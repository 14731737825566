import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { PickedItem } from '../../../ui-states/SearchUiState';
import './SearchDropDown.scss';
import classnames from 'classnames';
import LimitedText from '../tooltip/LimitedText';

type SearchDropDownState = {
    isDropDownOpen: boolean;
};

type SearchDropDownProps = {
    options: PickedItem[];
    pickedItem: string | React.ReactElement<any> | null;
    label?: string | React.ReactElement<any> | null;
    onClick: (pickedItem: PickedItem) => void;
    disabled?: boolean;
    className?: string;
};

class SearchDropDown extends Component<SearchDropDownProps, SearchDropDownState> {
    static defaultProps = {
        disabled: false
    };
    state = {
        isDropDownOpen: false
    };
    render() {
        const { options, pickedItem, onClick, label, disabled, className } = this.props;
        const { isDropDownOpen } = this.state;
        return (
            <Dropdown
                isOpen={isDropDownOpen}
                className={classnames(className, 'search-dropdown')}
                toggle={() =>
                    this.setState(prevState => ({
                        isDropDownOpen: !prevState.isDropDownOpen
                    }))
                }>
                <DropdownToggle disabled={disabled} caret={true}>
                    {pickedItem ? pickedItem : label}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    {options.map(option => (
                        <DropdownItem onClick={() => onClick({ label: option.label, id: option.id })} key={option.id}>
                            <LimitedText content={option.label} maxLength={60} />
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default SearchDropDown;
