import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './LoadingSpinner.scss';
import classnames from 'classnames';

export type SpinnerSize = 'tiny' | 'small' | 'large';
const LoadingSpinner = ({ className, size = 'large' }: { className?: string; size?: SpinnerSize }) => (
    <div className={classnames('loading-spinner', className)}>
        <FontAwesomeIcon
            data-testid="loading-spinner"
            icon={faSpinner}
            spin={true}
            size={size === 'small' ? '3x' : size === 'tiny' ? '1x' : '6x'}
        />
    </div>
);

export default LoadingSpinner;
