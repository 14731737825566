// @ts-ignore
import SimpleDecorator from 'draft-js-simpledecorator';
import TagSpan from './TagSpan';
import { Tag } from '../../../graphql/types';
import { CharacterMetadata, EntityInstance } from 'draft-js';
import { EntityType } from '../entities';

export const createTagDecorator = (tags: Tag[]) =>
    new SimpleDecorator((contentBlock: any, callback: any, contentState: any) => {
        let selectedEntity: EntityInstance;
        let selectedEntityKey: string;
        contentBlock.findEntityRanges(
            (character: CharacterMetadata) => {
                selectedEntityKey = character.getEntity();
                if (selectedEntityKey === null) {
                    return false;
                }

                selectedEntity = contentState.getEntity(selectedEntityKey);
                if (selectedEntity && selectedEntity.getType() === EntityType.TAG) {
                    return true;
                }
            },
            (start: number, end: number) => {
                return callback(start, end, {
                    tagId: selectedEntity.getData().id,
                    tagEntity: { blockKey: contentBlock.getKey(), entityKey: selectedEntityKey }
                });
            }
        );
    }, TagSpan);
