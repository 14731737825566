import { Field, FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { EditorShortcutType, VideoShortcutType } from '../../../../graphql/types';
import FormikSelect from '../../../common/formik-select/FormikSelect';
import FormikInput from '../../../common/inputs/FormikInput';
import KeyboardTranslation from '../../../../framework/utils/KeyboardTranslation';
import React from 'react';
import _ from 'lodash';
import { toJS } from 'mobx';
import add from '../../../../assets/images/blue-add.svg';
import CommonTable from '../../../common/common-table/CommonTable';
import i18next from 'i18next';
import { withTranslation, WithTranslation } from 'react-i18next';
import x from '../../../../assets/images/x.png';
import ModalActions from '../../../common/modal/ModalActions';

export type ShortcutType = EditorShortcutType | VideoShortcutType;
export type Shortcut = { keys: string[]; shortcutType: ShortcutType };

function mapShortcutsToList(editorShortcuts: Shortcut[]) {
    return editorShortcuts.map(({ shortcutType, keys }) => ({ shortcutType, keys }));
}

const filteredOptions = (
    t: i18next.TFunction,
    shortcutTypes: Array<{ value: string; label: string }>,
    usedShortcuts: Array<{ shortcutType: ShortcutType; keys: string[] }>
) => {
    return shortcutTypes.filter(type => !usedShortcuts.some(x => x.shortcutType === type.value));
};

type ShortCutFormProps = {
    editorShortcuts: Shortcut[];
    shortcutTypes: Array<{ value: string; label: string }>;
    getActiveKeys: (e: React.KeyboardEvent) => Set<string>;
    onSubmit: (shortcuts: Shortcut[]) => void;
    onCancel: () => void;
} & WithTranslation;

const ShortcutForm: React.FC<ShortCutFormProps> = ({
    editorShortcuts,
    t,
    shortcutTypes,
    getActiveKeys,
    onSubmit,
    onCancel
}) => {
    const columns = [
        {
            name: '',
            render: (shortcut: Shortcut & { index: number; arrayHelper: FieldArrayRenderProps }) => (
                <div onClick={() => shortcut.arrayHelper.remove(shortcut.index)}>
                    <img src={x} alt={t('delete shortcuts')} />
                </div>
            )
        },
        {
            name: t('command'),
            render: (shortcut: Shortcut) => t(shortcut.shortcutType)
        },
        {
            name: t('keys'),
            render: (shortcut: Shortcut) => KeyboardTranslation.translateCodeList(shortcut.keys)
        }
    ];

    return (
        <Formik
            initialValues={{
                shortcuts: mapShortcutsToList(editorShortcuts),
                keys: [],
                shortcutType: null
            }}
            onSubmit={values => console.debug(values)}
            render={({ values, errors, touched, setFieldValue, isSubmitting, setFieldError }) => {
                return (
                    <Form>
                        <Field
                            className="input-wrapper"
                            name="shortcutType"
                            component={FormikSelect}
                            options={filteredOptions(t, shortcutTypes, values.shortcuts)}
                            noOptionsMessage={t('no available shortcuts')}
                            placeholder={t('please pick available shortcut')}
                            option={
                                values.shortcutType
                                    ? { label: t(values.shortcutType!), value: values.shortcutType }
                                    : ''
                            }
                        />
                        <FormikInput
                            label={t('keys')}
                            name="keys"
                            placeholder=""
                            invalid={!!(errors.keys && touched.keys)}
                            // @ts-ignore
                            error={errors.keys && errors.keys}
                            value={KeyboardTranslation.translateCodeList(values.keys)}
                            onKeyDown={(e: React.KeyboardEvent) => {
                                e.preventDefault();
                                setFieldValue('keys', [...getActiveKeys(e)]);
                            }}
                            autoComplete="off"
                        />

                        <FieldArray
                            name="shortcuts"
                            render={(arrayHelper: FieldArrayRenderProps) => {
                                const extendedShortcuts = values.shortcuts.map((shortcut, index) => ({
                                    ...shortcut,
                                    arrayHelper: { ...arrayHelper },
                                    index
                                }));

                                return (
                                    <>
                                        <div
                                            className="add"
                                            onClick={() => {
                                                if (!values.keys || !values.shortcutType) {
                                                    return;
                                                }
                                                if (
                                                    values.shortcuts.some(x =>
                                                        _.isEqual(toJS(x.keys), toJS(values.keys))
                                                    )
                                                ) {
                                                    setFieldError('keys', t('shortcut is taken'));
                                                    return;
                                                }
                                                setFieldValue('keys', []);
                                                setFieldValue('shortcutType', '');
                                                return arrayHelper.push({
                                                    keys: values.keys,
                                                    shortcutType: values.shortcutType
                                                });
                                            }}>
                                            <img src={add} alt="" />
                                            <span className="text">{t('add shortcut')}</span>
                                        </div>
                                        <CommonTable
                                            columns={columns}
                                            rows={extendedShortcuts}
                                            dataTestId="shortcut-row"
                                        />
                                    </>
                                );
                            }}
                        />
                        <ModalActions
                            isSubmitting={isSubmitting}
                            onSubmitClick={() => onSubmit(values.shortcuts)}
                            submitText={t('save and close')}
                            onCancel={onCancel}
                        />
                    </Form>
                );
            }}
        />
    );
};

export default withTranslation()(ShortcutForm);
