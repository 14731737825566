import { autorun, IReactionDisposer, runInAction, toJS } from 'mobx';
import TranscriptModel, { TranscriptSyncStatus } from '../models/TranscriptModel';
import { Cancelable, debounce } from 'lodash';
import { SaveTranscriptMutation } from '../graphql/types';

export class TranscriptSaver {
    private readonly saveDebounced: ((transcript: TranscriptModel) => void) & Cancelable;
    private lastValue: string | null = null;
    private autorunDisposer: IReactionDisposer | null = null;

    constructor(
        private readonly saveAction: (transcript: TranscriptModel) => Promise<SaveTranscriptMutation>,
        private onSyncError: () => void,
        debounceBy: number = 500
    ) {
        this.saveDebounced = debounce(this.onSave, debounceBy, { trailing: true, maxWait: 5000 });
    }

    start(transcriptGetter: () => TranscriptModel | null) {
        this.autorunDisposer = autorun(
            () => {
                const transcript = transcriptGetter();

                // File was disposed
                if (!transcript) {
                    if (this.lastValue) {
                        console.debug('unsubscribing from file');
                        this.dispose();
                    }
                    return;
                }

                const transcriptToCompare = transcript && JSON.stringify(toJS(transcript.asJson));

                // First value received
                if (!this.lastValue) {
                    this.lastValue = transcriptToCompare;
                    return;
                }

                // File changed
                if (transcriptToCompare !== this.lastValue) {
                    this.saveDebounced(transcript);
                    this.lastValue = transcriptToCompare;
                    return;
                }
            },
            { name: 'save transcript' }
        );
    }

    private async onSave(transcriptModel: TranscriptModel) {
        try {
            runInAction(() => {
                transcriptModel.syncStatus = TranscriptSyncStatus.IN_PROGRESS;
            });
            const result = await this.saveAction(transcriptModel);
            const wasCorrected = result.wasCorrected;

            let syncStatus = TranscriptSyncStatus.SYNCED;

            if (wasCorrected) {
                syncStatus = TranscriptSyncStatus.NOT_SYNCED;
                this.onSyncError();
            }
            runInAction(() => {
                transcriptModel.syncStatus = syncStatus;
            });
        } catch (err) {
            runInAction(() => {
                transcriptModel.syncStatus = TranscriptSyncStatus.NOT_SYNCED;
                this.onSyncError();
            });
        }
    }

    dispose() {
        this.saveDebounced.cancel();
        this.lastValue = null;
        if (this.autorunDisposer) {
            this.autorunDisposer();
            this.autorunDisposer = null;
        }
    }
}
