class KeyboardTranslation {
    private englishLetterToHebrew: { [index: string]: string } = {
        e: 'ק',
        r: 'ר',
        t: 'א',
        y: 'ט',
        u: 'ו',
        i: 'ן',
        o: 'ם',
        p: 'פ',
        a: 'ש',
        s: 'ד',
        d: 'ג',
        f: 'כ',
        g: 'ע',
        h: 'י',
        j: 'ח',
        k: 'ל',
        l: 'ך',
        z: 'ז',
        x: 'ס',
        c: 'ב',
        v: 'ה',
        b: 'נ',
        n: 'מ',
        m: 'צ'
    };
    translateCodeList = (codes: string[]) => {
        const newCodes = codes.map(x => this.translateKeyCode(x));
        return newCodes.join('+');
    };
    private translateKeyCode = (keyCode: string) => {
        let newKeyCode = keyCode.replace('Key', '');
        newKeyCode = newKeyCode.replace('Digit', '');
        newKeyCode = newKeyCode.replace('Right', '');
        newKeyCode = newKeyCode.replace('Left', '');
        newKeyCode = newKeyCode.replace('Numpad', '');
        if (this.isLetter(newKeyCode)) {
            return this.englishLetterToHebrew[newKeyCode.toLowerCase()].concat(`/${newKeyCode}`);
        }
        return newKeyCode;
    };
    private isLetter = (letter: string): boolean => {
        return Object.keys(this.englishLetterToHebrew).includes(letter.toLowerCase());
    };
}
export default new KeyboardTranslation();
