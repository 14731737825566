import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import greyLightBulb from '../../../../assets/images/grey-lightbulb.png';
import InjectedComponent from '../../../../framework/utils/InjectedComponent';
import TimeUtils from '../../../../framework/utils/TimeUtils';
import { Notification } from '../../../../graphql/types';
import NotificationService from '../../../../services/NotificationService';
import UserStore from '../../../../stores/UserStore';
import './NotificationItem.scss';
import classnames from 'classnames';
import withStores from '../../../../framework/hoc/withStores';

type NotificationItemProps = {
    notification: Notification;
    markNotificationRead: () => {};
    userStore: UserStore;
} & WithTranslation;

@observer
class NotificationItem extends Component<NotificationItemProps> {
    render() {
        const {
            notification,
            markNotificationRead,
            userStore: { userRole }
        } = this.props;
        return (
            <div
                className={classnames({ 'unread-bg': notification!.unread }, 'notification-item')}
                onMouseEnter={() => {
                    if (notification.unread) {
                        return markNotificationRead();
                    }
                }}>
                <Link to={`/${userRole.toLowerCase()}/${NotificationService.getLinkTo(notification)}`}>
                    <div className="notification-item-wrapper">
                        <img src={greyLightBulb} alt="" />
                        <div>
                            <div>
                                {notification.title} <br /> {notification.description}
                            </div>
                        </div>
                    </div>
                </Link>
                <div className="notification-creation-date">
                    <div>{TimeUtils.formatDate(TimeUtils.wrapDate(notification.created), 'HH:MM:ss')}</div>
                    <div className="middle-created-date-item">
                        {TimeUtils.formatDate(TimeUtils.wrapDate(notification.created), 'LL')}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withStores('userStore')(NotificationItem));
