import * as React from 'react';
import { Container } from 'reactstrap';
import Footer from '../footer/Footer';
import classnames from 'classnames';

const FlexPage: React.FC<{ className?: string; footer?: boolean }> = ({ children, className, footer }) => (
    <Container fluid className="d-flex flex-column flex-fill">
        <div className={classnames(className, 'flex-fill')}>{children}</div>
        {footer && <Footer />}
    </Container>
);

export default FlexPage;
