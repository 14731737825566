import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { FetchResult } from 'react-apollo';
import { exportSelectedBlocksMutation } from '../graphql/customer/mutations/exportSelectedBlocks';
import { exportTranscriptFileMutation } from '../graphql/customer/mutations/exportTranscriptFile';
import { modifyTranscriptOffsetMutation } from '../graphql/transcriber/mutations/modifyTranscriptOffset';
import { saveTranscriptMutation } from '../graphql/transcriber/mutations/saveTranscript';
import { setTranscriptIntervalMutation } from '../graphql/transcriber/mutations/setTranscriptBlockInterval';
import {
    BlockInput,
    ExportJob,
    ExportJobStatus,
    ExportSelectedBlocksMutation,
    ExportSelectedBlocksMutationArgs,
    ExportSelectedFilesMutation,
    ExportSelectedFilesMutationArgs,
    ExportTranscriptMutation,
    ExportTranscriptMutationArgs,
    ModifyTranscriptOffset,
    ModifyTranscriptOffsetMutationArgs,
    SaveTranscriptMutation,
    SaveTranscriptMutationArgs,
    SetTranscriptIntervalMutation,
    SetTranscriptIntervalMutationArgs,
    TagLocationInput,
    Transcript
} from '../graphql/types';
import { MobxApolloStore } from '../framework/mobx';
import { action, computed, observable, runInAction } from 'mobx';
import TagStore from './TagStore';
import TranscriptModel from '../models/TranscriptModel';
import { convertToHTML } from '../framework/draft/conversion';
import { MobxApolloQuery } from 'mobx-apollo';
import { transcriptQuery } from '../graphql/common/queries/transcript';
import UserStore from './UserStore';
import async, { AsyncQueue } from 'async';
import { exportSelectedFilesMutation } from '../graphql/customer/mutations/exportTranscriptFiles';
import { exportJobStatusQuery } from '../graphql/customer/query/exportJobStatus';
import { runIfNotFinishedWithin } from '../framework/promises';

type TranscriptSnapshot = {
    transcriptId: string;
    newRevision: number;
    blocks: BlockInput[];
    tags: TagLocationInput[];
};

export default class TranscriptStore extends MobxApolloStore {
    @observable public transcriptQuery: MobxApolloQuery<{ transcript: Transcript }> | null = null;

    private readonly linearExecutor: AsyncQueue<any>;

    constructor(
        client: ApolloClient<NormalizedCacheObject>,
        private readonly tagStore: TagStore,
        private readonly userStore: UserStore
    ) {
        super(client);
        this.linearExecutor = async.queue(async.asyncify(async (action: () => void) => action()), 1);
    }

    private async waitForJob(jobId: string) {
        const query = this.client.watchQuery<{ exportJobStatus: ExportJob }>({
            query: exportJobStatusQuery,
            pollInterval: 3000,
            variables: {
                jobId
            }
        });

        const outUrl = await runIfNotFinishedWithin(
            new Promise((resolve, reject) => {
                query.subscribe(({ data, errors }) => {
                    if (
                        (errors && errors.length > 0) ||
                        (data && data.exportJobStatus.status === ExportJobStatus.Failed)
                    ) {
                        query.stopPolling();
                        reject();
                    } else if (data && data.exportJobStatus.status === ExportJobStatus.Complete) {
                        query.stopPolling();
                        resolve(data.exportJobStatus.resultUrl);
                    }
                });
            }),
            10 * 60 * 1000,
            () => {
                query.stopPolling();
                throw new Error('Export timed out');
            }
        );
        return outUrl;
    }

    @computed
    get transcript() {
        return (this.transcriptQuery && this.transcriptQuery.data && this.transcriptQuery.data.transcript) || null;
    }

    @action
    async init(fileId: string) {
        if (this.userStore.isCustomer) {
            // Needed to complete before transcript is loaded
            await this.tagStore.init();
        }

        runInAction(
            () =>
                (this.transcriptQuery = this.query(transcriptQuery, {
                    id: fileId
                }))
        );

        await this.awaitQuery(this.transcriptQuery!);

        return this.transcript!;
    }

    async fetchTranscript(fileId: string): Promise<Transcript> {
        const query = this.query(transcriptQuery, {
            id: fileId
        }) as MobxApolloQuery<{ transcript: Transcript }>;

        await this.awaitQuery(query);

        return query!.data!.transcript!;
    }

    @action
    saveTranscript = async (transcript: TranscriptModel) => {
        const blocks = this.mapTranscriptBlocks(transcript);

        transcript.revision += 1; // incrementing revision immediately before taking attempting to save the snapshot

        const snapshot = {
            transcriptId: transcript.id,
            newRevision: transcript.revision,
            blocks,
            tags: [...transcript.allTags]
        };

        const { data } = await new Promise<FetchResult<{ saveTranscript: SaveTranscriptMutation }>>(
            (resolve, reject) => {
                // linear execution prevents HTTP requests from overlapping each other
                const task = () => this.saveTranscriptSnapshot(snapshot);

                this.linearExecutor.push(task, (err, result) => (err ? reject(err) : resolve(result)));
            }
        );

        return data!.saveTranscript;
    };
    @action
    setTranscriptInterval = async ({ transcriptId, interval }: SetTranscriptIntervalMutationArgs) => {
        await this.client.mutate<SetTranscriptIntervalMutation, SetTranscriptIntervalMutationArgs>({
            mutation: setTranscriptIntervalMutation,
            variables: { transcriptId, interval },
            refetchQueries: [{ query: transcriptQuery, variables: { id: transcriptId } }],
            awaitRefetchQueries: true
        });
    };

    exportTranscriptFile = async ({ fileId, exportFormat }: ExportTranscriptMutationArgs) => {
        const { data } = await this.client.mutate<ExportTranscriptMutation, ExportTranscriptMutationArgs>({
            mutation: exportTranscriptFileMutation,
            variables: { fileId, exportFormat }
        });

        return this.waitForJob(data!.exportTranscript.jobId);
    };

    exportTranscriptsFiles = async ({ fileIds, exportFormat }: ExportSelectedFilesMutationArgs) => {
        const { data } = await this.client.mutate<
            { exportSelectedFiles: ExportSelectedFilesMutation },
            ExportSelectedFilesMutationArgs
        >({
            mutation: exportSelectedFilesMutation,
            variables: { fileIds, exportFormat }
        });

        return this.waitForJob(data!.exportSelectedFiles.jobId);
    };

    exportSelectedBlocks = async ({ selectedBlocks, exportFormat }: ExportSelectedBlocksMutationArgs) => {
        return await this.client.mutate<ExportSelectedBlocksMutation, ExportSelectedBlocksMutationArgs>({
            mutation: exportSelectedBlocksMutation,
            variables: { selectedBlocks, exportFormat }
        });
    };

    @action
    modifyTranscriptOffset = async ({ startOffsetInSeconds, transcriptId }: ModifyTranscriptOffsetMutationArgs) => {
        await this.client.mutate<ModifyTranscriptOffset, ModifyTranscriptOffsetMutationArgs>({
            mutation: modifyTranscriptOffsetMutation,
            variables: { startOffsetInSeconds, transcriptId },
            refetchQueries: [{ query: transcriptQuery, variables: { id: transcriptId } }],
            awaitRefetchQueries: true
        });
    };

    private async saveTranscriptSnapshot(transcriptSnapshot: TranscriptSnapshot) {
        return (await this.client.mutate<SaveTranscriptMutation, SaveTranscriptMutationArgs>({
            mutation: saveTranscriptMutation,
            variables: transcriptSnapshot
        })) as FetchResult<{ saveTranscript: SaveTranscriptMutation }>;
    }

    private mapTranscriptBlocks(transcript: TranscriptModel): BlockInput[] {
        return transcript.blocks.map(block => ({
            blockNumber: block.number,
            blockOffset: block.offset,
            paragraphs: block.paragraphs.map(paragraph => ({
                number: paragraph.number,
                speakerId: paragraph.speakerId,
                body: convertToHTML(paragraph.editorState.getCurrentContent(), this.tagStore.allTags)
            }))
        }));
    }
}
