export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function runIfNotFinishedWithin(promise: Promise<any>, timeoutMs: number, callback: () => void) {
    delay(timeoutMs).then(async () => {
        if ((await Promise.race([promise, 'slowpoke'])) === 'slowpoke') {
            callback();
        }
    });

    return promise;
}
