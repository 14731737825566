import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { File, FileStatus } from '../../../../graphql/types';
import SubmitButton from '../../../common/submit-button/SubmitButton';
import withStores from '../../../../framework/hoc/withStores';
import TranscriptUiState from '../../../../ui-states/TranscriptUiState';

type SubmitButtonsProps = {
    file: File;
    transcriptUiState: TranscriptUiState;
};

const AdminSubmitButtons = ({
    t,
    history,
    file,
    transcriptUiState
}: SubmitButtonsProps & WithTranslation & RouteComponentProps) => {
    return (
        <>
            <SubmitButton secondary={true} onClick={transcriptUiState.sendTranscriptForRepair}>
                {t('send for repair')}
            </SubmitButton>
            <SubmitButton disable={isDisabled(file)} onClick={transcriptUiState.openApproveFileModal}>
                {t('approve file')}
            </SubmitButton>
        </>
    );
};

const isDisabled = (file: File) => ![FileStatus.PendingApproval, FileStatus.AwaitingAmendment].includes(file.status);

export default withTranslation()(withRouter(withStores('transcriptUiState')(AdminSubmitButtons)));
