import { gql } from 'apollo-boost';

export const addFileMutation = gql`
    mutation addFile($mediaFileUrl: String!, $projectId: ID!, $mediaFileName: String!) {
        addFile(mediaFileUrl: $mediaFileUrl, projectId: $projectId, mediaFileName: $mediaFileName) {
            file {
                id
            }
        }
    }
`;
