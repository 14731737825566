import { gql } from 'apollo-boost';

export const saveTranscriptMutation = gql`
    mutation saveTranscript(
        $blocks: [BlockInput!]!
        $transcriptId: ID!
        $newRevision: Int!
        $tags: [TagLocationInput!]!
    ) {
        saveTranscript(blocks: $blocks, transcriptId: $transcriptId, newRevision: $newRevision, tags: $tags) {
            success
            wasCorrected
        }
    }
`;
