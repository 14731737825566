import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { filesSpeakerNamesQuery } from '../graphql/customer/query/fileSpeakers';
import { projectSpeakerNamesQuery, projectSpeakersQuery } from '../graphql/customer/query/projectSpeakers';
import { modifySpeakersMutation } from '../graphql/transcriber/mutations/modifySpeakers';
import { refreshSpeakers } from '../graphql/transcriber/queries/refreshSpeakers';
import {
    FilesSpeakersQueryArgs,
    ModifySpeakersMutation,
    ModifySpeakersMutationArgs,
    ProjectSpeakersQueryArgs,
    Speaker
} from '../graphql/types';

export default class SpeakerStore {
    constructor(private client: ApolloClient<NormalizedCacheObject>) {}
    public getSpeakersByProjectId = async (id: string) => {
        const query = await this.client.query<{ projectSpeakers: Speaker[] }, ProjectSpeakersQueryArgs>({
            query: projectSpeakersQuery,
            variables: { id }
        });
        return query.data.projectSpeakers;
    };

    public getSpeakerNamesByProjectId = async (id: string) => {
        const query = await this.client.query<{ projectSpeakers: Speaker[] }, ProjectSpeakersQueryArgs>({
            query: projectSpeakerNamesQuery,
            variables: { id }
        });
        return query.data.projectSpeakers;
    };

    public getSpeakerNamesByFilesIds = async ({ ids }: FilesSpeakersQueryArgs) => {
        const query = await this.client.query<{ filesSpeakers: Speaker[] }, FilesSpeakersQueryArgs>({
            query: filesSpeakerNamesQuery,
            variables: { ids }
        });
        return query.data.filesSpeakers;
    };

    public modifySpeakers = async ({
        speakers,
        transcriptId,
        fileId
    }: ModifySpeakersMutationArgs & { fileId: string }) => {
        return await this.client.mutate<ModifySpeakersMutation, ModifySpeakersMutationArgs>({
            mutation: modifySpeakersMutation,
            variables: { speakers, transcriptId },
            awaitRefetchQueries: true,
            refetchQueries: [{ query: refreshSpeakers, variables: { id: fileId } }]
        });
    };
}
