import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ConfirmModal from './confirm-modal/ConfirmModal';

import InjectedComponent from '../../../framework/utils/InjectedComponent';
import ModalManagerUiState, { ModalType } from '../../../ui-states/modalManagerUiState';
import ShortcutsModal from '../../transcriber/shortcut-modal/ShortcutsModal';
import AddFilesToProjectModal from './add-files-to-project-modal/AddFilesToProjectModal';
import ConfigureSpeakersModal from './configure-speakers-modal/ConfigureSpeakersModal';
import ConvertToTagsModal from './convert-to-tags-modal/ConvertToTagsModal';
import ExportFileModal from './export-file-modal/ExportFileModal';
import ExportTagsModal from './export-tags-modal/ExportTagsModal';
import ModifyBlockOffsetModal from './modify-block-timecode-modal/ModifyBlockTimeCodeModal';

type InjectedProps = {
    modalManagerUiState: ModalManagerUiState;
};

const modals: { [index: string]: React.ComponentClass<any> | React.FunctionComponent<any> } = {
    [ModalType.CONFIRM_MODAL]: ConfirmModal,
    [ModalType.CONFIGURE_PARTICIPANT_MODAL]: ConfigureSpeakersModal,
    [ModalType.EXPORT_TRANSCRIPTION_MODAL]: ExportFileModal,
    [ModalType.EXPORT_TAGS_MODAL]: ExportTagsModal,
    [ModalType.CONVERT_TO_TAG_MODAL]: ConvertToTagsModal,
    [ModalType.ADD_FILES_TO_PROJECT_MODAL]: AddFilesToProjectModal,
    [ModalType.TRANSCRIBER_SHORTCUTS_MODAL]: ShortcutsModal,
    [ModalType.MODIFY_BLOCK_OFFSET_MODAL]: ModifyBlockOffsetModal
};

@inject('modalManagerUiState')
@observer
class ModalManager extends InjectedComponent<InjectedProps, RouteComponentProps> {
    componentDidMount() {
        window.addEventListener('popstate', this.onBackClick);
    }
    componentWillUnmount() {
        window.removeEventListener('popstate', this.onBackClick);
    }

    render() {
        const { shownModal, modalProps } = this.injectedProps.modalManagerUiState;
        if (!shownModal) {
            return null;
        }

        const Component = modals[shownModal];
        if (!Component) {
            throw new Error(`No modal component for modal: ${shownModal}`);
        }

        return <Component isOpen={true} {...modalProps} />;
    }

    private onBackClick = () => {
        this.injectedProps.modalManagerUiState.closeCurrentModal();
    };
}

export default withRouter(ModalManager);
