import { convertFromHTML as originalConvertFromHTML, convertToHTML as originalConvertToHTML } from 'draft-convert';
import { ContentState } from 'draft-js';
import React from 'react';

type Tag = { id: string; color: string; name: string };

export const convertToHTML = (contentState: ContentState, tags: Tag[]) => {
    return originalConvertToHTML({
        entityToHTML: (entity: any, originalText: string, tags: Tag[]) => {
            if (entity.type === 'TAG') {
                const { id } = entity.data;
                return `<span data-tag-id="${id}">${originalText}</span>`;
            }
            return originalText;
        }
    })(contentState);
};

export const convertFromHTML = (content: string, tags: Tag[]) => {
    return originalConvertFromHTML({
        htmlToEntity: (nodeName: string, node: any, createEntity: any) => {
            if (!tags.length) {
                return;
            }
            if (node.dataset && node.dataset.tagId) {
                const tag = tags.find(tag => tag.id === node.dataset.tagId);
                if (tag) {
                    return createEntity('TAG', 'MUTABLE', { id: tag.id });
                }
            }
        }
    })(content);
};
