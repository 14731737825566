import React from 'react';
import './Title.scss';
import LimitedText from '../tooltip/LimitedText';

type TitleProps = {
    title: string;
};

const Title = ({ title }: TitleProps) => {
    return (
        <div className="title">
            <h1>
                <LimitedText content={title} maxLength={60} />
            </h1>
        </div>
    );
};

export default Title;
