import { gql } from 'apollo-boost';

export const projectsFilesSearchQuery = gql`
    query projectFiles($id: ID!) {
        projectFiles(id: $id) {
            id
            mediaFileName
        }
    }
`;
