import React, { Component } from 'react';
import { File } from '../../../graphql/types';
import CommonTable, { Column } from '../common-table/CommonTable';
import './FilesTable.scss';

type FilesTableProps = {
    files: File[];
    columns: Column[];
    dataTestId?: string;
    rowClassSelector?: (item: File) => string;
};

class FilesTable extends Component<FilesTableProps> {
    render() {
        const { files, columns, dataTestId, rowClassSelector } = this.props;
        return (
            <CommonTable
                rows={files}
                columns={columns}
                dataTestId={dataTestId}
                rowClassSelector={rowClassSelector}
                paginate
            />
        );
    }
}

export default FilesTable;
