import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ParagraphModel from '../../../../../models/ParagraphModel';
import UserStore from '../../../../../stores/UserStore';
import TranscriptUiState from '../../../../../ui-states/TranscriptUiState';
import ParagraphEditor from '../paragraph-editor/ParagraphEditor';
import ParagraphSpeaker from './ParagraphSpeaker';
import { Speaker } from '../../../../../graphql/types';
import withStores from '../../../../../framework/hoc/withStores';

type InjectedProps = {
    transcriptUiState: TranscriptUiState;
    userStore: UserStore;
};

type ParagraphProps = {
    paragraph: ParagraphModel;
    speakers: Speaker[];
    isActive: boolean;
} & InjectedProps;

@observer
class Paragraph extends Component<ParagraphProps> {
    private paragraphEditorRef: any = null;

    componentDidMount(): void {
        const { isActive } = this.props;
        if (isActive) {
            this.focus();
        }
    }

    render() {
        const {
            paragraph,
            speakers,
            userStore,
            transcriptUiState: {
                tryMoveToNextParagraph,
                tryMoveToPreviousParagraph,
                replaceWithMacros,
                handleEditorShortcuts,
                handleKeyBinding,
                setActiveParagraph
            }
        } = this.props;
        return (
            <div className="paragraph">
                <ParagraphSpeaker
                    speaker={speakers.find(x => x.id === paragraph.speakerId) || null}
                    setSpeaker={speaker => paragraph.setSpeaker(speaker && speaker.id)}
                    isCustomer={userStore.isCustomer}
                    speakers={speakers}
                />

                <div style={{ width: '100%' }}>
                    <ParagraphEditor
                        paragraph={paragraph}
                        isCustomer={userStore.isCustomer}
                        ref={ref => (this.paragraphEditorRef = ref)}
                        handleKeyCommand={handleEditorShortcuts}
                        keyBindingFn={handleKeyBinding}
                        handleBeforeInput={replaceWithMacros}
                        onUpArrow={tryMoveToPreviousParagraph}
                        onDownArrow={tryMoveToNextParagraph}
                        onFocus={() => setActiveParagraph(paragraph)}
                    />
                </div>
            </div>
        );
    }

    private focus() {
        this.paragraphEditorRef.wrappedInstance.focus();
    }
}

export default withStores('userStore', 'transcriptUiState')(Paragraph);
