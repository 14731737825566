import React from 'react';
import classnames from 'classnames';

type RadioProps = {
    value: string;
    checked: boolean;
    isDisabled?: boolean;
    label: string | JSX.Element;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Radio = ({ value, checked, label, onChange, isDisabled }: RadioProps) => {
    return (
        <label className={classnames({ disabled: isDisabled })}>
            <input
                type="radio"
                name={value}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={isDisabled}
            />
            {label}
        </label>
    );
};

export default Radio;
