import React from 'react';
import './Circle.scss';

type CircleProps = {
    color: string;
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
};
const Circle = ({ color, onClick }: CircleProps) => (
    <span className="circle" style={{ backgroundColor: color }} onClick={onClick} />
);

export default Circle;
