import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import InjectedComponent from '../../../framework/utils/InjectedComponent';
import { EditorShortcut, Macro, Transcriber, VideoShortcut } from '../../../graphql/types';
import ShortcutsModalUiState, { ShortcutSection } from '../../../ui-states/ShortcutsModalUiState';
import EditorShortcutTab from './editor-shortcut-tab/EditorShortcutTab';
import VideoPlayerShortcutTab from './editor-shortcut-tab/VideoPlayerShortcutTab';
import MacroShortcutTab from './macro-shortcut-tab/MacroShortcutTab';
import './ShortcutsModal.scss';
import classNames from 'classnames';

type ShortcutsModalProps = {
    isOpen: boolean;
    transcriber: Transcriber;
    onHide(): void;
};

type InjectedProps = {
    shortcutsModalUiState: ShortcutsModalUiState;
};

export type Tab = {
    component: React.ComponentClass<any> | React.FunctionComponent<any>;
    onSubmit: any;
    title: string;
    section: ShortcutSection;
};

@inject('shortcutsModalUiState')
@observer
class ShortcutsModal extends InjectedComponent<InjectedProps, ShortcutsModalProps & WithTranslation> {
    render() {
        const { shortcutsModalUiState } = this.injectedProps;

        const tabs: Tab[] = [
            {
                component: VideoPlayerShortcutTab,
                onSubmit: (values: VideoShortcut[]) => shortcutsModalUiState.modifyVideoPlayerShortcuts(values),
                title: 'shortcuts for video player',
                section: ShortcutSection.PLAYER
            },
            {
                component: MacroShortcutTab,
                onSubmit: (values: Macro[]) => shortcutsModalUiState.modifyMacros(values),
                title: 'shortcuts for spelling',
                section: ShortcutSection.MACROS
            },
            {
                component: EditorShortcutTab,
                onSubmit: (values: EditorShortcut[]) => shortcutsModalUiState.modifyEditorShortcuts(values),
                title: 'macros',
                section: ShortcutSection.EDITOR
            }
        ];
        const { isOpen, onHide, t, transcriber } = this.props;
        const TabContent = tabs[shortcutsModalUiState.activeSection].component;
        if (!TabContent) {
            throw new Error(`No Tab found for ${ShortcutSection[shortcutsModalUiState.activeSection]}`);
        }
        return (
            <Modal isOpen={isOpen} toggle={onHide} size="lg" centered={true}>
                <div className="shortcuts-modal">
                    <ModalBody>
                        <h2>{t('shortcut configuration')}</h2>
                        <div className="tabs">
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    className={classNames(`tab`, {
                                        active: shortcutsModalUiState.activeSection === tab.section
                                    })}
                                    onClick={() => shortcutsModalUiState.setActiveSection(tab.section)}>
                                    <h3>{t(tab.title)}</h3>
                                </div>
                            ))}
                        </div>
                        <TabContent
                            transcriber={transcriber}
                            onHide={onHide}
                            onSubmit={tabs[shortcutsModalUiState.activeSection].onSubmit}
                            getActiveKeys={shortcutsModalUiState.handleKeys}
                        />
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(ShortcutsModal);
