import { action, computed, observable } from 'mobx';
import { MobxApolloQuery } from 'mobx-apollo';
import { markNotificationReadMutation } from '../graphql/common/mutations/markNotificationRead';
import { notificationsQuery } from '../graphql/common/queries/notifications';
import { Notification, ReadNotificationsMutation, ReadNotificationsMutationArgs } from '../graphql/types';
import { MobxApolloStore } from '../framework/mobx';

export default class NotificationStore extends MobxApolloStore {
    @observable private notificationQuery: MobxApolloQuery<{ notifications: Notification[] }> | null = null;

    @action
    init() {
        if (!this.notificationQuery) {
            this.notificationQuery = this.query(notificationsQuery);
            return this.awaitQuery(this.notificationQuery);
        }

        return this.notificationQuery!.ref.refetch();
    }

    @computed
    get unreadNotifications() {
        return this.notifications.filter(x => x.unread).length;
    }

    @computed
    get notifications() {
        return (
            (this.notificationQuery && this.notificationQuery.data && this.notificationQuery.data.notifications) || []
        );
    }

    @computed
    get error() {
        return (this.notificationQuery && this.notificationQuery.error && this.notificationQuery.error.message) || null;
    }

    @computed
    get loading() {
        return this.notificationQuery && this.notificationQuery.loading;
    }

    public markNotificationRead = (notificationIds: string[]) => {
        return this.client.mutate<ReadNotificationsMutation, ReadNotificationsMutationArgs>({
            mutation: markNotificationReadMutation,
            variables: { notificationIds },
            refetchQueries: ['notifications']
        });
    };
}
