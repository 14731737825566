import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { truncateByLength } from '../../../framework/utils/StringUtils';
import { File } from '../../../graphql/types';

type TranscriberFilesDropDownState = {
    isDropDownOpen: boolean;
};

type TranscriberFilesDropDownProps = {
    file: File;
    files: File[];
};
class TranscriberFilesDropDown extends Component<
    TranscriberFilesDropDownProps & RouteComponentProps & WithTranslation,
    TranscriberFilesDropDownState
> {
    state = {
        isDropDownOpen: false
    };
    render() {
        const { isDropDownOpen } = this.state;
        const { file, history, t, files } = this.props;
        if (files.length <= 1) {
            return (
                <>
                    {t('file name')}:&nbsp;
                    <span className="value">{truncateByLength(file.mediaFileName, 15)}</span>
                </>
            );
        }
        return (
            <Dropdown
                isOpen={isDropDownOpen}
                toggle={() =>
                    this.setState(prevState => ({
                        isDropDownOpen: !prevState.isDropDownOpen
                    }))
                }>
                <DropdownToggle caret={true} tag="button">
                    {t('file name')}:&nbsp;
                    <span className="value">{truncateByLength(file.mediaFileName, 15)}</span>
                </DropdownToggle>
                <DropdownMenu right={true}>
                    {files.map(file => (
                        <div key={file.id}>
                            <DropdownItem
                                data-testid="url-dropdown-item"
                                onClick={() => history.push(`/transcriber/transcript/${file.id}`)}
                                key={file.id}>
                                {truncateByLength(file.mediaFileName, 15)}
                            </DropdownItem>
                            <DropdownItem divider={true} />
                        </div>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default withTranslation()(withRouter(TranscriberFilesDropDown));
