import { FieldArray, FieldArrayRenderProps, Formik, FormikErrors, FormikProps, getIn } from 'formik';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import add from '../../../../assets/images/blue-add.svg';
import x from '../../../../assets/images/x.png';
import { configureSpeakerSchema } from '../../../../framework/forms/validationSchema';
import KeyboardTranslation from '../../../../framework/utils/KeyboardTranslation';
import FormikInput from '../../inputs/FormikInput';
import { Speaker } from '../../../../graphql/types';
import ModalActions from '../ModalActions';
import { Alert } from 'reactstrap';

const SHORTCUT = 'shortcut';
const NAME = 'name';

type SpeakersFormProps = {
    serverSpeakers: Speaker[];
    initialSpeakers?: Speaker[];
    isLoading: boolean;
    confirmMessage?: React.ReactElement<any> | null;
    onSubmit(speakers: Speaker[]): Promise<boolean | void>;
    onCancel(): void;
};

class SpeakersForm extends Component<SpeakersFormProps & WithTranslation> {
    componentDidMount(): void {}

    render() {
        const { serverSpeakers, t, onSubmit, onCancel, isLoading, initialSpeakers = [], confirmMessage } = this.props;
        return (
            <Formik
                validationSchema={configureSpeakerSchema}
                initialValues={{ speakers: initialSpeakers.concat(serverSpeakers) }}
                onSubmit={({ speakers }) => onSubmit(speakers)}
                render={({ values, errors, setFieldTouched, validateForm }) => (
                    <FieldArray
                        name="speakers"
                        render={({ remove, push, form }: FieldArrayRenderProps) => (
                            <>
                                {values.speakers.map((speaker, index) => {
                                    return (
                                        <tr key={index} data-testid="speaker-row">
                                            <td>
                                                <div
                                                    className="delete-speaker"
                                                    onClick={() => remove(index)}
                                                    data-testid="delete-speaker">
                                                    <img src={x} alt={t('delete shortcuts')} />
                                                </div>
                                            </td>
                                            <td>
                                                <FormikInput
                                                    className="speaker-input"
                                                    name={`speakers.${index}.name`}
                                                    invalid={this.isInvalid(errors, index, NAME)}
                                                    error={this.getError(errors, index, NAME)}
                                                />
                                            </td>
                                            <td>
                                                <FormikInput
                                                    className="shortcut-input"
                                                    name={`speakers.${index}.shortcut`}
                                                    value={KeyboardTranslation.translateCodeList(speaker.shortcut)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                        e.preventDefault()
                                                    }
                                                    onKeyDown={(e: React.KeyboardEvent) => {
                                                        return this.onKeyShortcutChange(e, form, index);
                                                    }}
                                                    invalid={this.isInvalid(errors, index, SHORTCUT)}
                                                    error={this.getError(errors, index, SHORTCUT)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr className="border-0">
                                    <td colSpan={3}>
                                        <div
                                            className="add-speaker"
                                            onClick={() => {
                                                if (SpeakersForm.isEmptySpeaker(values)) {
                                                    return;
                                                }
                                                push({ name: '', shortcut: [], inMultipleProjects: false });
                                                setFieldTouched(`speakers.${values.speakers.length}.shortcut`);
                                                setFieldTouched(`speakers.${values.speakers.length}.name`);
                                            }}>
                                            <img src={add} alt={t('add speaker')} />
                                            <span className="text">{t('add speaker')}</span>
                                        </div>
                                        {confirmMessage && <Alert color="warning">{confirmMessage}</Alert>}
                                    </td>
                                </tr>
                                <tr className="border-0">
                                    <td colSpan={3}>
                                        <ModalActions
                                            onSubmitClick={async () => {
                                                const errors = await validateForm();
                                                if (isEmpty(errors)) {
                                                    return onSubmit(values.speakers);
                                                }
                                            }}
                                            isSubmitting={isLoading}
                                            isDisabled={isEmpty(values.speakers)}
                                            onCancel={onCancel}
                                            submitText={t('save and close')}
                                        />
                                    </td>
                                </tr>
                            </>
                        )}
                    />
                )}
            />
        );
    }

    private static isEmptySpeaker(values: { speakers: Speaker[] }) {
        return values.speakers.map(x => x.name).includes('') || values.speakers.map(x => x.shortcut[1]).includes('');
    }

    private isInvalid(errors: FormikErrors<{ speakers: Speaker[] }>, index: number, name: string) {
        return !!this.getError(errors, index, name);
    }

    private getError = (errors: FormikErrors<{ speakers: Speaker[] }>, index: number, name: string): string => {
        return getIn(errors, `speakers[${index}].${name}`);
    };

    private onKeyShortcutChange = (e: React.KeyboardEvent, form: FormikProps<any>, index: number) => {
        const shortcuts = this.handleKeys(e);
        return form.setFieldValue(`speakers.${index}.${SHORTCUT}`, shortcuts);
    };
    private handleKeys = (e: React.KeyboardEvent) => {
        const activeKeys = [];
        if (e.altKey) {
            activeKeys.push('AltLeft');
        }
        if (e.ctrlKey) {
            activeKeys.push('ControlLeft');
        }
        if (e.shiftKey) {
            activeKeys.push('ShiftLeft');
        }
        activeKeys.push(e.nativeEvent.code);
        return activeKeys;
    };
}

export default withTranslation()(SpeakersForm);
