import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ExportFormat } from '../../../../graphql/types';
import DownloadLinkMessage from '../download-link-message/DownloadLinkMessage';
import ExportFormatFields from '../ExportFormatFields';
import './ExportFileModal.scss';
import ModalActions from '../ModalActions';

type ExportFileModalProps = {
    isOpen: boolean;
    titleText: string;
    validate?: boolean;
    onHide(): void;
    onSubmit(type: ExportFormat): URL;
};

type ExportFileModalState = {
    selectedFileType: ExportFormat;
    downloadLink: URL | null;
    isDownloading: boolean;
};

class ExportFileModal extends Component<ExportFileModalProps & WithTranslation, ExportFileModalState> {
    static defaultProps = {
        validate: true
    };
    state = {
        selectedFileType: ExportFormat.Docx,
        downloadLink: null,
        isDownloading: false
    };
    render() {
        const { isOpen, onHide, titleText, t, validate } = this.props;
        const { selectedFileType, downloadLink, isDownloading } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={onHide} size="lg" centered={true}>
                <div className="export-file-modal">
                    <ModalHeader>{titleText}</ModalHeader>
                    <ModalBody>
                        {downloadLink ? (
                            <DownloadLinkMessage onClick={onHide} downloadLink={downloadLink} />
                        ) : (
                            <>
                                <div className="description">{t('please pick export format type')}</div>
                                <form onSubmit={this.onSubmit}>
                                    <ExportFormatFields
                                        onChange={this.handleChange}
                                        allowedFormats={[{ format: ExportFormat.Docx }, { format: ExportFormat.Avid }]}
                                        selectedFileType={selectedFileType}
                                    />
                                    <ModalActions
                                        onCancel={onHide}
                                        isSubmitting={isDownloading}
                                        submitText={t('download')}
                                        isDisabled={!selectedFileType || !validate}
                                    />
                                </form>
                            </>
                        )}
                    </ModalBody>
                </div>
            </Modal>
        );
    }
    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedFileType: e.target.value } as any);
    };

    private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        const { onSubmit } = this.props;
        const { selectedFileType } = this.state;
        e.preventDefault();
        this.setState({ isDownloading: true });
        try {
            const link: URL = await onSubmit(selectedFileType);
            this.setState({ isDownloading: false, downloadLink: link });
        } catch (e) {
            this.setState({ isDownloading: false });
        }
    };
}

export default withTranslation()(ExportFileModal);
