import './ModalActions.scss';
import SubmitButton from '../submit-button/SubmitButton';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

const ModalActions: React.FC<
    WithTranslation & {
        onCancel: () => void;
        onSubmitClick?: () => void;
        isSubmitting?: boolean;
        submitText?: string;
        isDisabled?: boolean;
        isCancelDisabled?: boolean;
        cancelText?: string;
    }
> = ({
    t,
    onCancel,
    isSubmitting,
    isDisabled,
    isCancelDisabled,
    submitText = t('approve'),
    cancelText = t('cancel'),
    onSubmitClick
}) => (
    <div className="modal-actions submit-buttons d-flex justify-content-around w-100">
        <SubmitButton
            disable={isDisabled}
            isLoading={isSubmitting}
            onClick={!onSubmitClick ? undefined : onSubmitClick}>
            {submitText}
        </SubmitButton>
        <SubmitButton secondary={true} onClick={onCancel} disable={isCancelDisabled}>
            {cancelText}
        </SubmitButton>
    </div>
);

export default withTranslation()(ModalActions);
