import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import bootstrap from './bootstrap';
import i18n from './localization/i18n';
import Main from './modules/Main';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import * as Sentry from '@sentry/browser';
import env from './env';
import FullStory from 'react-fullstory';

if (!env.DEV_MODE) {
    Sentry.init({
        dsn: env.SENTRY_DSN,
        environment: env.ENV,
        release: env.GIT_COMMIT
    });
}

configure({ enforceActions: 'always' });

const { client, ...injectables } = bootstrap();

ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider {...injectables}>
            <I18nextProvider i18n={i18n}>
                {env.USE_FULLSTORY && <FullStory org="M8GXW" />}
                <Main />
            </I18nextProvider>
        </Provider>
    </ApolloProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
