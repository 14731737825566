import { action, computed, observable } from 'mobx';
import { MobxApolloQuery } from 'mobx-apollo';
import { currentUserQuery } from '../graphql/common/queries/user';
import { changeEmailNotificationsMutation } from '../graphql/transcriber/mutations/changeEmailNotifications';
import { resetPasswordMutation } from '../graphql/transcriber/mutations/resetPassword';
import {
    ChangeEmailNotificationsMutation,
    ChangeEmailNotificationsMutationArgs,
    EditorShortcut,
    Macro,
    ResetPasswordMutation,
    User,
    UserRole,
    VerifyOtpMutation,
    VerifyOtpMutationArgs,
    VideoShortcut
} from '../graphql/types';
import { sendOtpMutation } from '../graphql/common/mutations/sendOtp';
import { MobxApolloStore } from '../framework/mobx';

export default class UserStore extends MobxApolloStore {
    @observable currentUserQuery: MobxApolloQuery<{ currentUser: User }> | null = null;

    resetPassword = async () => {
        return await this.client.mutate<ResetPasswordMutation>({
            mutation: resetPasswordMutation
        });
    };

    changeEmailNotification = async () => {
        return await this.client.mutate<ChangeEmailNotificationsMutation, ChangeEmailNotificationsMutationArgs>({
            mutation: changeEmailNotificationsMutation,
            variables: { status: !this.user.shouldReceiveMail },
            refetchQueries: [{ query: currentUserQuery }],
            awaitRefetchQueries: true
        });
    };

    async verifyOtp(otp: string) {
        const { data } = await this.client.mutate<VerifyOtpMutation, VerifyOtpMutationArgs>({
            mutation: sendOtpMutation,
            variables: { otp }
        });

        return data!.verifyOtp.token;
    }

    @computed
    get isLoggedIn() {
        return !!this.user;
    }

    @computed
    get isCustomer() {
        return this.user && this.user.role === UserRole.Customer;
    }

    @computed
    get isTranscriber() {
        return this.user && this.user.role === UserRole.Transcriber;
    }

    @computed
    get isAdmin() {
        return this.user && this.user.role === UserRole.Admin;
    }

    @computed
    get userRole(): UserRole {
        return this.user ? this.user.role : UserRole.NotSet;
    }

    @computed
    get editorShortcuts(): EditorShortcut[] {
        const { role, transcriber, administrator } = this.user;
        switch (role) {
            case UserRole.Transcriber:
                return transcriber!.editorShortcuts;
            case UserRole.Admin:
                return administrator!.editorShortcuts;
            default:
                return [];
        }
    }

    @computed
    get videoShortcuts(): VideoShortcut[] {
        const { role, transcriber, administrator } = this.user;
        switch (role) {
            case UserRole.Transcriber:
                return transcriber!.videoShortcuts;
            case UserRole.Admin:
                return administrator!.videoShortcuts;
            default:
                return [];
        }
    }

    @computed
    get macros(): Macro[] {
        const { role, transcriber, administrator } = this.user;
        switch (role) {
            case UserRole.Transcriber:
                return transcriber!.macros;
            case UserRole.Admin:
                return administrator!.macros;
            default:
                return [];
        }
    }

    @computed
    // Ensure that value is never null
    get user(): User {
        return (
            (!!this.currentUserQuery && this.currentUserQuery.data && this.currentUserQuery.data.currentUser) || null!
        );
    }

    @computed
    get error() {
        return (!!this.currentUserQuery && this.currentUserQuery.error && this.currentUserQuery.error.message) || null;
    }

    @computed
    get loading() {
        return !!this.currentUserQuery && this.currentUserQuery.loading;
    }

    @action
    async init() {
        this.currentUserQuery = this.query(currentUserQuery);
        return this.awaitQuery(this.currentUserQuery);
    }
}
