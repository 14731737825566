import _ from 'lodash';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import { Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import heverLogo from '../../../assets/images/textify-logo.png';
import { ModalService } from '../../../services/ModalService';
import ProjectStore from '../../../stores/ProjectStore';
import UserStore from '../../../stores/UserStore';
import AppUiState from '../../../ui-states/AppUiState';
import SearchUiState from '../../../ui-states/SearchUiState';
import './Header.scss';
import NotificationIcon from './notification-icon/NotificationTray';
import ProfileIcon from './profile-icon/ProfileIcon';
import LimitedText from '../tooltip/LimitedText';
import withStores from '../../../framework/hoc/withStores';
import { ProjectStatus } from '../../../graphql/types';
import InlineSpinner from '../loading-spinner/InlineSpinner';

type InjectedProps = {
    userStore: UserStore;
    appUiState: AppUiState;
    searchUiState: SearchUiState;
    projectStore: ProjectStore;
    modalService: ModalService;
};

type HeaderProps = InjectedProps & RouteComponentProps & WithTranslation;

const Header: React.FC<HeaderProps> = ({ projectStore, modalService, userStore: { isCustomer, user }, match, t }) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    return (
        <Observer>
            {() => {
                const { projects, loading } = projectStore;

                return (
                    <div className="header">
                        <Container fluid={true}>
                            <div className="header-right">
                                <Link to={`/`}>
                                    <img src={heverLogo} alt="" className="logo" />
                                </Link>
                                <div className="link-nav">
                                    {isCustomer ? (
                                        <>
                                            <NavLink to={`${match.url}/search`}>{t('search')}</NavLink>
                                            <span className="orange-bar">|</span>
                                            <NavLink to={`${match.url}/projects`}>{t('projects')}</NavLink>
                                            <Dropdown
                                                isOpen={isDropDownOpen}
                                                toggle={() => setIsDropDownOpen(isDropDownOpen => !isDropDownOpen)}
                                                inNavbar={true}>
                                                <DropdownToggle className="header-dropdown-toggle">
                                                    {t('upload files')}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {loading ? (
                                                        <DropdownItem disabled={true}>
                                                            <InlineSpinner size="small" />
                                                        </DropdownItem>
                                                    ) : !_.isEmpty(projects) ? (
                                                        projects
                                                            .filter(project => project.status !== ProjectStatus.Closed)
                                                            .map(project => (
                                                                <DropdownItem
                                                                    key={project.id}
                                                                    onClick={() =>
                                                                        modalService.addFilesToProject(project)
                                                                    }>
                                                                    <LimitedText
                                                                        content={project.name}
                                                                        maxLength={22}
                                                                    />
                                                                </DropdownItem>
                                                            ))
                                                    ) : (
                                                        <DropdownItem disabled={true}>
                                                            {t('no projects for user')}
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </>
                                    ) : (
                                        <Link to={`${match.url}/`}>{t('main page')}</Link>
                                    )}
                                </div>
                            </div>

                            <div className="header-icons">
                                <NotificationIcon />
                                <ProfileIcon fullName={user.fullName} profileImg={user.profileImg} />
                                {isCustomer ? (
                                    <span className="full-name">{user.fullName}</span>
                                ) : (
                                    <Link to={`${match.url}/profile`}>{user.fullName}</Link>
                                )}
                            </div>
                        </Container>
                    </div>
                );
            }}
        </Observer>
    );
};

export default withTranslation()(
    withRouter(withStores('userStore', 'appUiState', 'searchUiState', 'projectStore', 'modalService')(Header))
);
