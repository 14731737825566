import React from 'react';
import { Link } from 'react-router-dom';
import check from '../../../assets/images/check.png';
import eye from '../../../assets/images/eye.png';
import pencil from '../../../assets/images/pencil.png';
import { File, FileStatus, Project } from '../../../graphql/types';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithTranslation, withTranslation } from 'react-i18next';
import Tooltip from '../tooltip/Tooltip';

type ViewTranscriptionActionProps = {
    userRole: string;
    fileId: string;
} & WithTranslation;
type EditTranscriptionActionProps = {
    userRole: string;
    file: File;
};

type ViewProjectActionActionProps = {
    project: Project;
} & WithTranslation;

type AcceptFileActionProps = {
    onClick(): void;
};

type DeleteFileActionProps = {
    onClick(): void;
} & WithTranslation;

export const DeleteFileAction = withTranslation()(({ onClick, t }: DeleteFileActionProps) => (
    <div className="cursor-pointer" onClick={onClick}>
        <Tooltip content={t('delete file')}>
            <FontAwesomeIcon icon={faTrashAlt} />
        </Tooltip>
    </div>
));

export const EditTranscriptionAction = ({ userRole, file }: EditTranscriptionActionProps) => {
    if (file.status === FileStatus.PendingApproval) {
        return <span />;
    }
    return (
        <Link to={`/${userRole.toLowerCase()}/transcript/${file.id}`}>
            <img src={pencil} alt="" />
        </Link>
    );
};

export const ViewProjectAction = withTranslation()(({ project, t }: ViewProjectActionActionProps) => (
    <Link to={`/customer/projects/${project.id}`}>
        <Tooltip content={t('view project')}>
            <img src={eye} alt="" />
        </Tooltip>
    </Link>
));

export const ViewTranscriptionAction = withTranslation()(({ userRole, fileId, t }: ViewTranscriptionActionProps) => (
    <Link to={`/${userRole.toLowerCase()}/transcript/${fileId}`}>
        <Tooltip content={t('view transcript')}>
            <img src={eye} alt="" />
        </Tooltip>
    </Link>
));

export const AcceptFileAction = ({ onClick }: AcceptFileActionProps) => (
    <div className="cursor-pointer" onClick={onClick} data-testid="open-accept-file-modal">
        <img src={check} alt="" />
    </div>
);
