import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ExportFormat } from '../../../../graphql/types';
import TagStore from '../../../../stores/TagStore';
import { PickedItem } from '../../../../ui-states/SearchUiState';
import MultiSelectDropDown from '../../multi-select-dropdown/MultiSelectDropDown';
import DownloadLinkMessage from '../download-link-message/DownloadLinkMessage';
import ExportFormatFields from '../ExportFormatFields';
import './ExportTagsModal.scss';
import InlineSpinner from '../../loading-spinner/InlineSpinner';
import ModalActions from '../ModalActions';
import withStores from '../../../../framework/hoc/withStores';
import { withLoadable } from '../../../../framework/hoc/withLoadable';

type ExportTagsModalState = {
    selectedFileType: ExportFormat;
    selectedTags: PickedItem[];
    downloadLink: URL | null;
    isDownloading: boolean;
};

type InjectedProps = {
    tagStore: TagStore;
    isLoading?: boolean;
};

type ExportTagsModalProps = {
    fileId?: string;
    isOpen: boolean;
    titleText: string;
    onHide(): void;
    onSubmit(type: ExportFormat, tags: string[]): URL;
    allowedFormats?: Array<{ format: ExportFormat; disabledMessage?: string }>;
} & WithTranslation &
    InjectedProps;

@observer
class ExportTagsModal extends Component<ExportTagsModalProps, ExportTagsModalState> {
    state = {
        selectedFileType: ExportFormat.Docx,
        selectedTags: [] as PickedItem[],
        downloadLink: null,
        isDownloading: false
    };

    render() {
        const {
            isOpen,
            onHide,
            titleText,
            t,
            isLoading,
            allowedFormats,
            tagStore: { tagsInUse }
        } = this.props;
        const { selectedFileType, selectedTags, downloadLink, isDownloading } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={onHide} size="lg" centered={true}>
                <div className="export-tags-modal">
                    <ModalHeader>{titleText}</ModalHeader>
                    <ModalBody>
                        {isLoading ? (
                            <InlineSpinner className="mb-3" />
                        ) : downloadLink ? (
                            <DownloadLinkMessage onClick={onHide} downloadLink={downloadLink} />
                        ) : (
                            <form onSubmit={this.onSubmit}>
                                <div>
                                    <MultiSelectDropDown
                                        className="export-tags"
                                        title={t('please choose wanted tag')}
                                        label={t('tag')}
                                        options={tagsInUse.map(tag => ({ label: tag.name, id: tag.id }))}
                                        values={selectedTags}
                                        onChange={pickedItems => this.setState({ selectedTags: pickedItems })}
                                    />
                                </div>
                                <div className="description">{t('please pick export format type')}</div>
                                <ExportFormatFields
                                    onChange={this.handleChange}
                                    selectedFileType={selectedFileType}
                                    allowedFormats={allowedFormats}
                                />
                                <ModalActions
                                    onCancel={onHide}
                                    isSubmitting={isDownloading}
                                    isDisabled={!selectedFileType || !selectedTags.length}
                                    submitText={t('export')}
                                />
                            </form>
                        )}
                    </ModalBody>
                </div>
            </Modal>
        );
    }
    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedFileType: e.target.value } as any);
    };

    private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        const { selectedFileType, selectedTags } = this.state;
        const tags = selectedTags.map(tag => tag.id);
        this.setState({ isDownloading: true });
        try {
            const link: URL = await onSubmit(selectedFileType!, tags);
            this.setState({ isDownloading: false, downloadLink: link });
        } catch (e) {
            this.setState({ isDownloading: false });
        }
    };
}

export default withTranslation()(
    withStores('tagStore')(
        withLoadable({
            loader: ({ tagStore, fileId }: ExportTagsModalProps) => tagStore.fetchTagsInUse(fileId),
            loading: null
        })(ExportTagsModal)
    )
);
