import { observer } from 'mobx-react';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import placeholder from '../../../../assets/images/placeholder-avatar.png';
import InjectedComponent from '../../../../framework/utils/InjectedComponent';
import UserUiState from '../../../../ui-states/UserUiState';
import ProfileInput from '../../../common/inputs/ProfileInput';
import SubmitButton from '../../../common/submit-button/SubmitButton';
import './PersonalDetails.scss';

type PersonalDetailsProps = {
    fullName: string;
    phone: string;
    email: string;
    hasUserResetPassword: boolean;
    onResetButtonClick(): void;
};

type InjectedProps = {
    userUiState: UserUiState;
};
@observer
class PersonalDetails extends InjectedComponent<InjectedProps, WithTranslation & PersonalDetailsProps> {
    render() {
        const { fullName, phone, email, onResetButtonClick, t, hasUserResetPassword } = this.props;
        return (
            <div className="personal-details">
                <img src={placeholder} alt="" />
                <div>
                    <ProfileInput label={t('full name')} name="fullName" value={fullName} />
                    <ProfileInput label={t('phone')} name="phone" value={phone} />
                    <ProfileInput label={t('email')} name="email" value={email} />
                    {hasUserResetPassword ? (
                        <div data-testid="reset-message" className="reset-message">
                            <Trans i18nKey="resetMessage">{{ email }}</Trans>
                        </div>
                    ) : (
                        <SubmitButton secondary={true} onClick={() => onResetButtonClick()}>
                            {t('reset password')}
                        </SubmitButton>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(PersonalDetails);
