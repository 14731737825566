import { action, observable } from 'mobx';

export enum ModalType {
    CONFIRM_MODAL = 'CONFIRM_MODAL',
    CONFIGURE_PARTICIPANT_MODAL = 'CONFIGURE_PARTICIPANT_MODAL',
    EXPORT_TRANSCRIPTION_MODAL = 'EXPORT_TRANSCRIPTION_MODAL',
    EXPORT_TAGS_MODAL = 'EXPORT_TAGS_MODAL',
    CONVERT_TO_TAG_MODAL = 'CONVERT_TO_TAG_MODAL',
    ADD_FILES_TO_PROJECT_MODAL = 'ADD_FILES_TO_PROJECT_MODAL',
    TRANSCRIBER_SHORTCUTS_MODAL = 'TRANSCRIBER_SHORTCUTS_MODAL',
    MODIFY_BLOCK_OFFSET_MODAL = 'MODIFY_TRANSCRIPT_OFFSET_MODAL'
}

export default class ModalManagerUiState {
    @observable shownModal: ModalType | null = null;
    @observable modalProps: any = null;

    @action.bound
    openModal(modalType: ModalType, modalProps: {}) {
        this.shownModal = modalType;
        this.modalProps = {
            onHide: () => this.closeCurrentModal(),
            onClick: () => this.closeCurrentModal(),
            ...(modalProps || {})
        };
    }

    @action.bound
    closeCurrentModal() {
        this.shownModal = null;
    }
}
