import ApolloClient, { FetchPolicy } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { default as query, MobxApolloQuery } from 'mobx-apollo';
import { DocumentNode } from 'graphql';
import { when } from 'mobx';

export class MobxApolloStore {
    constructor(protected readonly client: ApolloClient<NormalizedCacheObject>) {}

    async awaitQuery<T>(query: MobxApolloQuery<T>) {
        await query.ref.result();
        return when(() => !query.loading);
    }

    protected query<T>(
        graphQLQuery: DocumentNode,
        variables?: null | Record<string, any>,
        fetchPolicy: FetchPolicy = 'no-cache', // 'network-only' does not work as expected, see: https://github.com/apollographql/react-apollo/issues/556
        onFetch?: (result: T) => void
    ) {
        return query<T>({
            client: this.client,
            query: graphQLQuery,
            variables: variables || undefined,
            fetchPolicy,
            onFetch
        });
    }

    protected async queryAndWait<T>(graphQLQuery: DocumentNode): Promise<MobxApolloQuery<T>> {
        const observableQuery = this.query<T>(graphQLQuery);
        await observableQuery.ref.result();
        return observableQuery;
    }
}
