import tinyColor from 'tinycolor2';
import { File, FileStatus } from '../../graphql/types';
import TimeUtils from './TimeUtils';
import moment from 'moment';

enum rowColor {
    blue = 'blue-row',
    orange = 'orange-row',
    noColor = ''
}

class ColorUtils {
    pickRandomColor = () => {
        const randomColorInHsl = tinyColor.random().toHsl();
        randomColorInHsl.l = 0.6;
        const randomColor = tinyColor(randomColorInHsl);
        return randomColor.toHexString();
    };

    rowBackgroundColor = (file: File): rowColor => {
        if (file.isAlmostDue) {
            return rowColor.orange;
        }
        if (file.status === FileStatus.UnderWork && !file.hasWorkStarted) {
            return rowColor.blue;
        }
        return rowColor.noColor;
    };
}

export default new ColorUtils();
