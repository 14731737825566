import React from 'react';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';

import './NormalInput.scss';

type ControlledInputProps = {
    icon: string;
    name: string;
    placeholder?: string;
    type?: InputType;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    onClick?: () => void;
    autoComplete?: string;
};

const ControlledInput = ({
    name,
    placeholder,
    type,
    icon,
    onChange,
    value,
    onClick,
    ...props
}: ControlledInputProps) => (
    <div className="input-wrapper">
        <div className={`input ${name}`}>
            <img src={icon} className="icon" alt="" />
            <Input
                name={name}
                type={type}
                data-testid={`test-${name}`}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onClick={onClick}
                {...props}
            />
        </div>
    </div>
);

ControlledInput.defaultProps = {
    type: 'text'
};

export default ControlledInput;
