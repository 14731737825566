import { gql } from 'apollo-boost';
import { baseFileFragment } from '../fragments/baseFileFragment';

export const fileQuery = gql`
    query file($id: ID!) {
        file(id: $id) {
            ...baseFile
            project {
                id
            }
            price {
                priceUnitType
                quantity
                pricePerUnit {
                    amount
                    currency
                }
                total {
                    amount
                    currency
                }
            }
        }
    }
    ${baseFileFragment}
`;
