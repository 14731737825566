import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import KeyboardTranslation from '../../../../framework/utils/KeyboardTranslation';
import SubmitButton from '../../submit-button/SubmitButton';
import './Speakers.scss';
import { Speaker } from '../../../../graphql/types';
import { Observer } from 'mobx-react';

type ParticipantsProps = {
    speakers: Speaker[];
    isCustomer: boolean;
    allowConfigureSpeakers?: boolean;
    onConfigureParticipantsButtonClick(): void;
} & WithTranslation;

const Speakers: React.FC<ParticipantsProps> = (props: ParticipantsProps) => {
    const { speakers, onConfigureParticipantsButtonClick, isCustomer, t, allowConfigureSpeakers = true } = props;
    return (
        <Observer>
            {() => (
                <div className="speakers">
                    {isCustomer ? (
                        <div>
                            <h3>{t('speakers')}:</h3>
                            <div>{speakers.map(speaker => speaker.name).join(', ')}</div>
                        </div>
                    ) : (
                        <>
                            <div className="speaker-header">
                                <h3>{t('speakers')}:</h3>
                                {allowConfigureSpeakers && (
                                    <SubmitButton
                                        className="configure-button"
                                        onClick={onConfigureParticipantsButtonClick}>
                                        {t('speakers settings')}
                                    </SubmitButton>
                                )}
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t('speaker name')}</th>
                                        <th>{t('shortcut')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {speakers.map((speaker, index) => {
                                        return (
                                            <tr key={index}>
                                                <td data-testid="speaker-name" className="speaker-name">
                                                    {speaker.name}
                                                </td>
                                                <td>
                                                    <span className="shortcut">
                                                        {KeyboardTranslation.translateCodeList(speaker.shortcut)}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            )}
        </Observer>
    );
};

export default withTranslation()(Speakers);
