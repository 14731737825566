import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import './TimeCodeDropDown.scss';

type TimeCodeDropDownState = {
    isDropDownOpen: boolean;
};

type TimeCodeDropDownProps = {
    timeCode: number | null;
    setTimeCode: (timeCode: number) => void;
};

class TimeCodeDropDown extends Component<TimeCodeDropDownProps, TimeCodeDropDownState> {
    state = {
        isDropDownOpen: false
    };

    render() {
        const { isDropDownOpen } = this.state;
        const { timeCode, setTimeCode } = this.props;
        return (
            <Dropdown
                isOpen={isDropDownOpen}
                toggle={() =>
                    this.setState(prevState => ({
                        isDropDownOpen: !prevState.isDropDownOpen
                    }))
                }
                data-testid="ts-dropdown">
                <DropdownToggle caret={true} outline={true} className="time-code-dropdown">
                    {timeCode}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    <DropdownItem onClick={() => setTimeCode(10)}>10</DropdownItem>
                    <DropdownItem onClick={() => setTimeCode(20)}>20</DropdownItem>
                    <DropdownItem onClick={() => setTimeCode(30)}>30</DropdownItem>
                    <DropdownItem onClick={() => setTimeCode(60)}>60</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default TimeCodeDropDown;
