import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import AdminTranscriptPage from './admin/admin-transcript-page/AdminTranscriptPage';
import withStores from '../framework/hoc/withStores';
import NotificationStore from '../stores/NotificationStore';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthService } from '../services/AuthService';

type InjectedProps = {
    authService: AuthService;
    notificationStore: NotificationStore;
};

@observer
class AdminPage extends Component<InjectedProps & WithTranslation> {
    render() {
        const {
            t,
            authService: { logout }
        } = this.props;

        return (
            <>
                <Switch>
                    <Route path={`/admin/transcript/:id`} component={AdminTranscriptPage} />

                    <Route
                        path={`/admin`}
                        render={() => (
                            <div className="text-center pt-5">
                                <h1>{t('פעולתך הסתיימה,')}</h1>

                                <h3 className="mt-4">{t('נא לסגור את החלון הנוכחי.')}</h3>

                                <p className="mt-4">
                                    {t('הנך מחובר כמנהל מערכת, ')}
                                    <Link to="/login" className="logout" onClick={() => logout()}>
                                        {t('logout')}.
                                    </Link>
                                </p>
                            </div>
                        )}
                    />
                </Switch>
            </>
        );
    }
}

export default withTranslation()(withStores('notificationStore', 'authService')(AdminPage));
