import { faBold, faItalic, faListOl, faListUl, faParagraph, faUnderline } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import './Toolbar.scss';
import ParagraphModel from '../../../../models/ParagraphModel';

const StyledButton = ({ icon, toggleInlineStyle, isActive, onMouseDown }: any) => (
    <Button
        onClick={toggleInlineStyle}
        onMouseDown={onMouseDown}
        color={isActive ? 'info' : 'secondary'}
        outline={true}>
        {' '}
        <FontAwesomeIcon icon={icon} />
    </Button>
);
const BlockButton = ({ icon, toggleBlockType, isActive, onMouseDown }: any) => (
    <Button onClick={toggleBlockType} onMouseDown={onMouseDown} color={isActive ? 'info' : 'secondary'} outline={true}>
        {' '}
        <FontAwesomeIcon icon={icon} />
    </Button>
);

const HeadingButton = ({ label, toggleBlockType, isActive, onMouseDown }: any) => (
    <Button onClick={toggleBlockType} onMouseDown={onMouseDown} color={isActive ? 'info' : 'secondary'} outline={true}>
        <span className="font-weight-bold">{label}</span>
    </Button>
);

@observer
class Toolbar extends Component<{ activeParagraph: ParagraphModel }> {
    render() {
        const { activeParagraph } = this.props;
        const {
            ItalicButton,
            BoldButton,
            UnderlineButton,
            OLButton,
            ULButton,
            H1Button,
            H2Button,
            H3Button,
            H4Button,
            H5Button,
            H6Button,
            ParagraphButton
            // todo: make more generic
        } = activeParagraph.plugins[0];

        return (
            <div className="editor-toolbar" key={activeParagraph.number}>
                <ButtonGroup>
                    <BoldButton>
                        <StyledButton icon={faBold} />
                    </BoldButton>
                    <ItalicButton>
                        <StyledButton icon={faItalic} />
                    </ItalicButton>
                    <UnderlineButton>
                        <StyledButton icon={faUnderline} />
                    </UnderlineButton>
                    <OLButton>
                        <BlockButton icon={faListOl} />
                    </OLButton>
                    <ULButton>
                        <BlockButton icon={faListUl} />
                    </ULButton>
                    <ParagraphButton>
                        <BlockButton icon={faParagraph} />
                    </ParagraphButton>
                    <H1Button>
                        <HeadingButton />
                    </H1Button>
                    <H2Button>
                        <HeadingButton />
                    </H2Button>
                    <H3Button>
                        <HeadingButton />
                    </H3Button>
                    <H4Button>
                        <HeadingButton />
                    </H4Button>
                    <H5Button>
                        <HeadingButton />
                    </H5Button>
                    <H6Button>
                        <HeadingButton />
                    </H6Button>
                </ButtonGroup>
            </div>
        );
    }
}

export default Toolbar;
