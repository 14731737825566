import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import message from '../../../../assets/images/blue-message.png';
import bubble from '../../../../assets/images/conversation-bubble.png';
import exportBlue from '../../../../assets/images/export-blue.png';
import quotes from '../../../../assets/images/quotes.png';
import './ExportBar.scss';
import _ from 'lodash';
import classnames from 'classnames';

type ExportBarProps = {
    onExportTranscriptionClick?: () => void;
    onExportSelectedParagraphsClick?: () => void;
    onExportTagsClick?: () => void;
    onConvertTagsClick?: () => void;
};

const ExportBarButton: React.FC<{
    callback: () => void;
    text: string;
    showSeperator: boolean;
    image: string;
    className?: string;
}> = ({ callback, text, image, className, showSeperator }) => (
    <li className={classnames(className)} onClick={callback}>
        <img src={image} alt="" />
        {text}
        {showSeperator && <span className="line-break">|</span>}
    </li>
);

const ExportBar = ({
    t,
    onExportTranscriptionClick,
    onExportSelectedParagraphsClick,
    onExportTagsClick,
    onConvertTagsClick
}: ExportBarProps & WithTranslation) => {
    const allCallbacks = _.compact([
        onExportTranscriptionClick,
        onExportTagsClick,
        onExportSelectedParagraphsClick,
        onConvertTagsClick
    ]);

    const shouldShowSeperator = (callback: () => void) => {
        return allCallbacks.indexOf(callback) < allCallbacks.length - 1;
    };

    return (
        <ul className="export-bar">
            {onExportTranscriptionClick && (
                <ExportBarButton
                    image={quotes}
                    className="quotes"
                    callback={onExportTranscriptionClick}
                    text={t('export transcript')}
                    showSeperator={shouldShowSeperator(onExportTranscriptionClick)}
                />
            )}
            {onExportTagsClick && (
                <ExportBarButton
                    image={exportBlue}
                    className="export"
                    callback={onExportTagsClick}
                    text={t('export tags')}
                    showSeperator={shouldShowSeperator(onExportTagsClick)}
                />
            )}
            {onExportSelectedParagraphsClick && (
                <ExportBarButton
                    image={bubble}
                    className="bubble"
                    callback={onExportSelectedParagraphsClick}
                    text={t('export selected text')}
                    showSeperator={shouldShowSeperator(onExportSelectedParagraphsClick)}
                />
            )}
            {onConvertTagsClick && (
                <ExportBarButton
                    image={message}
                    className="message"
                    callback={onConvertTagsClick}
                    text={t('convert selected text to tags')}
                    showSeperator={shouldShowSeperator(onConvertTagsClick)}
                />
            )}
        </ul>
    );
};

export default withTranslation()(ExportBar);
