import env from '../env';
import * as Sentry from '@sentry/browser';
import { action, computed, observable, reaction } from 'mobx';
import Toast from '../framework/utils/Toast';
import i18n from '../localization/i18n';
import UserStore from '../stores/UserStore';
import translations from '../localization/he/translation.json';
import { RouterStore } from 'mobx-react-router';
import qsUtils from '../framework/utils/qsUtils';
import { FullStoryAPI } from 'react-fullstory';
import { TypeOptions } from 'react-toastify';

export const API_TOKEN_KEY = 'API_TOKEN_KEY';

export default class AppUiState {
    @observable public apiToken: string | null = null;
    @observable public isAppLoading: boolean = false;

    constructor(private userStore: UserStore, private routerStore: RouterStore) {
        reaction(
            () => this.apiToken,
            apiToken => {
                if (apiToken) {
                    localStorage.setItem(API_TOKEN_KEY, apiToken);
                } else {
                    localStorage.removeItem(API_TOKEN_KEY);
                }
            }
        );

        reaction(
            () => this.userStore.user,
            user => {
                if (user) {
                    const { id, email } = user;
                    Sentry.configureScope(scope => scope.setUser({ id, email }));
                } else {
                    Sentry.configureScope(scope => scope.setUser(null));
                }
            }
        );
    }

    @action
    async init() {
        // try to get token from query string when accessed from django admin
        const { search } = this.routerStore.location;
        let apiToken = null;
        if (search) {
            const qs = qsUtils.parse(search);
            apiToken = qs.apiToken;
        }

        if (!apiToken) {
            apiToken = localStorage.getItem(API_TOKEN_KEY);
        }

        if (apiToken) {
            await this.tryInitSession(apiToken);
        }
    }

    @action
    public tryInitSession = async (apiToken: string) => {
        this.apiToken = apiToken;

        if (!this.isOTPVerified) {
            this.clearApiToken();
            return false;
        }

        try {
            await this.userStore.init();
        } catch (err) {
            this.clearApiToken();
            return false;
        }

        if (env.USE_FULLSTORY) {
            FullStoryAPI('identify', this.userStore.user.id);
        }

        return true;
    };

    showTranslatedToast = (msg: keyof typeof translations, type: TypeOptions = 'info') => {
        if (type === 'info') {
            this.showInfo(msg);
        } else if (type === 'error') {
            this.showError(msg);
        }
    };

    showInfo = (error: string) => {
        Toast.info(i18n.t(error));
    };
    showError = (error: string) => {
        Toast.error(i18n.t(error));
    };

    @action setAppLoadingState = (state: boolean) => (this.isAppLoading = state);

    @action
    clearApiToken = () => {
        this.apiToken = null;
        localStorage.removeItem(API_TOKEN_KEY);
    };

    @action
    setApiToken = (token: string) => {
        this.apiToken = token;
    };

    @computed
    get isOTPVerified() {
        if (!this.apiToken) {
            return false;
        }

        const base64Url = this.apiToken.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const payload = JSON.parse(atob(base64));
        return !!payload.otp_device_id;
    }

    @action
    private finishLoadingApp = () => (this.isAppLoading = false);
}
