import { action, computed, observable, reaction, runInAction } from 'mobx';
import { File, Speaker, Tag } from '../graphql/types';
import { PickedItem } from './SearchUiState';
import FileStore from '../stores/FileStore';
import SpeakerStore from '../stores/SpeakerStore';
import ProjectStore from '../stores/ProjectStore';

export default class SearchFieldsUiState {
    @observable isLoadingTags: boolean = false;
    @observable isLoadingSpeakers: boolean = false;
    @observable isLoadingFiles: boolean = false;
    @observable searchTerm: string = '';
    @observable pickedProject: PickedItem | null = null;
    @observable pickedFiles: PickedItem[] = [];
    @observable fromDate: Date | null = null;
    @observable toDate: Date | null = null;
    @observable pickedSpeakers: PickedItem[] = [];
    @observable pickedTags: PickedItem[] = [];
    @observable files: File[] = [];
    @observable speakers: Speaker[] = [];
    @observable tags: Tag[] = [];

    constructor(
        private readonly fileStore: FileStore,
        private readonly speakerStore: SpeakerStore,
        private readonly projectStore: ProjectStore
    ) {
        reaction(
            () => projectStore.projects,
            projects => {
                if (projects.length === 1) {
                    const project = projects[0];
                    this.setProject({ label: project.name, id: project.id }).catch(console.error);
                }
            }
        );
    }

    @action
    setSearchTerm = (searchTerm: string) => (this.searchTerm = searchTerm);
    @action
    setPickedProject = (pickedProject: PickedItem) => {
        this.pickedProject = pickedProject;
    };
    @action
    setPickedFiles = (pickedFile: PickedItem[]) => {
        this.clearSpeakers();
        this.pickedFiles = pickedFile;
    };

    @action
    setPickedTags = (tags: PickedItem[]) => (this.pickedTags = tags);

    @action
    setDates = (fromDate: Date, toDate: Date) => {
        this.fromDate = fromDate;
        this.toDate = toDate;
    };
    @action
    setPickedSpeakers = (speakers: PickedItem[]) => (this.pickedSpeakers = speakers);
    @action
    clearSpeakers = () => (this.pickedSpeakers = []);

    @action
    reset = () => {
        this.searchTerm = '';
        this.pickedProject = null;
        this.pickedFiles = [];
        this.fromDate = null;
        this.toDate = null;
        this.clearSpeakers();
        this.pickedTags = [];
    };
    @action
    setFiles = (files: File[]) => (this.files = files);
    @action
    setSpeakers = (speakers: Speaker[]) => (this.speakers = speakers);

    @action
    setTags = (tags: Tag[]) => (this.tags = tags);

    @computed get fields() {
        return {
            fromDate: this.fromDate,
            toDate: this.toDate,
            searchTerm: this.searchTerm,
            projectId: this.pickedProject && this.pickedProject.id,
            fileIds: this.pickedFiles.map(file => file.id),
            speakersIds: this.pickedSpeakers.map(speaker => speaker.id),
            tagsIds: this.pickedTags.map(tag => tag.id)
        };
    }

    @action
    setTagsLoading(isLoading: boolean) {
        this.isLoadingTags = isLoading;
    }

    @action
    setSpeakersLoading(isLoading: boolean) {
        this.isLoadingSpeakers = isLoading;
    }

    @action
    setFilesLoading(isLoading: boolean) {
        this.isLoadingFiles = isLoading;
    }

    @action setProject = async (pickedProject: PickedItem) => {
        this.setSpeakersLoading(true);
        this.setFilesLoading(true);
        this.setPickedProject(pickedProject);
        const [files, speakers] = await Promise.all([
            this.fileStore.getCompletedFilesNamesByProjectId(pickedProject.id),
            this.speakerStore.getSpeakerNamesByProjectId(pickedProject.id)
        ]);

        runInAction(() => {
            this.setFiles(files);
            this.setSpeakers(speakers);
            this.setSpeakersLoading(false);
            this.setFilesLoading(false);
        });
    };

    @action setFile = async (pickedFiles: PickedItem[]) => {
        this.setSpeakersLoading(true);
        this.setPickedFiles(pickedFiles);

        const ids = pickedFiles.map(file => file.id);
        const speakers = await this.speakerStore.getSpeakerNamesByFilesIds({ ids });

        this.setSpeakers(speakers);
        this.setSpeakersLoading(false);
    };
}
