import { gql } from 'apollo-boost';
import { slimProjectFragment } from './projectFragment';

export const projectsQuery = gql`
    query projects {
        projects {
            ...project
        }
    }
    ${slimProjectFragment}
`;
