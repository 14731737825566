import {
    CharacterMetadata,
    ContentState,
    convertToRaw,
    EditorChangeType,
    EditorState,
    EntityInstance,
    Modifier,
    SelectionState
} from 'draft-js';
import createRichButtonsPlugin from 'draft-js-richbuttons-plugin';
import { action, computed, observable } from 'mobx';
import uuid from 'uuid';
import { Macro, Paragraph, Tag } from '../graphql/types';
import BlockModel from './BlockModel';
import { convertFromHTML } from '../framework/draft/conversion';
import { EntityType } from '../framework/draft/entities';

export enum CursorPosition {
    START,
    END
}

export default class ParagraphModel {
    @computed
    get isEmpty() {
        return !this.editorState.getCurrentContent().hasText();
    }

    @computed
    get number() {
        return this.block!.paragraphs.indexOf(this);
    }

    @computed
    get isActive() {
        return this.block && this.block.transcript && this.block.transcript.activeParagraph === this;
    }

    @computed
    get asJson() {
        return { content: convertToRaw(this.editorState.getCurrentContent()), speakerId: this.speakerId };
    }

    @computed
    get isEndOfParagraph(): boolean {
        const selectionState = this.editorState.getSelection();
        const currentContent = this.editorState.getCurrentContent();
        return currentContent.getLastBlock().getText().length === selectionState.getEndOffset();
    }

    @computed
    get isStartOfParagraph(): boolean {
        return this.editorState.getSelection().getEndOffset() === 0;
    }

    @computed
    get nextParagraph() {
        const blockModel = this.block!;
        if (blockModel.paragraphs.length === this.number + 1) {
            return null;
        }
        return blockModel.paragraphs[this.number + 1];
    }

    @computed
    get previousParagraph(): ParagraphModel | null {
        if (this.number === 0) {
            return null;
        }

        return this.block!.paragraphs[this.number - 1];
    }

    @computed
    get tags() {
        const content = this.editorState.getCurrentContent();
        const tags = [] as Array<{ tagId: string; start: number; end: number; paragraph: number; block: number }>;
        content.getBlocksAsArray().forEach(block => {
            let selectedEntity: EntityInstance | null = null;
            block.findEntityRanges(
                (character: CharacterMetadata) => {
                    if (character.getEntity() !== null) {
                        const entity = content.getEntity(character.getEntity());
                        if (entity.getType() === EntityType.TAG) {
                            selectedEntity = content.getEntity(character.getEntity());
                            return true;
                        }
                    }
                    return false;
                },
                (start, end) => {
                    tags.push({
                        tagId: selectedEntity!.getData().id,
                        start,
                        end,
                        paragraph: this.number,
                        block: this.block!.number
                    });
                }
            );
        });

        return tags;
    }

    static fromHtml(block: BlockModel, paragraph: Paragraph, tags: Tag[]) {
        const editorState = EditorState.createWithContent(convertFromHTML(paragraph.body, tags));
        return new ParagraphModel(block, editorState, paragraph.speakerId);
    }

    static empty(block: BlockModel) {
        return new ParagraphModel(block, EditorState.createEmpty());
    }

    @observable editorState: EditorState;
    @observable speakerId: string | null;
    public plugins = [createRichButtonsPlugin()];
    public uuid = uuid.v4();
    @observable block?: BlockModel;

    private constructor(block: BlockModel, editorState: EditorState, speaker?: string | null) {
        this.editorState = editorState;
        this.block = block;
        this.speakerId = speaker || null;
    }

    @action
    setSpeaker = (speakerId?: string | null) => {
        this.speakerId = speakerId || null;
    };

    @action
    placeCursor = (cursorPosition: CursorPosition = CursorPosition.END): void => {
        const content = this.editorState.getCurrentContent();
        const firstBlock = content.getFirstBlock();
        const firstKey = firstBlock.getKey();

        if (cursorPosition === CursorPosition.END) {
            this.editorState = EditorState.moveFocusToEnd(this.editorState);
        } else {
            this.editorState = EditorState.forceSelection(
                this.editorState,
                new SelectionState({
                    anchorKey: firstKey,
                    anchorOffset: 0,
                    focusKey: firstKey,
                    focusOffset: 0,
                    isBackward: false
                })
            );
        }
    };

    tryReplaceWithText = (macro: Macro) => {
        const block = this.currentDraftBlock;
        const regex = new RegExp(macro.replace, 'g');
        const matchReg = regex.exec(block.getText());
        if (matchReg) {
            const start = matchReg.index;
            const end = start + matchReg[0].length;
            const newContentState = Modifier.replaceText(
                this.editorState.getCurrentContent(),
                new SelectionState({
                    anchorKey: block.getKey(),
                    anchorOffset: start,
                    focusKey: block.getKey(),
                    focusOffset: end
                }),
                macro.withText
            );

            this.setNewContent(newContentState);

            this.setEditorState(EditorState.forceSelection(this.editorState, newContentState.getSelectionAfter()));

            return true;
        }

        return false;
    };

    @action
    setNewContent(contentState: ContentState) {
        this.editorState = EditorState.set(this.editorState, { currentContent: contentState });
    }

    @action
    setEditorState(editorState: EditorState) {
        let newState = editorState;
        // When we focus on the draft js editor, an editor state is set on the first paragraph without the decorators
        // this is a workaround to never remove a paragraph's decorator
        if (newState.getDecorator() === null) {
            newState = EditorState.set(newState, { decorator: this.editorState.getDecorator() });
        }
        this.editorState = newState;
    }

    @action
    pushUndoableNewContent(contentState: ContentState, changeType: EditorChangeType = 'change-block-data') {
        this.editorState = EditorState.push(this.editorState, contentState, changeType);
    }

    @action
    setText(text?: string) {
        const contentState = ContentState.createFromText(text || '');
        this.setNewContent(contentState);
    }

    @action
    setBlock(block: BlockModel) {
        this.block = block;
    }

    @computed
    get firstDraftBlock() {
        return this.editorState.getCurrentContent().getFirstBlock();
    }

    @computed
    get currentDraftBlock() {
        const selection = this.editorState.getSelection();
        const currentContent = this.editorState.getCurrentContent();
        return currentContent.getBlockForKey(selection.getAnchorKey());
    }

    hasDraftBlock(blockKey: string) {
        return !!this.editorState.getCurrentContent().getBlockForKey(blockKey);
    }
}
