import React from 'react';

type CheckboxProps = {
    checked?: boolean;
    value: string;
    label?: string;
    onChange: () => void;
};

const Checkbox = ({ checked, onChange, value, label }: CheckboxProps) => {
    return (
        <label>
            {label}
            <input
                value={value}
                name={value}
                type="checkbox"
                checked={checked}
                onClick={e => {
                    e.stopPropagation();
                }}
                onChange={e => {
                    e.stopPropagation();
                    onChange();
                    return false;
                }}
                data-testid="checkbox"
            />
        </label>
    );
};

export default Checkbox;
