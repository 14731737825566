import { action, computed, observable } from 'mobx';
import { LoginMutationArgs } from '../graphql/types';
import { AuthService } from '../services/AuthService';

enum LoginSteps {
    CREDENTIALS = 'CREDENTIALS',
    OTP = 'OTP'
}
export default class LoginUiState {
    @observable step: LoginSteps = LoginSteps.CREDENTIALS;

    constructor(private authService: AuthService) {}

    @action
    proceedToOtpState = () => (this.step = LoginSteps.OTP);

    @action
    backToCredentialsStep = () => (this.step = LoginSteps.CREDENTIALS);

    @computed
    get isCredentialsStep() {
        return this.step === LoginSteps.CREDENTIALS;
    }

    @computed
    get isOtpStep() {
        return this.step === LoginSteps.OTP;
    }

    login = async ({ email, password }: LoginMutationArgs) => {
        await this.authService.login({ email, password });
        this.proceedToOtpState();
    };
}
