import React from 'react';
import { Input } from 'reactstrap';

import './ProfileInput.scss';

type ProfileInputProps = {
    name: string;
    value: string;
    label: string;
    children?: JSX.Element;
};

const ProfileInput = ({ name, value, label, children }: ProfileInputProps) => (
    <div className="profile-input-wrapper">
        <label htmlFor={name}>
            <span className="label">{label}</span>
            <div className="d-flex">
                <Input
                    className="profile-input"
                    name={name}
                    data-testid={`test-${name}`}
                    value={value}
                    readOnly={true}
                />
                {children}
            </div>
        </label>
    </div>
);

export default ProfileInput;
