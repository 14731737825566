import { Observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { FileSearchResult } from '../../../../graphql/types';
import SearchUiState from '../../../../ui-states/SearchUiState';
import '../SearchDisplay.scss';
import FilesSearchRow from './FilesSearchRow';
import withStores from '../../../../framework/hoc/withStores';
import Checkbox from '../../../common/checkbox/Checkbox';

type InjectedProps = {
    searchUiState: SearchUiState;
};

type FilesSearchDisplayProps = {
    results: FileSearchResult[];
} & InjectedProps &
    WithTranslation;

class FilesSearchDisplay extends Component<FilesSearchDisplayProps> {
    render() {
        return (
            <Observer>
                {() => {
                    const {
                        searchUiState: {
                            setActiveSearchResult,
                            activeSearchResult,
                            selectedFiles,
                            toggleSelectedFile,
                            toggleSelectAllFiles
                        },
                        results,
                        t
                    } = this.props;
                    return (
                        <div className="search-result">
                            <Table borderless={true} className="files-table">
                                <thead>
                                    <tr>
                                        <th />
                                        <th
                                            className="text-center"
                                            onClick={e => {
                                                e.stopPropagation();
                                                toggleSelectAllFiles();
                                            }}>
                                            <Checkbox
                                                checked={selectedFiles.length === results.length}
                                                value=""
                                                onChange={toggleSelectAllFiles}
                                            />
                                        </th>
                                        <th className="th-project">
                                            <span className="blue-and-normal">{t('project')}</span>
                                        </th>
                                        <th>
                                            <span className="blue-and-normal">{t('file')}</span>
                                        </th>
                                        <th>
                                            <span className="blue-and-normal">{t('recording date')}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results.map((result, index) => (
                                        <FilesSearchRow
                                            key={result.file.id}
                                            index={index + 1}
                                            isSelected={selectedFiles.includes(result.file.id)}
                                            isActive={activeSearchResult.resultIndex === index}
                                            file={result.file}
                                            onSelect={() => {
                                                toggleSelectedFile(result.file.id);
                                            }}
                                            onClick={() =>
                                                setActiveSearchResult({ resultIndex: index, paragraphIndex: 0 })
                                            }
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    );
                }}
            </Observer>
        );
    }
}

export default withTranslation()(withStores('searchUiState')(FilesSearchDisplay));
