import { action, computed, observable, runInAction } from 'mobx';
import { MobxApolloQuery } from 'mobx-apollo';
import { filedDueDateQuery } from '../graphql/customer/query/fileDueDate';
import { projectQuery } from '../graphql/customer/query/project';
import { projectsQuery } from '../graphql/customer/query/projects';
import { DateTime, EstimateFileDueDateQueryArgs, Project, ProjectQueryArgs } from '../graphql/types';
import { MobxApolloStore } from '../framework/mobx';

export default class ProjectStore extends MobxApolloStore {
    @observable project: Project | null = null;
    @observable projectsQuery: MobxApolloQuery<{ projects: Project[] }> | null = null;

    @action
    init() {
        this.projectsQuery = this.query(projectsQuery, {}, 'cache-first');
        return this.awaitQuery(this.projectsQuery);
    }

    @computed
    get projects() {
        return (this.projectsQuery && this.projectsQuery.data && this.projectsQuery.data.projects) || [];
    }

    @computed
    get error() {
        return this.projectsQuery && this.projectsQuery.error && this.projectsQuery.error.message;
    }

    @computed
    get loading() {
        return !!this.projectsQuery && this.projectsQuery.loading;
    }

    fetchProjectById = async ({ id }: ProjectQueryArgs) => {
        const result = await this.client.query<{ project: Project }, ProjectQueryArgs>({
            query: projectQuery,
            variables: { id },
            fetchPolicy: 'network-only'
        });

        runInAction(() => {
            this.project = result.data.project;
        });

        return result;
    };

    getDueDate = async ({ projectId }: EstimateFileDueDateQueryArgs) => {
        const { data } = await this.client.query<{ estimateFileDueDate: DateTime }>({
            query: filedDueDateQuery,
            variables: { projectId }
        });
        return data.estimateFileDueDate;
    };
}
