import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/lib/Creatable';
import { ActionMeta, ValueType } from 'react-select/lib/types';

type OptionType = {
    value: string;
    label: string;
};

type SelectProps = {
    options: OptionType[];
    className?: string;
    onChange: (value: ValueType<OptionType>, action: ActionMeta) => void;
};

const Select = ({ onChange, options, className, t }: SelectProps & WithTranslation) => {
    return (
        <CreatableSelect
            isClearable
            onChange={onChange}
            options={options}
            className={className ? className : ''}
            placeholder={t('select tag')}
            // @ts-ignore
            formatCreateLabel={inputValue => `${t('create new tag')}: ${inputValue}`}
        />
    );
};

export default withTranslation()(Select);
