import classNames from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';
import x from '../../../assets/images/x.png';
import InjectedComponent from '../../../framework/utils/InjectedComponent';
import FileUploadUiState, { Status } from '../../../ui-states/FileUploadUiState';
import SubmitButton from '../../common/submit-button/SubmitButton';
import './UploadFilesStatus.scss';
import LimitedText from '../../common/tooltip/LimitedText';

type InjectedProps = {
    fileUploadUiState: FileUploadUiState;
};

type UploadFilesStatusState = {
    isPopupOpen: boolean;
};

@inject('fileUploadUiState')
@observer
class UploadFilesStatus extends InjectedComponent<InjectedProps, WithTranslation, UploadFilesStatusState> {
    state = {
        isPopupOpen: true
    };

    render() {
        const { t } = this.props;
        const { fileUploadUiState } = this.injectedProps;
        const { isPopupOpen } = this.state;
        if (_.isEmpty(fileUploadUiState.projectsFilesStatus)) {
            return null;
        }
        return (
            <div className={classNames('upload-files-status', { scroller: isPopupOpen })}>
                <div
                    className={`popup-status ${isPopupOpen ? 'minimize-wrapper' : 'expand-wrapper'}`}
                    onClick={() => this.setState(prevState => ({ isPopupOpen: !prevState.isPopupOpen }))}>
                    {isPopupOpen ? (
                        <div className="minimize" />
                    ) : (
                        <div className="expand">
                            <div className="expand-icon" />
                            <div>{this.getSummary()}</div>
                        </div>
                    )}
                </div>
                {isPopupOpen && (
                    <div className="wrapper">
                        <h2>{this.title}</h2>
                        {fileUploadUiState.projectsFilesStatus.map((project, index) => (
                            <div key={index}>
                                <h3>
                                    <LimitedText content={project.projectName} maxLength={60} />
                                </h3>
                                <ul>
                                    {project.files.map((file, index) => (
                                        <li key={index}>
                                            <button
                                                className={classNames('cancel-button', {
                                                    invisible: this.isUploadComplete(file.uploadPercent)
                                                })}
                                                onClick={() => fileUploadUiState.cancelFile(project.projectId, index)}>
                                                <img src={x} alt="" />
                                            </button>
                                            <Progress
                                                animated={!this.isUploadComplete(file.uploadPercent)}
                                                color={this.isUploadComplete(file.uploadPercent) ? 'success' : ''}
                                                value={file.uploadPercent}
                                            />
                                            <LimitedText
                                                className="file-name"
                                                content={file.name}
                                                maxLength={12}
                                                component="div"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div className="cancel-buttons-area">
                            <div>{this.getSummary()}</div>
                            <SubmitButton secondary={true} onClick={fileUploadUiState.clearFiles}>
                                {this.buttonLabel}
                            </SubmitButton>
                        </div>
                    </div>
                )}
            </div>
        );
    }
    private isUploadComplete = (percent: number) => {
        return percent === 100;
    };
    private getSummary = () => {
        const {
            fileUploadUiState: { projectsFilesStatus }
        } = this.injectedProps;
        const { t } = this.props;
        const totalFiles = _.sumBy(projectsFilesStatus, project => project.files.length);
        const totalCompletedFiles = _.sumBy(projectsFilesStatus, project => {
            const { files } = project;
            const foundFiles = files.filter(file => file.uploadPercent === 100);
            return foundFiles.length;
        });
        return (
            <>
                <span className="font-weight-bold">{totalCompletedFiles}</span>/{totalFiles} {t('upload so far')}
            </>
        );
    };

    private get title(): string {
        const {
            fileUploadUiState: { projectsFilesStatus }
        } = this.injectedProps;
        const { t } = this.props;
        const files = _.flatMap(projectsFilesStatus, 'files');
        if (_.isEmpty(files)) {
            return t('no files in list');
        }
        if (files.some(file => file.status === Status.IN_PROGRESS)) {
            return t('uploading...');
        }
        return t('files uploaded successfully');
    }

    private get buttonLabel(): string {
        const {
            fileUploadUiState: { projectsFilesStatus }
        } = this.injectedProps;
        const { t } = this.props;
        const files = _.flatMap(projectsFilesStatus, 'files');
        if (files.some(file => file.status === Status.IN_PROGRESS)) {
            return t('cancel all');
        }
        return t('close');
    }
}

export default withTranslation()(UploadFilesStatus);
