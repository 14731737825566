import { action, computed, observable, runInAction } from 'mobx';
import { exportSelectedSearchResultsMutation } from '../graphql/customer/mutations/exportSelectedSearchResults';
import { searchQuery } from '../graphql/customer/query/search';
import {
    ExportSelectedSearchResultsMutation,
    ExportSelectedSearchResultsMutationArgs,
    FileSearchResult,
    SearchFilesInput,
    SearchResultsNode
} from '../graphql/types';
import { MobxApolloStore } from '../framework/mobx';
import moment from 'moment';
import { Omit } from '../framework/functional';

export default class SearchStore extends MobxApolloStore {
    @observable totalResults: number = 0;
    @observable searchResults: FileSearchResult[] = [];
    @observable isSearching: boolean = false;
    @observable lastSearch: Omit<SearchFilesInput, 'cursor'> | null = null;

    private lastCursor: number = 0;

    @action
    public search = async (options: SearchFilesInput) => {
        this.isSearching = true;
        const results = await this.doSearch(options, 0);
        runInAction(() => {
            this.isSearching = false;
            this.searchResults = results;
        });
        return results;
    };

    private async doSearch(options: SearchFilesInput, cursor: number) {
        this.lastSearch = options;
        options.fromDate = options.fromDate && moment(options.fromDate).format('YYYY-MM-DD');
        options.toDate = options.toDate && moment(options.toDate).format('YYYY-MM-DD');
        options.cursor = cursor;

        // Make sure to never cache search results, otherwise searching twice won't update the transcripts
        const query = await this.client.query<{ searchFiles: SearchResultsNode }>({
            query: searchQuery,
            variables: { input: options },
            fetchPolicy: 'no-cache'
        });

        const { results, cursor: newCursor, totalResults } = query.data.searchFiles;
        runInAction(() => {
            this.totalResults = totalResults;
            this.lastCursor = newCursor;
        });
        return results;
    }

    @action
    public searchMore = async (options: SearchFilesInput) => {
        const results = await this.doSearch(options, this.lastCursor);
        runInAction(() => {
            this.searchResults = this.searchResults.concat(results);
        });
    };

    @computed
    get hasSearched() {
        return !!this.lastSearch;
    }

    public exportSelectedSearchResults = async ({
        searchResults,
        exportFormat
    }: ExportSelectedSearchResultsMutationArgs) => {
        return await this.client.mutate<ExportSelectedSearchResultsMutation, ExportSelectedSearchResultsMutationArgs>({
            mutation: exportSelectedSearchResultsMutation,
            variables: { searchResults, exportFormat }
        });
    };

    @action
    reset() {
        this.lastCursor = 0;
        this.totalResults = 0;
        this.searchResults = [];
        this.isSearching = false;
        this.lastSearch = null;
    }
}
