import moment, { Moment } from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import React, { Component } from 'react';

import 'rc-calendar/assets/index.css';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DateTime } from '../../../../graphql/types';
import './RecordDate.scss';

type RecordDatePickerState = {
    date: Moment | null;
    showPicker: boolean;
};
type RecordDatePickerProps = {
    isCustomer: boolean;
    recordedAt?: string;
    onDatePicked: (date: DateTime) => Promise<void>;
};

class RecordDate extends Component<RecordDatePickerProps & WithTranslation, RecordDatePickerState> {
    state = {
        date: null,
        showPicker: false
    };
    componentDidMount(): void {
        const { recordedAt } = this.props;
        if (!!recordedAt) {
            return this.setState({ date: moment(new Date(recordedAt)) });
        }
    }

    render() {
        const { date } = this.state;
        const { onDatePicked, t, isCustomer } = this.props;
        const calendar = <Calendar value={date || undefined} onChange={date => this.setState({ date })} />;
        if (isCustomer) {
            return (
                <div className="customer-record-date">
                    <h3>{t('recording date')}:</h3>
                    {date && <div>{(date as Moment).format('DD/MM/YYYY')}</div>}
                </div>
            );
        }
        return (
            <div className="record-date-picker">
                <h3>{t('configure recording details')}:</h3>
                <div className="date-label">{t('recording date')}</div>
                <DatePicker
                    animation="slide-up"
                    calendar={calendar}
                    value={date}
                    onChange={(date: Moment) => {
                        this.setState({ date }, async () => {
                            await onDatePicked(date);
                        });
                    }}>
                    {({ value }: { value: Moment }) => {
                        return (
                            <input
                                placeholder="אנא בחר תאריך"
                                style={{ width: 250 }}
                                readOnly={true}
                                className="ant-calendar-picker-input ant-input"
                                value={value ? value.format('DD/MM/YYYY') : ''}
                                data-testid="date-picker-input"
                            />
                        );
                    }}
                </DatePicker>
            </div>
        );
    }
}

export default withTranslation()(RecordDate);
