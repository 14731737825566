// based on https://github.com/jamiebuilds/react-loadable api
import InlineSpinner from '../../modules/common/loading-spinner/InlineSpinner';
import React, { ComponentType } from 'react';
import { delay as delayAsync } from '../promises';
import { Subtract } from '../functional';

export type LoadableInjectedProps = {
    isLoading?: boolean;
    isPastDelay?: boolean;
    error?: boolean;
    reload?: (silent?: boolean) => void;
};

export const withLoadable = <T extends {}>({
    loader,
    loading = InlineSpinner,
    delay = 200
}: {
    loader: (props: T) => Promise<any>;
    loading?: React.ComponentType<any> | null;
    delay?: number;
    timeout?: number;
}) => (InnerComponent: ComponentType<T & LoadableInjectedProps>) => {
    class Wrapper extends React.Component<Subtract<T, LoadableInjectedProps>> {
        state = { isLoading: true, isPastDelay: false, error: false };

        componentDidMount() {
            this.fetch();
        }

        render() {
            const { isLoading, isPastDelay, error } = this.state;
            const LoadingComponent = loading;

            if (isLoading && LoadingComponent) {
                if (isPastDelay) {
                    return <LoadingComponent />;
                }

                return null;
            }
            return (
                <InnerComponent
                    isLoading={isLoading}
                    isPastDelay={isPastDelay}
                    error={error}
                    reload={this.fetch}
                    {...this.props}
                />
            );
        }

        private fetch = async (silent?: boolean) => {
            let finished = false;
            await new Promise(resolve =>
                this.setState({ isLoading: !silent, isPastDelay: false, error: false }, resolve)
            );

            try {
                delayAsync(delay).then(() => {
                    if (!finished) {
                        this.setState({ isPastDelay: true });
                    }
                });
                await loader((this.props as unknown) as T);
            } catch (error) {
                console.error('Component async init error', error);
                this.setState({ error: true });
            } finally {
                finished = true;
                this.setState({ isLoading: false });
            }
        };
    }

    return Wrapper;
};
