import { gql } from 'apollo-boost';

export const transcriberFragmentFragment = gql`
    fragment transcriber on Transcriber {
        id
        shouldNotifyEmail
        priceMethods {
            pricingUnit
            unitPrice {
                amount
                currency
            }
        }
        macros {
            replace
            withText
        }
        editorShortcuts {
            shortcutType
            keys
        }
        videoShortcuts {
            shortcutType
            keys
        }
    }
`;
