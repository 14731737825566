import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SubmitButton from '../../submit-button/SubmitButton';
import './DownloadLinkMessage.scss';

type DownloadLinkMessageProps = {
    downloadLink: string;
    onClick(): void;
};

const DownloadLinkMessage = ({ downloadLink, onClick, t }: DownloadLinkMessageProps & WithTranslation) => (
    <div className="download-link text-center">
        <p>
            {t('if the download doesnt start, click the link')}:
            <a href={downloadLink} target="_blank">
                {t('click to download link')}
            </a>
        </p>
        <SubmitButton secondary={true} onClick={onClick}>
            {t('close')!}
        </SubmitButton>
    </div>
);

export default withTranslation()(DownloadLinkMessage);
