import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Prompt, Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import ShortcutService from '../../../services/ShortcutService';
import FileStore from '../../../stores/FileStore';
import UserStore from '../../../stores/UserStore';
import TranscriptUiState from '../../../ui-states/TranscriptUiState';
import VideoPlayerUiState from '../../../ui-states/VideoPlayerUiState';
import HeaderInfo from '../../common/header-info/HeaderInfo';
import TextEditor from '../../common/text-editor/TextEditor';
import Title from '../../common/title/Title';
import VideoPlayer from '../../common/video-player/VideoPlayer';
import withStores from '../../../framework/hoc/withStores';
import { withLoadable } from '../../../framework/hoc/withLoadable';
import TranscriberSubmitButtons from './header-info/TranscriberSubmitButtons';
import { ModalService } from '../../../services/ModalService';
import './TranscriberTranscriptPage.scss';

interface InjectedProps extends RouteComponentProps {
    fileStore: FileStore;
    transcriptUiState: TranscriptUiState;
    userStore: UserStore;
    shortcutService: ShortcutService;
    videoPlayerUiState: VideoPlayerUiState;
    reload: () => void;
    modalService: ModalService;
}

type ParamsProps = {
    id: string;
};

type TranscriberTranscriptPageProps = InjectedProps & RouteComponentProps<ParamsProps> & WithTranslation;

@observer
class TranscriberTranscriptPage extends Component<TranscriberTranscriptPageProps> {
    async componentDidMount() {
        const { transcriptUiState, modalService } = this.props;
        document.addEventListener('keydown', this.handleKeyDown);

        if (transcriptUiState.file && isEmpty(transcriptUiState.file.speakers)) {
            modalService.openParticipantsModal();
        }
    }

    async componentWillUnmount() {
        const { videoPlayerUiState, transcriptUiState } = this.props;

        document.removeEventListener('keydown', this.handleKeyDown);
        transcriptUiState.reset();
        videoPlayerUiState.clearVideo();
    }

    handleKeyDown = (e: KeyboardEvent) => this.props.shortcutService.handleKeyPress(e);

    componentDidUpdate(prevProps: RouteComponentProps<ParamsProps>) {
        const { match } = this.props;
        const { id } = match.params;
        if (prevProps.match.params.id !== id) {
            this.props.reload();
        }
    }

    render() {
        const {
            transcriptUiState: { file: currentFile },
            fileStore: { myFiles }
        } = this.props;
        const { t } = this.props;

        if (!currentFile || !currentFile.isAvailableForWork) {
            return <Redirect to="/transcriber" />;
        }

        return (
            <Container fluid={true}>
                <Prompt when={true} message={t('are you sure you want to leave the page?')} />
                <Title title={`${t('file name')}: ${currentFile!.mediaFileName}`} />
                <Row>
                    <Col xs="12">
                        <HeaderInfo
                            file={currentFile!}
                            myFiles={myFiles}
                            showTranscriberFilesDropDown
                            submitButtons={<TranscriberSubmitButtons file={currentFile} />}
                        />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs="8" className="transcript-bottom-padder">
                        <TextEditor canEditShortcuts />
                    </Col>
                    <Col xs="4">
                        <VideoPlayer file={currentFile!} showVideoTimePairing />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(
    withRouter(
        withStores(
            'fileStore',
            'transcriptUiState',
            'userStore',
            'shortcutService',
            'videoPlayerUiState',
            'modalService'
        )(
            withLoadable({
                loader: ({
                    transcriptUiState,
                    match: {
                        params: { id }
                    }
                }: TranscriberTranscriptPageProps) => transcriptUiState.initialize(id)
            })(TranscriberTranscriptPage)
        )
    )
);
