import qs, { IParseOptions, IStringifyOptions } from 'qs';

export const parse = (text: string, opts?: IParseOptions): any =>
    qs.parse(text, { ignoreQueryPrefix: true, allowDots: true, ...opts });

export const stringify = (obj: any, opts?: IStringifyOptions): string => qs.stringify(obj, opts);

export default {
    parse,
    stringify
};
