export interface SearchFilesInput {
    cursor?: number | null;

    searchTerm?: string | null;

    projectId?: string | null;

    fileIds?: string[] | null;

    fromDate?: Date | null;

    toDate?: Date | null;

    speakersIds?: string[] | null;

    tagsIds?: string[] | null;
}

export interface SpeakerInput {
    id?: string | null;

    name: string;

    shortcut: string[];
}

export interface TagInput {
    id?: string | null;

    name: string;

    color: string;
}

export interface SelectedResultInput {
    fileId: string;

    blockId: string;

    paragraphId: string;

    id: string;

    text: string;
}

export interface SelectedBlockInput {
    fileId: string;

    blockId: string;
}

export interface ConvertToTagInput {
    fileId: string;

    blockId: string;

    paragraphId?: string | null;
}

export interface BlockInput {
    blockNumber: number;

    blockOffset?: number | null;

    blockLength?: number | null;

    paragraphs: ParagraphInput[];
}

export interface ParagraphInput {
    number: number;

    speakerId?: string | null;

    body: string;
}

export interface TagLocationInput {
    tagId: string;

    block: number;

    paragraph: number;

    start: number;

    end: number;
}

export interface MacroInput {
    replace: string;

    withText: string;
}

export interface EditorShortcutsInput {
    keys: string[];

    shortcutType: EditorShortcutType;
}

export interface VideoPlayerShortcutsInput {
    keys: string[];

    shortcutType: VideoShortcutType;
}
/** An enumeration. */
export enum NotificationType {
    FileTranscriberAssigned = 'FILE_TRANSCRIBER_ASSIGNED',
    FileRepairsRequired = 'FILE_REPAIRS_REQUIRED',
    FileTranscriptionCompleted = 'FILE_TRANSCRIPTION_COMPLETED',
    FileUrgencyUpdated = 'FILE_URGENCY_UPDATED',
    FileAlmostDue = 'FILE_ALMOST_DUE',
    FileNewUrgencyWage = 'FILE_NEW_URGENCY_WAGE',
    ProjectCreated = 'PROJECT_CREATED',
    ProjectEmptyLongTime = 'PROJECT_EMPTY_LONG_TIME'
}
/** An enumeration. */
export enum ExportJobStatus {
    InProgress = 'IN_PROGRESS',
    Complete = 'COMPLETE',
    Failed = 'FAILED'
}
/** An enumeration. */
export enum ProjectStatus {
    AwaitingFiles = 'AWAITING_FILES',
    UnderWork = 'UNDER_WORK',
    Closed = 'CLOSED'
}
/** An enumeration. */
export enum FileStatus {
    New = 'NEW',
    InAuction = 'IN_AUCTION',
    UnderWork = 'UNDER_WORK',
    PendingApproval = 'PENDING_APPROVAL',
    BeingAmended = 'BEING_AMENDED',
    AwaitingAmendment = 'AWAITING_AMENDMENT',
    Delivered = 'DELIVERED'
}
/** An enumeration. */
export enum PriceUnitType {
    Page = 'PAGE'
}
/** An enumeration. */
export enum UserRole {
    NotSet = 'NOT_SET',
    Customer = 'CUSTOMER',
    Transcriber = 'TRANSCRIBER',
    Admin = 'ADMIN'
}
/** An enumeration. */
export enum PricingUnit {
    PageByLineCount = 'PAGE_BY_LINE_COUNT',
    PageByWordCount = 'PAGE_BY_WORD_COUNT',
    RecordingHour = 'RECORDING_HOUR',
    Word = 'WORD',
    Characters = 'CHARACTERS'
}

export enum EditorShortcutType {
    InsertNewBlock = 'INSERT_NEW_BLOCK',
    InsertNewParagraph = 'INSERT_NEW_PARAGRAPH'
}

export enum VideoShortcutType {
    Stop = 'STOP',
    Play = 'PLAY',
    Pause = 'PAUSE',
    Rewind = 'REWIND',
    FastForward = 'FAST_FORWARD',
    ToggleMute = 'TOGGLE_MUTE'
}
/** An enumeration. */
export enum ExportFormat {
    Docx = 'DOCX',
    Avid = 'AVID',
    Srt = 'SRT',
    Txt = 'TXT'
}

/** The `DateTime` scalar type represents a DateTimevalue as specified by[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
export type DateTime = any;

/** The `Date` scalar type represents a Datevalue as specified by[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
export type Date = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

export interface Query {
    notifications: Notification[];

    tags: Tag[];

    tagsInUse: Tag[];

    transcript: Transcript;

    exportJobStatus: ExportJob;

    projects: Project[];

    project: Project;

    projectFiles: File[];

    projectSpeakers: Speaker[];

    estimateFileDueDate: DateTime;

    auctionFiles: File[];

    myFiles: File[];

    file: File;

    filesSpeakers: Speaker[];

    searchFiles: SearchResultsNode;

    totalProfitFromFiles: Money;

    lastSearches: LastSearchNode[];

    searchHistoryFiles: FileSearchResult[];

    currentUser: User;
}

export interface Notification {
    id: string;

    created: DateTime;

    title: string;

    description: string;

    notificationType: NotificationType;

    unread: boolean;

    fileId?: string | null;

    projectId?: string | null;
}

export interface Tag {
    id: string;

    name: string;

    color: string;
}

export interface Transcript {
    id: string;

    blockInterval: number;

    blocks: Block[];

    revision: number;

    startOffset: number;
}

export interface Block {
    number: number;

    offset?: number | null;

    length?: number | null;

    totalOffset?: number | null;

    paragraphs: Paragraph[];
}

export interface Paragraph {
    number: number;

    speakerId?: string | null;

    body: string;
}

export interface ExportJob {
    id: string;

    resultUrl: string;

    status: ExportJobStatus;
}

export interface Project {
    created: DateTime;

    id: string;

    name: string;

    status: ProjectStatus;

    files: File[];

    category: string;

    numOfFiles: number;

    numOfCompletedFiles: number;

    numOfInProgressFiles: number;

    dueDate?: DateTime | null;
}

export interface File {
    created: DateTime;

    id: string;

    project: Project;

    status: FileStatus;

    dueDate: DateTime;

    mediaFileName: string;

    encodingProgress: number;

    recordedAt?: DateTime | null;

    isUrgent: boolean;

    mediaFileUrl?: string | null;

    mediaFileDuration?: string | null;

    mediaStreamFileSignature?: string | null;

    category: string;

    price: Price;

    speakers: Speaker[];

    hasWorkStarted: boolean;

    isAvailableForWork: boolean;

    isAudioFile: boolean;

    isAlmostDue: boolean;

    wordCount: number;

    pageCount: number;
}

export interface Price {
    priceUnitType: PriceUnitType;

    quantity: number;

    pricePerUnit: Money;

    total: Money;
}

export interface Money {
    amount: number;

    currency: string;
}

export interface Speaker {
    id?: string | null;

    name: string;

    shortcut: string[];

    inMultipleFiles: boolean;
}

export interface SearchResultsNode {
    totalResults: number;

    cursor: number;

    results: FileSearchResult[];
}

export interface FileSearchResult {
    file: File;

    results: ParagraphSearchResult[];
}

export interface ParagraphSearchResult {
    id: string;

    speaker?: Speaker | null;

    block: Block;

    startOffset: number;

    paragraph: Paragraph;

    blockInterval: number;

    text: string;
}

export interface LastSearchNode {
    id: string;

    term: string;
}

export interface User {
    id: string;

    email: string;

    dateJoined: DateTime;

    fullName: string;

    role: UserRole;

    shouldReceiveMail: boolean;

    phone: string;

    transcriber?: Transcriber | null;

    customer?: Customer | null;

    administrator?: Administrator | null;

    profileImg: string;
}

export interface Transcriber {
    id: string;

    priceMethods: PriceMethod[];

    shouldNotifyEmail: boolean;

    macros: Macro[];

    editorShortcuts: EditorShortcut[];

    videoShortcuts: VideoShortcut[];
}

export interface PriceMethod {
    pricingUnit: PricingUnit;

    unitPrice: Money;
}

export interface Macro {
    replace: string;

    withText: string;
}

export interface EditorShortcut {
    keys: string[];

    shortcutType: EditorShortcutType;
}

export interface VideoShortcut {
    keys: string[];

    shortcutType: VideoShortcutType;
}

export interface Customer {
    id: string;

    projects: Project[];
}

export interface Administrator {
    macros: Macro[];

    editorShortcuts: EditorShortcut[];

    videoShortcuts: VideoShortcut[];
}

export interface Mutation {
    readNotifications: ReadNotificationsMutation;

    modifySpeakers: ModifySpeakersMutation;

    modifyRecordingDate: ModifyRecordingDateMutation;

    setTranscriptInterval: SetTranscriptIntervalMutation;

    addTag: AddTagMutation;

    modifyTag: ModifyTagMutation;

    exportTranscript: ExportTranscriptMutation;

    exportSelectedSearchResults: ExportSelectedSearchResultsMutation;

    exportSelectedBlocks: ExportSelectedBlocksMutation;

    exportSelectedFiles: ExportSelectedFilesMutation;

    exportTags: ExportTagsMutation;

    convertToTags: ConvertToTagsMutation;

    saveTranscript: SaveTranscriptMutation;

    modifyTranscriptOffset: ModifyTranscriptOffset;

    logWorkOnTranscript: LogWorkOnTranscriptMutation;

    sendFileForApproval: SendFileForApprovalMutation;

    sendFileForRepair: SendFileForRepairMutation;

    acceptWorkingOnFile: AcceptWorkingOnFileMutation;

    deleteFile: DeleteFileMutation;

    generateFileUploadUrl: GenerateFileUploadUrlMutation;

    addFile: AddFileMutation;

    approveFile: ApproveFileMutation;

    changeEmailNotifications: ChangeEmailNotificationsMutation;

    resetPassword: ResetPasswordMutation;

    modifyMacros: ModifyMacrosMutation;

    modifyEditorShortcuts: ModifyEditorShortcutsMutation;

    modifyVideoPlayerShortcuts: ModifyVideoPlayerShortcutsMutation;

    login: LoginMutation;

    verifyOtp: VerifyOtpMutation;

    resendToken: ResendTokenMutation;
}

export interface ReadNotificationsMutation {
    success: boolean;
}

export interface ModifySpeakersMutation {
    speakers: Speaker[];
}

export interface ModifyRecordingDateMutation {
    success: boolean;
}

export interface SetTranscriptIntervalMutation {
    success: boolean;
}

export interface AddTagMutation {
    addTag: {
        tag: Tag;
    };
}

export interface ModifyTagMutation {
    modifyTag: {
        tag: Tag;
    };
}

export interface ExportTranscriptMutation {
    exportTranscript: {
        jobId: string;
    };
}

export interface ExportSelectedSearchResultsMutation {
    exportSelectedSearchResults: {
        outFileUrl: string;
    };
}

export interface ExportSelectedBlocksMutation {
    exportSelectedBlocks: {
        outFileUrl: string;
    };
}

export interface ExportSelectedFilesMutation {
    jobId: string;
}

export interface ExportTagsMutation {
    exportTags: {
        outFileUrl: string;
    };
}

export interface ConvertToTagsMutation {
    success: boolean;
}

export interface SaveTranscriptMutation {
    success: boolean;

    transcript: Transcript;

    wasCorrected: boolean;
}

export interface ModifyTranscriptOffset {
    success: boolean;

    transcript: Transcript;
}

export interface LogWorkOnTranscriptMutation {
    success: boolean;
}

export interface SendFileForApprovalMutation {
    success: boolean;
}

export interface SendFileForRepairMutation {
    success: boolean;
}

export interface AcceptWorkingOnFileMutation {
    success: boolean;
}

export interface DeleteFileMutation {
    success: boolean;
}

export interface GenerateFileUploadUrlMutation {
    generateFileUploadUrl: {
        uploadUrl: string;
        uploadFields: string;
    };
}

export interface AddFileMutation {
    file: File;
}

export interface ApproveFileMutation {
    success: boolean;
}

export interface ChangeEmailNotificationsMutation {
    success: boolean;
}

export interface ResetPasswordMutation {
    success: boolean;
}

export interface ModifyMacrosMutation {
    success: boolean;

    transcriber: Transcriber;
}

export interface ModifyEditorShortcutsMutation {
    success: boolean;

    transcriber: Transcriber;
}

export interface ModifyVideoPlayerShortcutsMutation {
    success: boolean;

    transcriber: Transcriber;
}

export interface LoginMutation {
    login: {
        token: string;
    };
}

export interface VerifyOtpMutation {
    verifyOtp: {
        token: string;
    };
}

export interface ResendTokenMutation {
    success: boolean;
}

// ====================================================
// Arguments
// ====================================================

export interface TagsQueryArgs {
    searchTerm?: string | null;
}
export interface TagsInUseQueryArgs {
    fileId?: string | null;
}
export interface TranscriptQueryArgs {
    id: string;
}
export interface ExportJobStatusQueryArgs {
    id: string;
}
export interface ProjectQueryArgs {
    id: string;
}
export interface ProjectFilesQueryArgs {
    id: string;
}
export interface ProjectSpeakersQueryArgs {
    id: string;
}
export interface EstimateFileDueDateQueryArgs {
    projectId: string;
}
export interface FileQueryArgs {
    id: string;
}
export interface FilesSpeakersQueryArgs {
    ids: string[];
}
export interface SearchFilesQueryArgs {
    input: SearchFilesInput;
}
export interface SearchHistoryFilesQueryArgs {
    month?: string | null;

    priceUnit?: string | null;
}
export interface ReadNotificationsMutationArgs {
    notificationIds: string[];
}
export interface ModifySpeakersMutationArgs {
    speakers: SpeakerInput[];

    transcriptId: string;
}
export interface ModifyRecordingDateMutationArgs {
    date: DateTime;

    transcriptionId: string;
}
export interface SetTranscriptIntervalMutationArgs {
    interval: number;

    transcriptId: string;
}
export interface AddTagMutationArgs {
    tag: TagInput;
}
export interface ModifyTagMutationArgs {
    tag: TagInput;
}
export interface ExportTranscriptMutationArgs {
    exportFormat: ExportFormat;

    fileId: string;

    transcriptTemplateId?: string | null;
}
export interface ExportSelectedSearchResultsMutationArgs {
    exportFormat: ExportFormat;

    searchResults: SelectedResultInput[];
}
export interface ExportSelectedBlocksMutationArgs {
    exportFormat: ExportFormat;

    selectedBlocks: SelectedBlockInput[];
}
export interface ExportSelectedFilesMutationArgs {
    exportFormat: ExportFormat;

    fileIds: string[];
}
export interface ExportTagsMutationArgs {
    exportFormat: ExportFormat;

    fileId?: string | null;

    tagsIds: string[];
}
export interface ConvertToTagsMutationArgs {
    selectedInputs: ConvertToTagInput[];

    tagId: string;
}
export interface SaveTranscriptMutationArgs {
    blocks: BlockInput[];

    newRevision: number;

    tags: TagLocationInput[];

    transcriptId: string;
}
export interface ModifyTranscriptOffsetMutationArgs {
    startOffsetInSeconds: number;

    transcriptId: string;
}
export interface LogWorkOnTranscriptMutationArgs {
    transcriptId: string;
}
export interface SendFileForApprovalMutationArgs {
    id: string;
}
export interface SendFileForRepairMutationArgs {
    fileId: string;
}
export interface AcceptWorkingOnFileMutationArgs {
    id: string;
}
export interface DeleteFileMutationArgs {
    id: string;
}
export interface GenerateFileUploadUrlMutationArgs {
    fileName: string;

    fileType: string;
}
export interface AddFileMutationArgs {
    mediaFileName: string;

    mediaFileUrl: string;

    projectId: string;
}
export interface ApproveFileMutationArgs {
    fileId: string;
}
export interface ChangeEmailNotificationsMutationArgs {
    status: boolean;
}
export interface ModifyMacrosMutationArgs {
    macros: MacroInput[];
}
export interface ModifyEditorShortcutsMutationArgs {
    shortcuts: EditorShortcutsInput[];
}
export interface ModifyVideoPlayerShortcutsMutationArgs {
    shortcuts: VideoPlayerShortcutsInput[];
}
export interface LoginMutationArgs {
    email: string;

    password: string;
}
export interface VerifyOtpMutationArgs {
    otp: string;
}
