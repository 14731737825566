import { gql } from 'apollo-boost';

export const modifyTagMutation = gql`
    mutation modifyTag($tag: TagInput!) {
        modifyTag(tag: $tag) {
            tag {
                id
                color
                name
            }
        }
    }
`;
