import MultiSelect from '@khanacademy/react-multi-select';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { truncateByLength } from '../../../framework/utils/StringUtils';
import { PickedItem } from '../../../ui-states/SearchUiState';
import './MultiSelectDropDown.scss';
import classnames from 'classnames';

export type OptionType = { label: string; value: string };

type MultiSelectDropDownProps = {
    options: PickedItem[];
    label: string;
    disabled?: boolean;
    onChange: (items: PickedItem[]) => void;
    values: PickedItem[];
    className?: string;
    isLoading?: boolean;
    title?: string;
};
type MultiSelectDropDownState = {
    selected: string[];
};

class MultiSelectDropDown extends Component<MultiSelectDropDownProps & WithTranslation, MultiSelectDropDownState> {
    static defaultProps = {
        disabled: false
    };

    state = {
        selected: []
    };
    componentDidMount(): void {
        this.setState({ selected: this.props.values.map(value => value.id) });
    }

    componentDidUpdate(
        prevProps: Readonly<MultiSelectDropDownProps>,
        prevState: Readonly<{ selected: string[] }>,
        snapshot?: any
    ): void {
        if (prevProps.values !== this.props.values) {
            this.setState({ selected: this.props.values.map(value => value.id) });
        }
    }

    render() {
        const { options, label, disabled, onChange, className, isLoading, t, title } = this.props;
        const { selected } = this.state;
        const isDisabled = disabled || isLoading;
        return (
            <div className={classnames(className, 'd-flex align-items-center', { disabled: isDisabled })}>
                {title}
                <MultiSelect
                    options={this.mapPickedItemsToOptionTypes(options)}
                    selected={selected}
                    overrideStrings={{
                        selectSomeItems: label,
                        allItemsAreSelected: t('all items are selected'),
                        selectAll: t('select all'),
                        search: t('search')
                    }}
                    disabled={isDisabled}
                    disableSearch={isDisabled}
                    onSelectedChanged={(values: string[]) => {
                        this.setState({ selected: values });
                        onChange(this.mapOptionTypesToPickedItems(values));
                    }}
                    isLoading={isLoading}
                />
            </div>
        );
    }

    private mapPickedItemsToOptionTypes = (items: PickedItem[]): OptionType[] => {
        return items.map(item => {
            const label = truncateByLength(item.label, 80);
            return { label, value: item.id };
        });
    };

    private mapOptionTypesToPickedItems = (list: string[]): PickedItem[] => {
        const { options } = this.props;
        return list.map(item => {
            const foundItem = options.find(option => option.id === item);
            return {
                id: foundItem!.id,
                label: foundItem!.label
            };
        });
    };
}

export default withTranslation()(MultiSelectDropDown);
