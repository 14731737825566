import { Form, Formik, FormikActions } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import lock from '../../../assets/images/lock.png';
import heverLogo from '../../../assets/images/textify-logo.png';
import { loginOtpSchema } from '../../../framework/forms/validationSchema';
import { VerifyOtpMutationArgs } from '../../../graphql/types';
import FormikInput from '../../common/inputs/FormikInput';
import SubmitButton from '../../common/submit-button/SubmitButton';
import useFocusOnMount from '../../../framework/hooks/useFocusOnMount';

type OTPLoginProps = {
    sendOtp: (otp: string) => void;
    resendOtp: () => void;
    isResendingOtp: boolean;
} & WithTranslation;

const OTPLogin = ({ sendOtp, resendOtp, t, isResendingOtp }: OTPLoginProps) => {
    const formRef = useFocusOnMount<any>();

    return (
        <div data-testid="login-otp">
            <Formik
                validationSchema={loginOtpSchema}
                initialValues={{ otp: '' }}
                onSubmit={async (
                    values: VerifyOtpMutationArgs,
                    formikActions: FormikActions<VerifyOtpMutationArgs>
                ) => {
                    try {
                        await sendOtp(values.otp);
                    } catch (e) {
                        formikActions.setSubmitting(false);
                        formikActions.resetForm();
                    }
                }}
                render={({ isSubmitting, errors, touched, resetForm }) => (
                    <Form>
                        <div className="hever-logo">
                            <img src={heverLogo} alt="" />
                        </div>
                        <p>{t('please enter the number sent to your phone')}</p>
                        <FormikInput
                            ref={formRef}
                            name="otp"
                            placeholder={t('secret code')}
                            invalid={!!(errors.otp && touched.otp)}
                            type="tel"
                            minLength={4}
                            maxLength={4}
                            error={errors.otp!}
                            icon={lock}
                            autoComplete="off"
                        />
                        <div className="otp-form">
                            <SubmitButton isLoading={isResendingOtp} secondary={true} onClick={resendOtp}>
                                {t('did not got code')}
                            </SubmitButton>
                            <SubmitButton isLoading={isSubmitting} disable={!isEmpty(errors)}>
                                {t('enter')}
                            </SubmitButton>
                        </div>
                    </Form>
                )}
            />
        </div>
    );
};

export default withTranslation()(OTPLogin);
