import { Observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import TimeUtils from '../../../../framework/utils/TimeUtils';
import { FileSearchResult } from '../../../../graphql/types';
import SearchUiState from '../../../../ui-states/SearchUiState';
import '../SearchDisplay.scss';
import ParagraphSearchRow from './ParagraphSearchRow';
import LimitedText from '../../../common/tooltip/LimitedText';
import withStores from '../../../../framework/hoc/withStores';
import _ from 'lodash';
import BlockService from '../../../../services/BlockService';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    InfiniteLoader,
    List,
    ListRowProps,
    WindowScroller
} from 'react-virtualized';
import InlineSpinner from '../../../common/loading-spinner/InlineSpinner';
import classNames from 'classnames';

type InjectedProps = {
    searchUiState: SearchUiState;
};

type ParagraphSearchDisplayProps = {
    results: FileSearchResult[];
} & InjectedProps &
    WithTranslation;

type ParagraphSearchDisplayState = {
    isLoading: boolean;
};

class ParagraphsSearchDisplay extends Component<ParagraphSearchDisplayProps, ParagraphSearchDisplayState> {
    private cellMeasurerCache = new CellMeasurerCache({
        defaultHeight: 100,
        minHeight: 75,
        fixedWidth: true
    });

    constructor(props: ParagraphSearchDisplayProps) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    render() {
        const {
            searchUiState: { loadMoreResults, totalResults },
            results
        } = this.props;

        const { isLoading } = this.state;

        return (
            <div className="search-result">
                <InfiniteLoader
                    isRowLoaded={({ index }) => results.length > index}
                    threshold={3}
                    loadMoreRows={async ({ startIndex, stopIndex }) => {
                        if (stopIndex < results.length) {
                            return Promise.resolve(results.slice(startIndex, stopIndex));
                        }
                        this.setState({ isLoading: true });
                        await loadMoreResults();
                        this.setState({ isLoading: false });
                    }}
                    rowCount={totalResults}>
                    {({ onRowsRendered, registerChild }) => (
                        <WindowScroller>
                            {({ height, isScrolling, scrollTop, onChildScroll }) => (
                                <div className={classNames('position-relative', { 'loading-list': isLoading })}>
                                    <AutoSizer disableHeight>
                                        {({ width }) => (
                                            <List
                                                ref={registerChild}
                                                onRowsRendered={onRowsRendered}
                                                overscanRowCount={0}
                                                autoHeight
                                                isScrolling={isScrolling}
                                                scrollTop={scrollTop}
                                                onScroll={onChildScroll}
                                                height={height}
                                                width={width}
                                                rowCount={results.length}
                                                deferredMeasurementCache={this.cellMeasurerCache}
                                                rowHeight={this.cellMeasurerCache.rowHeight}
                                                rowRenderer={rowProps => this.renderRow(width, rowProps)}
                                            />
                                        )}
                                    </AutoSizer>
                                    {isLoading && (
                                        <div className="pagination-spinner">
                                            <InlineSpinner size="small" />
                                        </div>
                                    )}
                                </div>
                            )}
                        </WindowScroller>
                    )}
                </InfiniteLoader>
            </div>
        );
    }

    private renderRow = (width: number, { index: resultIndex, key, style, parent }: ListRowProps) => {
        return (
            <Observer>
                {() => {
                    const {
                        searchUiState: {
                            activeSearchResult,
                            setActiveSearchResult,
                            toggleSelectedResults,
                            selectedResults
                        },
                        results,
                        t
                    } = this.props;
                    const searchResult = results[resultIndex];
                    const file = searchResult.file;
                    return (
                        <CellMeasurer
                            key={key}
                            cache={this.cellMeasurerCache}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={resultIndex}
                            fixedWidth
                            defaultWidth={width}>
                            <Table borderless style={{ ...style, direction: 'rtl' }}>
                                <thead>
                                    <tr>
                                        <th className="project-column">
                                            <span className="blue-and-normal">{t('project')}: </span>
                                            <LimitedText content={searchResult.file.project.name} maxLength={60} />
                                        </th>
                                        <th className="file-record-date-column">
                                            <div>
                                                <span className="blue-and-normal">{t('file')}: </span>
                                                <Link to={`/customer/transcript/${searchResult.file.id}`}>
                                                    <LimitedText content={file.mediaFileName} maxLength={60} />
                                                </Link>
                                            </div>
                                            <div>
                                                <span className="blue-and-normal">{t('recording date')}: </span>
                                                <span>{TimeUtils.formatDate(file.recordedAt!, 'DD/MM/YY')}</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchResult.results.map((result, paragraphIndex) => {
                                        return (
                                            <ParagraphSearchRow
                                                body={result.text}
                                                resultId={result.id}
                                                speaker={result.speaker && result.speaker.name}
                                                timestamp={TimeUtils.millisecondsToDurationString(
                                                    BlockService.calculateBlockVideoPositionInMS(
                                                        result.block.totalOffset || 0,
                                                        result.block.number,
                                                        result.blockInterval
                                                    )
                                                )}
                                                onCheckBoxClick={() => {
                                                    this.cellMeasurerCache.clearAll();
                                                    toggleSelectedResults({
                                                        fileId: file.id,
                                                        blockId: result.block.number.toString(),
                                                        paragraphId: result.paragraph.number.toString(),
                                                        id: result.id,
                                                        text: result.text
                                                    });
                                                }}
                                                key={result.id}
                                                isActive={_.isEqual(activeSearchResult, {
                                                    resultIndex,
                                                    paragraphIndex
                                                })}
                                                searchResult={result}
                                                onClick={() => setActiveSearchResult({ resultIndex, paragraphIndex })}
                                                checked={selectedResults.map(result => result.id).includes(result.id)}
                                            />
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </CellMeasurer>
                    );
                }}
            </Observer>
        );
    };
}

export default withTranslation()(withStores('searchUiState')(ParagraphsSearchDisplay));
