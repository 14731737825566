import { gql } from 'apollo-boost';

export const notificationFragment = gql`
    fragment notification on Notification {
        id
        title
        description
        created
        unread
        fileId
        projectId
        notificationType
    }
`;
