import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';
import UserStore from '../../stores/UserStore';
import AppUiState from '../../ui-states/AppUiState';
import LoginUiState from '../../ui-states/LoginUiState';
import FullScreenLoadingSpinner from '../common/loading-spinner/FullScreenLoadingSpinner';
import CredentialsLogin from './forms/CredentialsLogin';
import OtpLogin from './forms/OTPLogin';
import './Login.scss';
import withStores from '../../framework/hoc/withStores';
import { UserRole } from '../../graphql/types';

type InjectedProps = {
    authService: AuthService;
    appUiState: AppUiState;
    userStore: UserStore;
    loginUiState: LoginUiState;
};

type LoginProps = InjectedProps & WithTranslation;

@observer
class Login extends Component<LoginProps> {
    state = {
        isResendingOtp: false
    };

    componentDidMount(): void {
        this.props.loginUiState.backToCredentialsStep();
    }

    public render() {
        const { isResendingOtp } = this.state;
        const {
            t,
            authService: { sendOtp, logout },
            appUiState,
            userStore,
            loginUiState
        } = this.props;
        if (userStore.loading) {
            return <FullScreenLoadingSpinner />;
        }
        if (appUiState.isOTPVerified) {
            if (userStore.isLoggedIn) {
                switch (userStore.userRole) {
                    case UserRole.Customer:
                        return <Redirect to="/customer" />;
                    case UserRole.Transcriber:
                        return <Redirect to="/transcriber" />;
                    case UserRole.Admin:
                        return <Redirect to="/admin" />;
                }
            }

            return (
                <div className="login-page">
                    <div className="text-center">
                        <h1>{t('user has no role')}</h1>
                        <Link to="/login" className="logout" onClick={() => logout()}>
                            {t('logout')}
                        </Link>
                    </div>
                </div>
            );
        }
        if (loginUiState.isCredentialsStep) {
            return (
                <div className="login-page">
                    <CredentialsLogin login={loginUiState.login} />
                </div>
            );
        }
        if (loginUiState.isOtpStep) {
            return (
                <div className="login-page">
                    <OtpLogin sendOtp={sendOtp} resendOtp={this.resendOtp} isResendingOtp={isResendingOtp} />
                </div>
            );
        }
    }

    private resendOtp = async () => {
        this.setState({ isResendingOtp: true });
        await this.props.authService.resendOtp();
        this.setState({ isResendingOtp: false });
    };
}

export default withTranslation()(withStores('authService', 'appUiState', 'userStore', 'loginUiState')(Login));
