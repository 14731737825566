import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { File, FileStatus } from '../../../../graphql/types';
import SubmitButton from '../../../common/submit-button/SubmitButton';
import withStores from '../../../../framework/hoc/withStores';
import TranscriptUiState from '../../../../ui-states/TranscriptUiState';

type SubmitButtonsProps = {
    file: File;
    transcriptUiState: TranscriptUiState;
};

const TranscriberSubmitButtons = ({
    t,
    history,
    file,
    transcriptUiState
}: SubmitButtonsProps & WithTranslation & RouteComponentProps) => {
    return (
        <>
            <SubmitButton secondary={true} onClick={() => history.push('/')}>
                {t('close file')}
            </SubmitButton>
            <SubmitButton disable={isDisabled(file)} onClick={transcriptUiState.openSubmitForApprovalModal}>
                {t('send for approval')}
            </SubmitButton>
        </>
    );
};

const isDisabled = (file: File) => file.status === FileStatus.PendingApproval;

export default withTranslation()(withRouter(withStores('transcriptUiState')(TranscriberSubmitButtons)));
