import _ from 'lodash';
import React, { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ListGroup, ListGroupItem } from 'reactstrap';
import searchIcon from '../../../../../assets/images/search.png';
import { LastSearchNode } from '../../../../../ui-states/SearchUiState';
import ControlledInput from '../../../../common/inputs/ControlledInput';
import './SearchInput.scss';

type SearchInputProps = {
    searchTerm: string;
    lastSearches: LastSearchNode[];
    shouldShowDropdown: boolean;
    onChange: (value: string) => void;
    onClick(): void;
    onResultClick(result: string): void;
    hideLastSearches(): void;
};

class SearchInput extends Component<SearchInputProps & WithTranslation> {
    render() {
        const { t, onChange, searchTerm, lastSearches, onClick, onResultClick, shouldShowDropdown } = this.props;
        return (
            <div className="search-input">
                <ControlledInput
                    autoComplete="off"
                    icon={searchIcon}
                    name="searchQuery"
                    placeholder={t('enter text')}
                    onChange={e => onChange(e.target.value)}
                    value={searchTerm}
                    onClick={onClick}
                />
                {shouldShowDropdown && !_.isEmpty(lastSearches) && (
                    <div className="last-searches" data-testid="last-searches">
                        <ListGroup>
                            {lastSearches.map((searchItem, index) => (
                                <ListGroupItem
                                    data-testid="search-item"
                                    key={index}
                                    onClick={() => {
                                        onResultClick(searchItem.term);
                                    }}>
                                    {searchItem.term}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                )}
            </div>
        );
    }

    private handleClickOutside = () => this.props.hideLastSearches();
}

export default withTranslation()(enhanceWithClickOutside(SearchInput));
