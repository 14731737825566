import { gql } from 'apollo-boost';
import { fileWithSlimSpeakersFragment } from '../../common/fragments/fileFragment';

export const projectQuery = gql`
    query project($id: ID!) {
        project(id: $id) {
            id
            name
            category
            numOfFiles
            numOfInProgressFiles
            numOfCompletedFiles
            created
            dueDate
            status
            files {
                ...file
            }
        }
    }
    ${fileWithSlimSpeakersFragment}
`;
