import _ from 'lodash';

class BlockService {
    public calculateBlockTimeCode = (
        allBlocks: Array<{ offset?: number | null; number: number }>,
        blockNumber: number,
        blockInterval: number
    ) => {
        const relevantBlocks = allBlocks.filter(x => x.number <= blockNumber);
        const offsets = _.sumBy(relevantBlocks, x => x.offset || 0);
        return this.calculateBlockVideoPositionInMS(offsets, blockInterval, blockNumber);
    };

    public calculateBlockVideoPositionInMS = (startOffset: number, blockInterval: number, blockNumber: number) => {
        return startOffset + blockInterval * blockNumber * 1000;
    };
}
export default new BlockService();
