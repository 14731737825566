import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import BlockModel from '../../../../models/BlockModel';
import TranscriptUiState from '../../../../ui-states/TranscriptUiState';
import VideoPlayerUiState from '../../../../ui-states/VideoPlayerUiState';
import './Block.scss';
import Entry from './entry/Entry';
import withStores from '../../../../framework/hoc/withStores';
import classnames from 'classnames';

type BlockProps = {
    block: BlockModel;
} & InjectedProps;

type InjectedProps = {
    transcriptUiState: TranscriptUiState;
    videoPlayerUiState: VideoPlayerUiState;
};

@observer
class Block extends Component<BlockProps> {
    render() {
        const {
            block,
            transcriptUiState: {
                setActiveParagraph,
                openModifyBlockOffsetModal,
                file: { speakers },
                transcript
            }
        } = this.props;
        return (
            <tr className="block" data-testid={`block-${block.number}`}>
                <td
                    data-testid="time-code"
                    className="time-code"
                    onClick={() => setActiveParagraph(block.firstParagraph)}>
                    <span>
                        {block.timestamp}
                        <span
                            className={classnames('modify-transcript', { 'block-offset-modified': block.offset > 0 })}
                            onClick={e => {
                                e.stopPropagation();
                                openModifyBlockOffsetModal(block);
                            }}>
                            <FontAwesomeIcon icon={faClock} />
                        </span>
                    </span>
                </td>
                <Entry
                    data-testid="text-block"
                    block={block}
                    activeParagraph={transcript!.activeParagraph}
                    speakers={speakers}
                />
            </tr>
        );
    }
}

export default withStores('transcriptUiState', 'videoPlayerUiState')(Block);
