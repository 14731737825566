import { gql } from 'apollo-boost';

export const searchTagsQuery = gql`
    query tags($searchTerm: String) {
        tags(searchTerm: $searchTerm) {
            id
            name
            color
        }
    }
`;
