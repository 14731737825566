import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import { hideMenu } from 'react-contextmenu/modules/actions';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import ColorUtils from '../../../framework/utils/ColorUtils';
import { Tag } from '../../../graphql/types';
import SubmitButton from '../../common/submit-button/SubmitButton';
import Circle from './circle/Circle';

type TagFormState = {
    isSaving: boolean;
    fields: Tag;
    showColorPicker: boolean;
};

type TagFormProps = {
    onFocus?: () => void;
    recommendedTags: Tag[];
    searchTags: (term: string) => void;
    tag?: Tag;
    label: string;
    onSubmit(tag: Tag): Promise<void>;
} & WithTranslation;

@observer
class TagForm extends Component<TagFormProps, TagFormState> {
    state = {
        isSaving: false,
        fields: {
            name: '',
            color: '',
            id: ''
        },
        showColorPicker: false
    };

    private debouncedSearchTags = _.debounce(async () => {
        const { searchTags } = this.props;
        const {
            fields: { name }
        } = this.state;
        searchTags(name);
    }, 300);

    async componentDidMount() {
        const { tag } = this.props;
        this.setState({
            fields: {
                name: tag ? tag.name : '',
                color: tag ? tag.color : ColorUtils.pickRandomColor(),
                id: tag ? tag.id : ''
            }
        });
    }

    componentWillUnmount(): void {
        this.debouncedSearchTags.cancel();
    }

    render() {
        const {
            isSaving,
            showColorPicker,
            fields: { name, color }
        } = this.state;
        const { t, label, onFocus } = this.props;
        return (
            <form onSubmit={this.onSubmit} onClick={e => e.stopPropagation()}>
                <label htmlFor="tagName">{t('enter tag name')}:</label>
                <div className="tag-input-wrapper">
                    <Input
                        name="tagName"
                        data-testid="tag"
                        id="tagName"
                        ref={(ref: any) => ref && ref.focus()}
                        onFocus={onFocus}
                        onChange={this.onNameChange}
                        value={name}
                        autoComplete="off"
                    />
                    <Circle color={color} onClick={this.toggleColorPicker} />
                </div>
                {showColorPicker && (
                    <TwitterPicker
                        color={ColorUtils.pickRandomColor()}
                        width="100%"
                        onChangeComplete={this.onColorChange}
                        triangle="hide"
                    />
                )}
                <SubmitButton disable={isSaving || !name} dataTestId="submit-tag">
                    {label}
                </SubmitButton>
            </form>
        );
    }

    private onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        this.setState(
            prevState => ({
                fields: {
                    ...prevState.fields,
                    name
                }
            }),
            async () => {
                if (!name || !name.trim()) {
                    this.props.searchTags('');
                    return;
                }
                await this.debouncedSearchTags();
            }
        );
    };

    private onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const { fields } = this.state;
        const { onSubmit } = this.props;
        this.setState({ isSaving: true });
        await onSubmit(fields);
        hideMenu();
    };

    private toggleColorPicker = () => {
        this.setState(prevState => ({
            showColorPicker: !prevState.showColorPicker
        }));
    };

    private onColorChange = (color: ColorResult) => {
        this.setState(
            prevState => ({
                fields: {
                    ...prevState.fields,
                    color: color.hex
                }
            }),
            () => this.toggleColorPicker()
        );
    };
}

export default withTranslation()(TagForm);
