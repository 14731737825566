import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { action, computed, observable } from 'mobx';
import { MobxApolloQuery } from 'mobx-apollo';
import Toast from '../framework/utils/Toast';
import { fileQuery } from '../graphql/common/queries/file';
import { addFileMutation } from '../graphql/customer/mutations/addFile';
import { deleteFileMutation } from '../graphql/customer/mutations/deleteFile';
import { generateFileUploadUrlMutation } from '../graphql/customer/mutations/generateFileUploadUrl';
import { sendFileForRepairMutation } from '../graphql/customer/mutations/sendFileForRepair';
import { acceptWorkingOnFileMutation } from '../graphql/transcriber/mutations/acceptWorkingOnFile';
import { modifyRecordingDateMutation } from '../graphql/transcriber/mutations/modifyRecordingDate';
import { sendFileForApprovalMutation } from '../graphql/transcriber/mutations/sendFileForApproval';
import { searchHistoryFilesQuery } from '../graphql/transcriber/queries/searchHistoryFiles';
import { totalProfitFromFilesQuery } from '../graphql/transcriber/queries/totalProfitFromFiles';
import {
    AcceptWorkingOnFileMutation,
    AcceptWorkingOnFileMutationArgs,
    AddFileMutation,
    AddFileMutationArgs,
    ApproveFileMutation,
    ApproveFileMutationArgs,
    DeleteFileMutation,
    DeleteFileMutationArgs,
    File as FileType,
    GenerateFileUploadUrlMutation,
    GenerateFileUploadUrlMutationArgs,
    ModifyRecordingDateMutation,
    ModifyRecordingDateMutationArgs,
    Money,
    ProjectFilesQueryArgs,
    SearchHistoryFilesQueryArgs,
    SendFileForApprovalMutation,
    SendFileForApprovalMutationArgs,
    SendFileForRepairMutation,
    SendFileForRepairMutationArgs
} from '../graphql/types';
import i18n from '../localization/i18n';
import { UploadFileStatus } from '../ui-states/FileUploadUiState';
import { MobxApolloStore } from '../framework/mobx';
import { filePageQuery } from '../graphql/transcriber/queries/filePageQuery';
import { approveFileMutation } from '../graphql/transcriber/mutations/approveFileMutation';
import { projectsFilesSearchQuery } from '../graphql/customer/query/projectFilesSearch';

const FILE_UPLOAD_CANCELED_MESSAGE = 'File upload canceled';

export default class FileStore extends MobxApolloStore {
    @observable private filesQuery: null | MobxApolloQuery<{ auctionFiles: FileType[]; myFiles: FileType[] }> = null;
    @observable private searchHistoricalFilesQuery: null | MobxApolloQuery<{ searchHistoryFiles: FileType[] }> = null;
    @observable private fileQuery: null | MobxApolloQuery<{ file: FileType }> = null;

    @computed
    get currentFile() {
        const file = this.fileQuery && this.fileQuery.data && this.fileQuery.data.file;
        return file;
    }

    @computed
    get myFiles() {
        return (this.filesQuery && this.filesQuery.data && this.filesQuery.data.myFiles) || [];
    }

    @computed
    get auctionFiles() {
        return (this.filesQuery && this.filesQuery.data && this.filesQuery.data.auctionFiles) || [];
    }

    @computed
    get historyFiles() {
        return (
            (this.searchHistoricalFilesQuery &&
                this.searchHistoricalFilesQuery.data &&
                this.searchHistoricalFilesQuery.data.searchHistoryFiles) ||
            []
        );
    }

    @action
    init() {
        this.filesQuery = this.query(filePageQuery, undefined, 'cache-and-network');
        return this.awaitQuery(this.filesQuery);
    }

    @action
    fetchFile = (id: string) => {
        this.fileQuery = this.query(fileQuery, { id }, 'cache-first');
        return this.awaitQuery(this.fileQuery);
    };

    sendFileForApproval = async ({ id }: SendFileForApprovalMutationArgs) => {
        return await this.client.mutate<SendFileForApprovalMutation, SendFileForApprovalMutationArgs>({
            mutation: sendFileForApprovalMutation,
            variables: { id },
            refetchQueries: ['myFiles', 'auctionFiles']
        });
    };

    approveFile = async ({ fileId }: ApproveFileMutationArgs) => {
        return await this.client.mutate<{ approveFile: ApproveFileMutation }, ApproveFileMutationArgs>({
            mutation: approveFileMutation,
            variables: { fileId },
            refetchQueries: ['file', 'transcript']
        });
    };

    acceptWorkingOnFile = async ({ id }: AcceptWorkingOnFileMutationArgs) => {
        await this.client.mutate<AcceptWorkingOnFileMutation, AcceptWorkingOnFileMutationArgs>({
            mutation: acceptWorkingOnFileMutation,
            variables: { id },
            refetchQueries: ['myFiles', 'auctionFiles']
        });
    };

    changeRecordingTime = async ({ date, transcriptionId }: ModifyRecordingDateMutationArgs) => {
        await this.client.mutate<ModifyRecordingDateMutation, ModifyRecordingDateMutationArgs>({
            mutation: modifyRecordingDateMutation,
            variables: { date, transcriptionId }
        });
    };

    getTotalProfitFromFiles = async () => {
        const query = await this.client.query<{ total: Money }>({
            query: totalProfitFromFilesQuery
        });
        return query.data.total;
    };

    searchHistoryFiles = async ({ month, priceUnit }: SearchHistoryFilesQueryArgs) => {
        const query = await this.client.query<{ searchHistoryFiles: FileType[] }>({
            query: searchHistoryFilesQuery,
            variables: { month, priceUnit }
        });

        return query.data.searchHistoryFiles;
    };

    getCompletedFilesNamesByProjectId = async (id: string) => {
        const query = await this.client.query<{ projectFiles: FileType[] }, ProjectFilesQueryArgs>({
            query: projectsFilesSearchQuery,
            variables: { id }
        });
        return query.data.projectFiles;
    };

    @action
    fetchHistoryFiles() {
        this.searchHistoricalFilesQuery = this.query(searchHistoryFilesQuery);
        return this.awaitQuery(this.searchHistoricalFilesQuery);
    }

    deleteFile = async ({ id }: DeleteFileMutationArgs) => {
        await this.client.mutate<DeleteFileMutation, DeleteFileMutationArgs>({
            mutation: deleteFileMutation,
            variables: { id },
            refetchQueries: ['project'],
            fetchPolicy: 'no-cache'
        });
    };

    sendFileForRepair = async ({ fileId }: SendFileForRepairMutationArgs) => {
        await this.client.mutate<SendFileForRepairMutation, SendFileForRepairMutationArgs>({
            mutation: sendFileForRepairMutation,
            variables: { fileId }
        });
    };

    generateFileUploadUrl = async ({ fileName, fileType }: GenerateFileUploadUrlMutationArgs) => {
        const { data } = await this.client.mutate<GenerateFileUploadUrlMutation, GenerateFileUploadUrlMutationArgs>({
            mutation: generateFileUploadUrlMutation,
            variables: { fileName, fileType }
        });
        return { url: data!.generateFileUploadUrl.uploadUrl, uploadFields: data!.generateFileUploadUrl.uploadFields };
    };

    addFile = async ({ mediaFileUrl, projectId, mediaFileName }: AddFileMutationArgs) => {
        await this.client.mutate<AddFileMutation, AddFileMutationArgs>({
            mutation: addFileMutation,
            variables: { mediaFileUrl, projectId, mediaFileName },
            refetchQueries: ['project'],
            fetchPolicy: 'no-cache'
        });
    };

    uploadToS3 = async (
        signedRequest: string,
        fileStatus: UploadFileStatus,
        data: { [x: string]: string },
        config: AxiosRequestConfig
    ) => {
        try {
            const formData = new FormData();

            for (const field of Object.keys(data)) {
                formData.append(field, data[field]);
            }
            formData.append('file', fileStatus.file);

            await axios.post(signedRequest, formData, config);
            return true;
        } catch (e) {
            if (e.message === FILE_UPLOAD_CANCELED_MESSAGE) {
                return false;
            }
            Toast.error(i18n.t('Failed to upload file.'));
            console.error(e);
            throw e;
        }
    };

    cancelUploadFiles = (cancelTokenSource: CancelTokenSource, cancelMessage = 'File upload canceled') => {
        cancelTokenSource.cancel(cancelMessage);
    };
}
