import { gql } from 'apollo-boost';
import { speakerFragment } from './speakerFragment';

export const baseFileFragment = gql`
    fragment baseFile on File {
        id
        mediaFileUrl
        mediaStreamFileSignature
        category
        status
        mediaFileDuration
        mediaFileName
        created
        dueDate
        recordedAt
        encodingProgress
        isAvailableForWork
        isUrgent
        speakers {
            ...speaker
        }
        hasWorkStarted
        isAudioFile
    }
    ${speakerFragment}
`;
