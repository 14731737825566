import { observer } from 'mobx-react';
import React, { Component } from 'react';
import SpeakerDropDown from '../SpeakerDropDown';
import LimitedText from '../../../tooltip/LimitedText';
import { Speaker } from '../../../../../graphql/types';

type ParagraphSpeakerProps = {
    isCustomer: boolean;
    speakers: Speaker[];
    speaker: Speaker | null;
    setSpeaker(speaker: Speaker | null): void;
};

type ParagraphSpeakerState = {
    isEditingSpeaker: boolean;
};
@observer
export default class ParagraphSpeaker extends Component<ParagraphSpeakerProps, ParagraphSpeakerState> {
    render() {
        const { isCustomer, speakers, speaker } = this.props;

        if (isCustomer) {
            const speakerName = speaker ? <LimitedText content={speaker.name} maxLength={20} /> : '-';
            return <span className="speaker">{speakerName}</span>;
        }
        return (
            <span className="speaker">
                {!speaker ? (
                    <SpeakerDropDown speakers={speakers} onClick={this.onDropDownClick} />
                ) : (
                    <span className="cursor-pointer" onClick={this.onSpeakerNameClick}>
                        {speaker.name}:
                    </span>
                )}
            </span>
        );
    }

    private onSpeakerNameClick = () => {
        this.props.setSpeaker(null);
    };

    private onDropDownClick = (speaker: Speaker) => {
        this.props.setSpeaker(speaker);
    };
}
