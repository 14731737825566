import { gql } from 'apollo-boost';
import { baseFileFragment } from '../../common/fragments/baseFileFragment';
import { blockFragment } from '../../common/fragments/blockFragment';

export const searchHistoryFilesQuery = gql`
    query searchHistoryFiles($month: String, $priceUnit: String) {
        searchHistoryFiles(month: $month, priceUnit: $priceUnit) {
            ...baseFile
            transcript {
                id
                blockInterval
                speakers {
                    id
                    name
                    shortcut
                }
                blocks {
                    ...block
                }
            }
            price {
                priceUnitType
                quantity
                pricePerUnit {
                    amount
                    currency
                }
                total {
                    amount
                    currency
                }
            }
        }
    }
    ${baseFileFragment}
    ${blockFragment}
`;
