import { action, computed, observable, runInAction } from 'mobx';
import { Tag } from '../graphql/types';
import { EntityLocation } from '../framework/draft/types';
import TagStore from '../stores/TagStore';
import TranscriptModel from '../models/TranscriptModel';
import * as _ from 'lodash';

export default class TagUiState {
    private readonly tagStore: TagStore;
    private transcript: TranscriptModel | null = null;
    private searchTerm: string = '';
    @observable isPopupTagOpen: boolean = false;
    @observable searchResults: Tag[] = [];
    MAX_TAG_RECOMMENDATIONS = 5;

    constructor(tagStore: TagStore) {
        this.tagStore = tagStore;
    }

    @action openPopupTag = () => {
        return (this.isPopupTagOpen = true);
    };

    @computed
    get allTags() {
        return _.sortBy(this.tagStore.allTags, x => x.name);
    }

    @computed
    get recommendedTags() {
        if (this.searchResults.length) {
            return _.sortBy(this.searchResults, x => x.name).slice(0, this.MAX_TAG_RECOMMENDATIONS);
        }

        if (this.searchTerm) {
            return [];
        }

        return this.allTags.slice(0, this.MAX_TAG_RECOMMENDATIONS);
    }

    @action
    init(transcript: TranscriptModel | null) {
        this.transcript = transcript;
        this.isPopupTagOpen = false;
        return this.tagStore.init();
    }

    @action closePopupTag = () => {
        this.searchTerm = '';
        this.isPopupTagOpen = false;
    };

    @action addTag = async (tag: Tag, tagEntityLocation: EntityLocation) => {
        let newOrExistingTag = this.tagStore.allTags.find(x => x.name.toLowerCase() === tag.name.toLowerCase());
        if (!newOrExistingTag) {
            newOrExistingTag = await this.tagStore.addTag(tag);
        }
        this.ensureTranscript().addTag(newOrExistingTag!, tagEntityLocation);
        this.closePopupTag();
    };

    @action searchTags = async (term: string) => {
        this.searchTerm = term;
        const results = await this.tagStore.filterTagsBySearchTerm(term);
        runInAction(() => {
            this.searchResults = results;
        });
    };

    @action modifyTag = async (tag: Tag, tagEntityLocation: EntityLocation) => {
        await this.tagStore.modifyTag(tag);
        this.ensureTranscript().modifyTag(tag, tagEntityLocation);
        this.closePopupTag();
    };

    @action removeTag = (tagEntityLocation: EntityLocation) => {
        this.ensureTranscript().removeTag(tagEntityLocation);
        this.closePopupTag();
    };

    private ensureTranscript() {
        if (!this.transcript) {
            throw new Error('TagUiState not initialized with transcript!');
        }
        return this.transcript;
    }
}
