import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TranscriptUiState from '../../../../ui-states/TranscriptUiState';
import './ConfigureSpeakersModal.scss';
import SpeakersForm from './SpeakersForm';
import { Speaker } from '../../../../graphql/types';
import withStores from '../../../../framework/hoc/withStores';
import InlineSpinner from '../../loading-spinner/InlineSpinner';
import { ModalService } from '../../../../services/ModalService';

type ConfigureParticipantsModalProps = {
    isOpen: boolean;
    transcriptId: string;
    fileId: string;
    serverSpeakers: Speaker[];
    onHide(): void;
} & WithTranslation &
    InjectedProps;

type InjectedProps = {
    transcriptUiState: TranscriptUiState;
};

@observer
class ConfigureSpeakersModal extends Component<ConfigureParticipantsModalProps> {
    state = {
        isSubmitting: false,
        isLoading: false,
        initialSpeakers: [],
        confirmMessage: null
    };

    componentDidMount() {
        const file = this.props.transcriptUiState.file;
        if (file.speakers.length === 0) {
            this.tryPopulateSpeakers();
        }
    }

    render() {
        const {
            isOpen,
            onHide,
            t,
            transcriptUiState: { file }
        } = this.props;
        const { speakers: serverSpeakers } = file!;
        const { isLoading, isSubmitting, initialSpeakers, confirmMessage } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" centered={true} backdrop={isEmpty(serverSpeakers)}>
                <div className="speakers-modal d-flex flex-column">
                    <ModalHeader>{t('speakers configuration')}</ModalHeader>
                    <ModalBody>
                        {isLoading ? (
                            <InlineSpinner />
                        ) : (
                            <table>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>{t('speakers names')}</th>
                                        <th>{t('shortcut')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <SpeakersForm
                                        initialSpeakers={initialSpeakers}
                                        serverSpeakers={serverSpeakers}
                                        onSubmit={this.onSubmit}
                                        confirmMessage={confirmMessage}
                                        onCancel={onHide}
                                        isLoading={isSubmitting}
                                    />
                                </tbody>
                            </table>
                        )}
                    </ModalBody>
                </div>
            </Modal>
        );
    }

    private onSubmit = async (newSpeakers: Speaker[]) => {
        const {
            transcriptUiState: { file, submitModifySpeakers }
        } = this.props;
        const { confirmMessage } = this.state;
        const { speakers: serverSpeakers } = file!;
        const { onHide, t } = this.props;
        const modifiedSpeakers = serverSpeakers.filter(
            x => x.inMultipleFiles && newSpeakers.some(y => x.id === y.id && x.name !== y.name)
        );

        if (modifiedSpeakers.length && !confirmMessage) {
            this.setState({
                confirmMessage: (
                    <>
                        {t('Are you sure you want to change the following speakers?')}
                        <br />
                        <ul>
                            {modifiedSpeakers.map(x => (
                                <li className="bg-transparent border-0" key={x.name}>
                                    {x.name}
                                </li>
                            ))}
                        </ul>
                    </>
                )
            });
            return false;
        }

        this.setState({ isSubmitting: true });
        await submitModifySpeakers(newSpeakers);
        this.setState({ isSubmitting: false });
        onHide();
    };

    private tryPopulateSpeakers = async () => {
        const { transcriptUiState } = this.props;
        this.setState({ isLoading: true });
        const speakers = await transcriptUiState.getProjectSpeakers();
        this.setState({ isLoading: false, initialSpeakers: speakers.map(x => ({ ...x, id: null })) });
    };
}

export default withTranslation()(withStores('transcriptUiState')(ConfigureSpeakersModal));
