import * as React from 'react';
import { Tag } from '../../../../graphql/types';
import ParagraphEditor from '../../../common/text-editor/block/paragraph-editor/ParagraphEditor';
import ParagraphModel from '../../../../models/ParagraphModel';

const ReadonlyParagraph: React.FC<{
    paragraph: ParagraphModel;
    speaker: string;
    tags: Tag[];
    setActiveParagraph: () => void;
}> = ({ paragraph, speaker, setActiveParagraph }) => {
    return (
        <>
            <span className="underline">
                <u>{speaker}</u>
            </span>
            <ParagraphEditor
                isCustomer
                paragraph={paragraph}
                onFocus={setActiveParagraph}
                handleKeyCommand={() => {
                    return 'not-handled';
                }}
                keyBindingFn={() => {
                    return 'not-handled';
                }}
            />
        </>
    );
};
export default ReadonlyParagraph;
