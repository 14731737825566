import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SearchStore from '../../../stores/SearchStore';
import SearchUiState, { SearchView } from '../../../ui-states/SearchUiState';
import Player from '../../common/player/Player';
import ExportBar from './export-bar/ExportBar';
import FilesSearchDisplay from './files-search-display/FilesSearchDisplay';
import ParagraphsSearchDisplay from './paragraphs-search-display/ParagraphsSearchDisplay';
import './SearchResults.scss';
import ReadonlyTranscript from './transcript/ReadonlyTranscript';
import withStores from '../../../framework/hoc/withStores';
import TagStore from '../../../stores/TagStore';
import InlineSpinner from '../../common/loading-spinner/InlineSpinner';

type InjectedProps = {
    searchStore: SearchStore;
    searchUiState: SearchUiState;
    tagStore: TagStore;
};

type SearchResultsProps = InjectedProps & WithTranslation;

const searchViews: { [index: string]: React.ComponentClass<any> | React.FunctionComponent<any> } = {
    [SearchView.PARAGRAPHS]: ParagraphsSearchDisplay,
    [SearchView.FILES]: FilesSearchDisplay
};

@observer
class SearchResults extends Component<SearchResultsProps> {
    render() {
        const {
            searchStore: { searchResults },
            searchUiState: {
                activeSearchView,
                activeSearchResultFile,
                activeTranscript,
                activeSearchResultParagraph,
                openExportTranscriptionModal,
                openExportSelectedParagraphModal,
                openConvertTagModal,
                openExportTagsModal,
                selectedResults,
                selectedFiles,
                isLoadingTranscript
            },
            t,
            tagStore
        } = this.props;
        const SearchViewComponent = searchViews[activeSearchView];
        if (isEmpty(searchResults)) {
            return <h3 className="text-center mt-5">{t('not results found')}</h3>;
        }

        const file = activeSearchResultFile!.file; // activeSearchResult will never be null if there are searchResults
        const transcript = activeTranscript;
        const allowExportTags =
            !selectedResults.length || selectedResults.every(x => x.fileId === selectedResults[0].fileId);

        const canExportFiles =
            (activeSearchView === SearchView.PARAGRAPHS && activeSearchResultFile) ||
            (activeSearchView === SearchView.FILES && selectedFiles.length > 0);

        return (
            <div className="search-results">
                <ExportBar
                    onExportTranscriptionClick={canExportFiles ? openExportTranscriptionModal : undefined}
                    onExportSelectedParagraphsClick={
                        activeSearchView === SearchView.PARAGRAPHS ? openExportSelectedParagraphModal : undefined
                    }
                    onConvertTagsClick={activeSearchView === SearchView.PARAGRAPHS ? openConvertTagModal : undefined}
                    onExportTagsClick={allowExportTags ? () => openExportTagsModal(file.id) : undefined}
                />
                <Row>
                    <Col md="7">
                        <SearchViewComponent results={searchResults} />
                    </Col>
                    <Col md="5">
                        <div className="left-panel-wrapper">
                            <Player
                                mediaFileUrl={file.mediaFileUrl}
                                isAudioFile={file.isAudioFile}
                                mediaStreamFileSignature={file.mediaStreamFileSignature}
                            />

                            {!transcript || isLoadingTranscript ? (
                                <InlineSpinner className="mt-5" />
                            ) : (
                                <ReadonlyTranscript
                                    // force transcript components to reload when active search result changes
                                    // otherwise tags decorator wont re-render as DraftJS plugin editor creates decorators on mount
                                    key={transcript.id}
                                    transcript={transcript}
                                    speakers={file.speakers}
                                    tags={tagStore.allTags}
                                    activeBlock={
                                        activeSearchResultParagraph && activeSearchResultParagraph.block.number
                                    }
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(withStores('searchStore', 'searchUiState', 'tagStore')(SearchResults));
