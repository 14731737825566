import { gql } from 'apollo-boost';
import { notificationFragment } from '../fragments/notificationFragment';

export const notificationsQuery = gql`
    query notifications {
        notifications {
            ...notification
        }
    }
    ${notificationFragment}
`;
