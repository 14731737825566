import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import add from '../../../../assets/images/blue-add.svg';
import x from '../../../../assets/images/x.png';
import { Macro, Transcriber } from '../../../../graphql/types';
import CommonTable from '../../../common/common-table/CommonTable';
import FormikInput from '../../../common/inputs/FormikInput';
import '../Tab.scss';
import ModalActions from '../../../common/modal/ModalActions';

type MacroShortcutTabProps = {
    transcriber: Transcriber;
    onHide(): void;
    onSubmit(macros: Macro[]): void;
};

function mapMacroToList(transcriber: Transcriber) {
    return transcriber.macros.map(macro => ({ replace: macro.replace, withText: macro.withText }));
}

const MacroShortcutTab = ({ transcriber, t, onHide, onSubmit }: MacroShortcutTabProps & WithTranslation) => {
    const columns = [
        {
            name: t(''),
            render: (macro: Macro & { index: number; arrayHelper: FieldArrayRenderProps }) => (
                <div onClick={() => macro.arrayHelper.remove(macro.index)}>
                    <img src={x} alt={t('delete shortcuts')} />
                </div>
            )
        },
        {
            name: t('replace'),
            render: (macro: Macro) => macro.replace
        },
        {
            name: t('with'),
            render: (macro: Macro) => macro.withText
        }
    ];
    return (
        <div data-testid="macros-tab" className="tab">
            <Formik
                initialValues={{
                    macros: mapMacroToList(transcriber),
                    replace: '',
                    withText: ''
                }}
                onSubmit={({ macros }) => console.debug(macros)}
                render={({ values, errors, touched, setFieldValue, isSubmitting }) => (
                    <Form>
                        <FormikInput
                            label={t('replace')}
                            name="replace"
                            placeholder="-->"
                            invalid={!!(errors.replace && touched.replace)}
                            error={errors.replace}
                            autoComplete="off"
                        />
                        <FormikInput
                            label={t('with')}
                            name="withText"
                            placeholder="..X"
                            invalid={!!(errors.withText && touched.withText)}
                            error={errors.withText}
                            autoComplete="off"
                        />
                        <FieldArray
                            name="macros"
                            render={(arrayHelper: FieldArrayRenderProps) => {
                                const extendedMacros = values.macros.map((macro, index) => ({
                                    ...macro,
                                    arrayHelper: { ...arrayHelper },
                                    index
                                }));
                                return (
                                    <>
                                        <div
                                            className="add"
                                            onClick={() => {
                                                if (!(values.replace && values.withText)) {
                                                    return;
                                                }
                                                setFieldValue('replace', '');
                                                setFieldValue('withText', '');
                                                return arrayHelper.push({
                                                    replace: values.replace,
                                                    withText: values.withText
                                                });
                                            }}>
                                            <img src={add} alt={t('add speaker')} />
                                            <span className="text">{t('add macro')}</span>
                                        </div>
                                        <CommonTable columns={columns} rows={extendedMacros} dataTestId="macro-row" />
                                    </>
                                );
                            }}
                        />
                        <ModalActions
                            isSubmitting={isSubmitting}
                            onSubmitClick={async () => {
                                await onSubmit(values.macros);
                                await onHide();
                            }}
                            submitText={t('save and close')}
                            onCancel={onHide}
                        />
                    </Form>
                )}
            />
        </div>
    );
};

export default withTranslation()(MacroShortcutTab);
