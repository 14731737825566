import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tag as TagType } from '../../../../graphql/types';
import TagForm from '../TagForm';
import './AddTagsPopup.scss';
import Tag from './Tag';

type TagsPopupState = {
    showingRecommendations: boolean;
};

type AddTagsPopupProps = {
    addTag: (tag: TagType) => Promise<void>;
    searchTags: (term: string) => void;
    recommendedTags: TagType[];
} & WithTranslation;

class AddTagsPopup extends Component<AddTagsPopupProps, TagsPopupState> {
    state = {
        showingRecommendations: false
    };

    render() {
        const { showingRecommendations } = this.state;
        const { t, addTag, recommendedTags, searchTags } = this.props;
        return (
            <div className="tags-popup" data-testid="tags-popup">
                {!isEmpty(recommendedTags) && showingRecommendations && (
                    <ul data-testid="recommendation-tag-list" className="recommendation-tag-list">
                        {recommendedTags.map((tag: TagType) => (
                            <Tag key={tag.id} tag={tag} addTag={addTag} />
                        ))}
                    </ul>
                )}
                <TagForm
                    onSubmit={addTag}
                    onFocus={() => this.setState({ showingRecommendations: true })}
                    recommendedTags={recommendedTags}
                    searchTags={searchTags}
                    label={t('add tag')}
                />
            </div>
        );
    }
}

export default withTranslation()(AddTagsPopup);
