import { gql } from 'apollo-boost';
import { speakerFragment } from '../../common/fragments/speakerFragment';

export const modifySpeakersMutation = gql`
    mutation modifySpeakers($speakers: [SpeakerInput]!, $transcriptId: ID!) {
        modifySpeakers(speakers: $speakers, transcriptId: $transcriptId) {
            speakers {
                ...speaker
            }
        }
    }
    ${speakerFragment}
`;
