const elementOrEmpty = (element: any) => (element || element === 0 ? element : '');

const joiner = (data: Array<Array<number | string>>, separator = ',', enclosingCharacter = '"') => {
    return data
        .filter(e => e)
        .map(row =>
            row
                .map(element => elementOrEmpty(element))
                .map(column => `${enclosingCharacter}${column}${enclosingCharacter}`)
                .join(separator)
        )
        .join(`\n`);
};

const jsons2csv = (
    data: Array<Array<number | string>>,
    headers: string[],
    separator: string,
    enclosingCharacter: string
) => joiner([headers, ...data], separator, enclosingCharacter);

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const buildURI = (headers: string[], data: Array<Array<number | string>>) => {
    const csv = jsons2csv(data, headers, ',', '"');
    const type = isSafari() ? 'application/csv' : 'text/csv';
    const blob = new Blob([csv], { type });
    const dataURI = `data:${type};charset=utf-8,${csv}`;

    // @ts-ignore
    const URL = window.URL || window.webkitURL;

    return typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
};
