import { Form, Formik, FormikActions } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import envelope from '../../../assets/images/envelope.png';
import lock from '../../../assets/images/lock.png';
import heverLogo from '../../../assets/images/textify-logo.png';
import { loginCredentialsSchema } from '../../../framework/forms/validationSchema';
import { LoginMutationArgs } from '../../../graphql/types';
import FormikInput from '../../common/inputs/FormikInput';
import SubmitButton from '../../common/submit-button/SubmitButton';
import useFocusOnMount from '../../../framework/hooks/useFocusOnMount';

type CredentialsLoginProps = {
    login: (values: LoginMutationArgs) => void;
};

const CredentialsLogin = ({ login, t }: CredentialsLoginProps & WithTranslation) => {
    const formRef = useFocusOnMount<any>();

    return (
        <div data-testid="login-credentials-page">
            <Formik
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={loginCredentialsSchema}
                initialValues={{ email: '', password: '' }}
                onSubmit={async (values: LoginMutationArgs, formikActions: FormikActions<LoginMutationArgs>) => {
                    try {
                        await login(values);
                    } catch (e) {
                        formikActions.setSubmitting(false);
                    }
                }}
                render={({ errors, touched, isValid, isSubmitting }) => (
                    <Form>
                        <div className="hever-logo">
                            <img src={heverLogo} alt="Textify" />
                        </div>
                        <p>{t('please enter credentials to log in to your account')}</p>
                        <FormikInput
                            name="email"
                            placeholder={t('emailLogin')}
                            invalid={!!(errors.email && touched.email)}
                            type="email"
                            error={errors.email!}
                            icon={envelope}
                            ref={formRef}
                        />
                        <FormikInput
                            name="password"
                            type="password"
                            placeholder={t('password')}
                            invalid={!!(errors.password && touched.password)}
                            error={errors.password!}
                            icon={lock}
                        />
                        <SubmitButton isLoading={isSubmitting} disable={!isEmpty(errors) || !isValid}>
                            {t('send code')}
                        </SubmitButton>
                    </Form>
                )}
            />
        </div>
    );
};

export default withTranslation()(CredentialsLogin);
