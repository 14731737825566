import { observer } from 'mobx-react';
import React, { Component } from 'react';
import BlockModel from '../../../../../models/BlockModel';
import TranscriptUiState from '../../../../../ui-states/TranscriptUiState';
import Paragraph from '../paragraph/Paragraph';
import './Entry.scss';
import withStores from '../../../../../framework/hoc/withStores';
import ParagraphModel from '../../../../../models/ParagraphModel';
import { Speaker } from '../../../../../graphql/types';

type InjectedProps = {
    transcriptUiState: TranscriptUiState;
};

type EntryProps = {
    block: BlockModel;
    activeParagraph: ParagraphModel;
    speakers: Speaker[];
} & InjectedProps;

@observer
class Entry extends Component<EntryProps> {
    private paragraphRef: any = null;

    render() {
        const { block, activeParagraph, speakers } = this.props;
        return (
            <td className="entry">
                {block.paragraphs.map(paragraph => {
                    return (
                        <Paragraph
                            key={paragraph.uuid}
                            isActive={activeParagraph === paragraph}
                            speakers={speakers}
                            paragraph={paragraph}
                            ref={ref => (this.paragraphRef = ref)}
                        />
                    );
                })}
            </td>
        );
    }
}

export default withStores('transcriptUiState')(Entry);
