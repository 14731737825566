import { gql } from 'apollo-boost';

export const modifyVideoPlayerShortcutsMutation = gql`
    mutation modifyVideoPlayerShortcuts($shortcuts: [VideoPlayerShortcutsInput!]!) {
        modifyVideoPlayerShortcuts(shortcuts: $shortcuts) {
            success
            transcriber {
                id
                videoShortcuts {
                    shortcutType
                    keys
                }
            }
        }
    }
`;
