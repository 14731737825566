import FilesTable from './FilesTable';
import React from 'react';
import { File, FileStatus, UserRole } from '../../../graphql/types';
import LimitedText from '../tooltip/LimitedText';
import TimeUtils from '../../../framework/utils/TimeUtils';
import { DeleteFileAction, EditTranscriptionAction, ViewTranscriptionAction } from './actions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ModalService } from '../../../services/ModalService';
import withStores from '../../../framework/hoc/withStores';
import EncodingProgressBar from './EncodingProgressBar';
import { Link } from 'react-router-dom';

const MyFilesTable: React.FC<
    {
        myFiles: File[];
        showProject?: boolean;
        isCustomer?: boolean;
        modalService: ModalService;
        rowClassSelector?: (item: File) => string;
    } & WithTranslation
> = ({ myFiles, t, isCustomer, modalService, rowClassSelector, showProject = false }) => {
    return (
        <FilesTable
            files={myFiles}
            rowClassSelector={rowClassSelector}
            columns={[
                {
                    name: t('file name'),
                    render: (file: File) => {
                        if (
                            (!isCustomer && file.status !== FileStatus.PendingApproval) ||
                            file.status === FileStatus.Delivered
                        ) {
                            const role = isCustomer
                                ? UserRole.Customer.toLowerCase()
                                : UserRole.Transcriber.toLowerCase();
                            return (
                                <Link to={`/${role}/transcript/${file.id}`}>
                                    <LimitedText content={file.mediaFileName} maxLength={60} />
                                </Link>
                            );
                        }
                        return <LimitedText content={file.mediaFileName} maxLength={60} />;
                    },
                    className: 'file-name-column text-right'
                },
                {
                    name: t('speakers'),
                    className: 'speakers-column text-right',
                    render: (file: File) => (
                        <LimitedText content={file.speakers.map(speaker => speaker.name).join(', ')} maxLength={20} />
                    )
                },
                {
                    name: t('status'),
                    render: (file: File) => t(file.status)
                },
                ...(!showProject
                    ? []
                    : [
                          {
                              name: t('project'),
                              render: (file: File) => <LimitedText content={file.project.name} maxLength={60} />,
                              className: 'project-column'
                          }
                      ]),
                {
                    name: t('total length'),
                    render: (file: File) => file.mediaFileDuration || ''
                },
                {
                    name: t('due date'),
                    render: (file: File) => TimeUtils.formatDate(file.dueDate, 'DD/MM/YYYY - HH:mm'),
                    className: 'duedate-column'
                },
                {
                    name: t('actions'),
                    render: (file: File) => {
                        if (!file.isAvailableForWork) {
                            return <EncodingProgressBar encodingProgress={file.encodingProgress} />;
                        }
                        if (!isCustomer) {
                            return <EditTranscriptionAction userRole="transcriber" file={file} />;
                        }

                        if (file.status === FileStatus.New) {
                            return (
                                <DeleteFileAction
                                    onClick={() => modalService.deleteTranscriptionModal(file.id, file.mediaFileName)}
                                />
                            );
                        }
                        if (file.status === FileStatus.Delivered) {
                            return <ViewTranscriptionAction fileId={file.id} userRole={UserRole.Customer} />;
                        }

                        return '';
                    },
                    className: 'actions-column'
                },
                {
                    name: t('word count'),
                    render: (file: File) => file.wordCount.toString()
                },
                {
                    name: t('page count'),
                    render: (file: File) => file.pageCount.toString()
                }
            ]}
        />
    );
};
export default withTranslation()(withStores('modalService')(MyFilesTable));
