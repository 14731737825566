import React, { RefObject, useEffect, useRef } from 'react';
import { Table } from 'reactstrap';
import EditorTagContextMenu from '../../context-menu/EditorTagContextMenu';
import './ReadonlyTranscript.scss';
import ReadonlyParagraph from './ReadonlyParagraph';
import { Speaker, Tag } from '../../../../graphql/types';
import classnames from 'classnames';
import _ from 'lodash';
import BlockService from '../../../../services/BlockService';
import TranscriptModel from '../../../../models/TranscriptModel';
import InlineSpinner from '../../../common/loading-spinner/InlineSpinner';
import { Observer } from 'mobx-react';

type ReadonlyTranscriptProps = {
    transcript: TranscriptModel;
    tags: Tag[];
    activeBlock: number | null;
    speakers: Speaker[];
};

const ReadonlyTranscript: React.FC<ReadonlyTranscriptProps> = ({ transcript, tags, activeBlock, speakers }) => {
    const transcriptRef = useRef(null) as RefObject<HTMLTableSectionElement>;
    const blockRefs = useRef(
        _.range(transcript.blocks.length).map(() => React.createRef() as RefObject<HTMLTableRowElement>)
    );
    useEffect(
        () => {
            const blockRef = activeBlock !== null && blockRefs.current[activeBlock];
            const blockNode = blockRef && blockRef.current;
            const transcriptNode = transcriptRef.current;
            if (blockNode && transcriptNode) {
                transcriptNode.scrollTo(0, blockNode.offsetTop);
            }
        },
        [activeBlock]
    );

    return (
        <Observer>
            {() => (
                <div className="readonly-transcript">
                    <Table>
                        <tbody ref={transcriptRef}>
                            {transcript.blocks.map((block, i) => (
                                <tr
                                    ref={blockRefs.current[i]}
                                    key={block.number}
                                    className={classnames({
                                        active: activeBlock === null ? false : activeBlock === block.number
                                    })}>
                                    <td>
                                        {`${block.timestamp} >`}
                                        <div>
                                            {transcript.isSaving && transcript.activeBlock === block && (
                                                <InlineSpinner size="tiny" />
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        {block.paragraphs.map(paragraphModel => {
                                            const speaker = speakers.find(x => x.id === paragraphModel.speakerId);
                                            return (
                                                <ReadonlyParagraph
                                                    tags={tags}
                                                    setActiveParagraph={() =>
                                                        transcript.setActiveParagraph(paragraphModel)
                                                    }
                                                    paragraph={paragraphModel}
                                                    speaker={(speaker && speaker.name) || ''}
                                                    key={paragraphModel.number}
                                                />
                                            );
                                        })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <EditorTagContextMenu />
                </div>
            )}
        </Observer>
    );
};

export default ReadonlyTranscript;
