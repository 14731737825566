import { gql } from 'apollo-boost';

export const speakerFragment = gql`
    fragment speaker on Speaker {
        id
        name
        shortcut
        inMultipleFiles
    }
`;
