import { isEmpty } from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TimeUtils from '../../../framework/utils/TimeUtils';
import { File, FileStatus } from '../../../graphql/types';
import { ModalService } from '../../../services/ModalService';
import FilePageUiState from '../../../ui-states/FilePageUiState';
import { AcceptFileAction } from '../../common/file-tables/actions';
import FilesTable from '../../common/file-tables/FilesTable';
import './FilesPage.scss';
import FilterFiles from './filter-files/FilterFiles';
import withStores from '../../../framework/hoc/withStores';
import FlexPage from '../../common/page/FlexPage';
import { withLoadable } from '../../../framework/hoc/withLoadable';
import LimitedText from '../../common/tooltip/LimitedText';
import EncodingProgressBar from '../../common/file-tables/EncodingProgressBar';
import MyFilesTable from '../../common/file-tables/MyFilesTable';
import moment from 'moment';
import { Observer } from 'mobx-react';
import useInterval from '../../../framework/hooks/useInterval';

type InjectedProps = {
    modalService: ModalService;
    filePageUiState: FilePageUiState;
    reload: (silent?: boolean) => void;
};

type FilesPageProps = InjectedProps & WithTranslation;

const FilesPage: React.FC<FilesPageProps> = ({ modalService, filePageUiState, t, reload }) => {
    const getBaseColumns = () => {
        return [
            {
                name: t('file name'),
                render: (file: File) => <LimitedText content={file.mediaFileName} maxLength={60} />,
                className: 'file-name-column text-right'
            }
        ];
    };

    const getAuctionFilesColumns = () => {
        return [
            ...getBaseColumns(),
            {
                name: t('category'),
                className: 'text-right',
                render: (file: File) => file.category
            },
            {
                name: t('file length'),
                render: (file: File) => file.mediaFileDuration || '-'
            },
            {
                name: t('project'),
                render: (file: File) => <LimitedText content={file.project.name} maxLength={60} />
            },
            {
                name: t('due date'),
                render: (file: File) => TimeUtils.formatDate(file.dueDate, 'DD/MM/YYYY - HH:mm')
            },
            {
                name: t('actions'),
                render: (file: File) =>
                    !file.isAvailableForWork ? (
                        <EncodingProgressBar encodingProgress={file.encodingProgress} />
                    ) : (
                        <AcceptFileAction
                            onClick={() =>
                                modalService.openAcceptWorkingOnTranscriptModal(
                                    file.id,
                                    file.mediaFileName,
                                    file.dueDate
                                )
                            }
                        />
                    ),
                className: 'actions-column'
            }
        ];
    };

    const filesRowClassSelector = (file: File) => {
        if (file.encodingProgress < 1) {
            return 'encoding';
        }
        if (file.isAlmostDue) {
            return 'almost-due';
        }
        if (file.status === FileStatus.UnderWork && !file.hasWorkStarted) {
            return 'under-work';
        }
        return '';
    };

    useInterval(() => reload(true));

    return (
        <Observer>
            {() => {
                const { myFiles, auctionFiles, searchTerm } = filePageUiState;
                return (
                    <FlexPage className="main-page">
                        <FilterFiles />
                        <h2>{t('auction files')}</h2>
                        {isEmpty(auctionFiles) ? (
                            <h3 className="text-center mt-5">
                                {!!searchTerm
                                    ? t('No auction files match search')
                                    : t('No new auction files available')}
                            </h3>
                        ) : (
                            <FilesTable
                                files={auctionFiles}
                                rowClassSelector={filesRowClassSelector}
                                columns={getAuctionFilesColumns()}
                                dataTestId="auction-files-row"
                            />
                        )}

                        <h2>{t('my files')}</h2>
                        {isEmpty(myFiles) ? (
                            <h3 className="text-center">
                                {!!searchTerm
                                    ? t('No assigned files match search')
                                    : t('There is no assigned files to you')}
                            </h3>
                        ) : (
                            <MyFilesTable myFiles={myFiles} rowClassSelector={filesRowClassSelector} showProject />
                        )}
                    </FlexPage>
                );
            }}
        </Observer>
    );
};

export default withTranslation()(
    withStores('modalService', 'filePageUiState')(
        withLoadable({ loader: ({ filePageUiState }: FilesPageProps) => filePageUiState.init() })(FilesPage)
    )
);
