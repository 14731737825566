import React, { Component } from 'react';
import { Tag as TagType } from '../../../../graphql/types';
import Circle from '../circle/Circle';

type TagProps = {
    tag: TagType;
    addTag: (tag: TagType) => void;
};

class Tag extends Component<TagProps> {
    render() {
        const { tag, addTag } = this.props;

        return (
            <li onClick={() => addTag(tag)} data-testid={`tag-${tag.id}`}>
                <div className="recommended-tags-wrapper">
                    {tag.name}
                    <Circle color={tag.color} />
                </div>
            </li>
        );
    }
}

export default Tag;
