import { uniq } from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import InjectedComponent from '../../../../framework/utils/InjectedComponent';
import { PriceUnitType } from '../../../../graphql/types';
import HistoryUiState from '../../../../ui-states/HistoryUiState';
import SearchDropDown from '../../../common/search-dropdown/SearchDropDown';
import SubmitButton from '../../../common/submit-button/SubmitButton';
import './SearchHistory.scss';

type SearchHistoryProps = {
    priceUnitTypes: PriceUnitType[];
    onSubmit: () => void;
};

type InjectedProps = {
    historyUiState: HistoryUiState;
};

@inject('historyUiState')
@observer
class SearchHistory extends InjectedComponent<InjectedProps, WithTranslation & SearchHistoryProps> {
    componentDidMount(): void {
        const { priceUnitTypes } = this.props;
        const { setUserPriceUnitTypes } = this.injectedProps.historyUiState;
        const userPriceUnitTypes = uniq(priceUnitTypes).map(priceType => ({ label: priceType, id: priceType }));
        setUserPriceUnitTypes(userPriceUnitTypes);
    }

    render() {
        const { onSubmit, t } = this.props;
        const {
            userMonths,
            pickedMonth,
            setPickedMonth,
            userPriceUnitTypes,
            pickedUnit,
            setPickedUnit
        } = this.injectedProps.historyUiState;
        return (
            <div className="title search-history">
                <h1>{t('history of employee')}</h1>
                <div className="dropdowns">
                    <SearchDropDown
                        options={userMonths}
                        pickedItem={pickedMonth && pickedMonth.label}
                        onClick={pickedMonth => setPickedMonth(pickedMonth)}
                        label={pickedMonth && pickedMonth.label}
                    />
                    <SearchDropDown
                        options={userPriceUnitTypes}
                        pickedItem={pickedUnit && pickedUnit.label}
                        onClick={pickedUnit => setPickedUnit(pickedUnit)}
                        label={t('unit type') as string}
                    />
                    <SubmitButton onClick={onSubmit}>חיפוש</SubmitButton>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchHistory);
