import { isSetsEqual } from '../framework/utils/setUtils';
import { VideoShortcutType } from '../graphql/types';
import UserStore from '../stores/UserStore';
import VideoPlayerUiState from '../ui-states/VideoPlayerUiState';
import { ModalService } from './ModalService';

export default class ShortcutService {
    actions: { [index: string]: () => void } = {
        [VideoShortcutType.Play]: () => this.play(),
        [VideoShortcutType.Stop]: () => this.stop(),
        [VideoShortcutType.Pause]: () => this.pause(),
        [VideoShortcutType.Rewind]: () => this.rewind(),
        [VideoShortcutType.FastForward]: () => this.fastForward(),
        [VideoShortcutType.ToggleMute]: () => this.toggleMute()
    };

    constructor(
        private modalService: ModalService,
        private userStore: UserStore,
        private videoPlayerUiState: VideoPlayerUiState
    ) {}

    handleKeyPress = (e: KeyboardEvent) => {
        const { videoShortcuts, isTranscriber } = this.userStore;

        const activeKeys = this.handleKeys(e);

        if (e.code === 'F1' && isTranscriber) {
            this.modalService.openShortcutsModal();
            return;
        }

        for (const shortcut of videoShortcuts) {
            if (isSetsEqual(new Set(shortcut.keys), activeKeys)) {
                return this.actions[shortcut.shortcutType]();
            }
        }
    };

    private play = () => this.videoPlayerUiState.setPlaying();
    private stop = () => this.videoPlayerUiState.resetVideoTime();
    private pause = () => this.videoPlayerUiState.setStopped();
    private rewind = () => this.videoPlayerUiState.onRewindClick();
    private fastForward = () => this.videoPlayerUiState.onForwardClick();
    private toggleMute = () => this.videoPlayerUiState.toggleMute();
    private handleKeys = (e: KeyboardEvent) => {
        const activeKeys = new Set();
        if (e.altKey) {
            activeKeys.add('AltLeft');
        }
        if (e.ctrlKey) {
            activeKeys.add('ControlLeft');
        }
        if (e.shiftKey) {
            activeKeys.add('ShiftLeft');
        }
        activeKeys.add(e.code);
        return activeKeys;
    };
}
