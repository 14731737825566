import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import UserStore from '../stores/UserStore';
import Header from './common/header/Header';
import ModalManager from './common/modal/ModalManager';
import CustomerTranscriptPage from './customer/customer-transcript-page/CustomerTranscriptPage';
import ProjectPage from './customer/project-page/ProjectPage';
import ProjectsPage from './customer/projects-page/ProjectsPage';
import SearchPage from './customer/search-page/SearchPage';
import UploadFilesStatus from './customer/upload-files-status/UploadFilesStatus';
import NotificationsPage from './transcriber/notification-page/NotificationsPage';
import ProjectStore from '../stores/ProjectStore';
import withStores from '../framework/hoc/withStores';
import { withLoadable } from '../framework/hoc/withLoadable';
import FullScreenLoadingSpinner from './common/loading-spinner/FullScreenLoadingSpinner';
import NotificationStore from '../stores/NotificationStore';

type InjectedProps = {
    userStore: UserStore;
    notificationStore: NotificationStore;
};

@observer
class CustomerPage extends Component<InjectedProps> {
    render() {
        return (
            <>
                <Header />
                <UploadFilesStatus />
                <Switch>
                    <Route path="/customer/transcript/:id" component={CustomerTranscriptPage} />
                    <Route path="/customer/projects/:id" component={ProjectPage} />
                    <Route path="/customer/all-notifications" component={NotificationsPage} />
                    <Route path="/customer/projects" component={ProjectsPage} />
                    <Route path="/customer/search" component={SearchPage} />
                    <Route path="/customer" component={SearchPage} />
                </Switch>
            </>
        );
    }
}

export default withStores('notificationStore')(
    withLoadable({
        loader: ({ notificationStore }: InjectedProps) => {
            return notificationStore.init();
        },
        loading: FullScreenLoadingSpinner
    })(CustomerPage)
);
