import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Col, Row } from 'reactstrap';
import UserStore from '../../../stores/UserStore';
import TranscriptUiState from '../../../ui-states/TranscriptUiState';
import VideoPlayerUiState from '../../../ui-states/VideoPlayerUiState';
import HeaderInfo from '../../common/header-info/HeaderInfo';
import TextEditor from '../../common/text-editor/TextEditor';
import Title from '../../common/title/Title';
import VideoPlayer from '../../common/video-player/VideoPlayer';
import ExportBar from '../search-result-page/export-bar/ExportBar';
import { withLoadable } from '../../../framework/hoc/withLoadable';
import FlexPage from '../../common/page/FlexPage';
import TagUiState from '../../../ui-states/TagUiState';
import { UserRole } from '../../../graphql/types';
import CustomerSubmitButtons from './CustomerSubmitButtons';

type InjectedProps = {
    transcriptUiState: TranscriptUiState;
    tagUiState: TagUiState;
    userStore: UserStore;
    videoPlayerUiState: VideoPlayerUiState;
    reload: () => void;
};

type ParamsProps = {
    id: string;
};

type CustomerTranscriptPageProps = RouteComponentProps<ParamsProps> & InjectedProps;

@observer
class CustomerTranscriptPage extends Component<CustomerTranscriptPageProps> {
    async componentDidMount() {
        this.props.videoPlayerUiState.clearVideo();
    }

    componentWillUnmount() {
        this.props.transcriptUiState.reset();
    }

    async componentDidUpdate(prevProps: CustomerTranscriptPageProps) {
        const { match } = this.props;
        const { id } = match.params;
        if (prevProps.match.params.id !== id) {
            this.props.reload();
        }
    }

    render() {
        const { transcriptUiState } = this.props;
        const file = transcriptUiState.file;

        if (!file || !file.isAvailableForWork) {
            return <Redirect to="/customer" />;
        }

        return (
            <FlexPage footer={false}>
                <Title title={file.mediaFileName} />
                <Row>
                    <Col xs="12">
                        <HeaderInfo file={file} submitButtons={<CustomerSubmitButtons />} />
                        <ExportBar
                            onConvertTagsClick={transcriptUiState.openConvertTagsModal}
                            onExportTranscriptionClick={transcriptUiState.openExportTranscriptModal}
                            onExportSelectedParagraphsClick={transcriptUiState.openExportSelectedBlocksModal}
                            onExportTagsClick={transcriptUiState.openExportTagsModal}
                        />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs="8">
                        <TextEditor isCustomer={true} />
                    </Col>
                    <Col xs="4">
                        <VideoPlayer file={file} showVideoTimePairing={false} isCustomer={true} />
                    </Col>
                </Row>
            </FlexPage>
        );
    }
}

export default withRouter(
    inject('transcriptUiState', 'userStore', 'videoPlayerUiState', 'tagUiState')(
        withLoadable({
            loader: async ({
                transcriptUiState,
                tagUiState,
                match: {
                    params: { id }
                }
            }: CustomerTranscriptPageProps) => {
                await transcriptUiState.initialize(id);
                await tagUiState.init(transcriptUiState.transcript);
            }
        })(CustomerTranscriptPage)
    )
);
