import { toast } from 'react-toastify';
import i18n from '../localization/i18n';
import TranscriptModel from '../models/TranscriptModel';

export default class TranscriptVideoSyncer {
    WAIT_FOR_AUTO_BLOCK_MOVE_SECONDS = 10;
    private autoBlockNotificationShown: boolean = false;
    private transcript: TranscriptModel | null = null;
    private scheduledMoveBlockIndex: number | null = null;

    start(transcript: TranscriptModel) {
        this.transcript = transcript;
    }

    syncTranscriptUptoBlockNumber(
        blockNumber: number,
        isPairingCursorToVideoTime: boolean,
        createBlocksAutomatically: boolean
    ) {
        const transcript = this.transcript;
        if (!transcript) {
            console.error('TranscriptVideoSyncer out of sync with ui state, this.transcript is null');
            return;
        }

        if (createBlocksAutomatically) {
            const newBlockCreated = transcript.tryCompleteBlocksUpTo(blockNumber);
            if (newBlockCreated) {
                this.tryShowNewBlockNotification();
            }

            if (isPairingCursorToVideoTime && newBlockCreated === 1 && transcript.activeBlock.isAtEndOfBlock) {
                this.schedulePotentialBlockMove(transcript.lastBlock!.number);
            }
        } else if (isPairingCursorToVideoTime) {
            transcript.moveCursorToBlock(blockNumber);
        }
    }

    private tryShowNewBlockNotification() {
        if (!this.autoBlockNotificationShown) {
            return;
        }

        toast.info(i18n.t('new block created'), { position: 'bottom-right' });
        this.autoBlockNotificationShown = true;
    }

    private schedulePotentialBlockMove(blockNumber: number) {
        this.scheduledMoveBlockIndex = blockNumber;
        setTimeout(() => {
            this.scheduledMoveBlockIndex = null;
        }, this.WAIT_FOR_AUTO_BLOCK_MOVE_SECONDS * 1000);
    }

    get shouldMoveToNewBlock() {
        if (!this.transcript) {
            console.error('TranscriptVideoSyncer out of sync with ui state, this.transcript is null');
            return false;
        }

        return (
            this.transcript.lastBlock &&
            this.scheduledMoveBlockIndex === this.transcript.activeBlock.number + 1 &&
            this.transcript.activeBlock.isAtEndOfBlock
        );
    }

    dispose() {
        this.transcript = null;
        this.autoBlockNotificationShown = false;
        this.scheduledMoveBlockIndex = null;
    }
}
