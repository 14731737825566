import { gql } from 'apollo-boost';
import { transcriberFragmentFragment } from '../../transcriber/fragments/transcriberFragment';

export const userFragment = gql`
    fragment user on User {
        id
        profileImg
        fullName
        email
        phone
        dateJoined
        role
        shouldReceiveMail
        transcriber {
            ...transcriber
        }
        administrator {
            macros {
                replace
                withText
            }
            editorShortcuts {
                shortcutType
                keys
            }
            videoShortcuts {
                shortcutType
                keys
            }
        }
    }
    ${transcriberFragmentFragment}
`;
