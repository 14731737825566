import { addTagMutation } from '../graphql/customer/mutations/addTag';
import { convertToTagsMutation } from '../graphql/customer/mutations/convertToTags';
import { exportTagsMutation } from '../graphql/customer/mutations/exportTags';
import { modifyTagMutation } from '../graphql/customer/mutations/modifyTag';
import { searchTagsQuery } from '../graphql/customer/query/tags';
import {
    AddTagMutation,
    AddTagMutationArgs,
    ConvertToTagsMutation,
    ConvertToTagsMutationArgs,
    ExportFormat,
    ExportTagsMutation,
    ExportTagsMutationArgs,
    ModifyTagMutation,
    ModifyTagMutationArgs,
    Tag,
    TagsQueryArgs
} from '../graphql/types';
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { MobxApolloStore } from '../framework/mobx';
import { MobxApolloQuery } from 'mobx-apollo';
import { tagsInUseQuery } from '../graphql/customer/query/tagsInUse';

export default class TagStore extends MobxApolloStore {
    @observable tagQuery: MobxApolloQuery<{ tags: Tag[] }> | null = null;
    @observable tagsInUseQuery: MobxApolloQuery<{ tagsInUse: Tag[] }> | null = null;

    @action
    init = async () => {
        this.tagQuery = this.query(searchTagsQuery, {}, 'cache-first');
        await this.awaitQuery(this.tagQuery);
        return this.tagQuery!.data!.tags;
    };
    @action
    fetchTagsInUse = async (fileId?: string) => {
        this.tagsInUseQuery = this.query(tagsInUseQuery, { fileId });
        await this.awaitQuery(this.tagsInUseQuery);
        return this.tagsInUse;
    };

    @action
    reset() {
        this.tagQuery = null;
    }

    @computed
    get allTags() {
        return (this.tagQuery && this.tagQuery.data && this.tagQuery.data.tags) || ([] as Tag[]);
    }

    @computed
    get tagsInUse() {
        const tags =
            (this.tagsInUseQuery && this.tagsInUseQuery.data && this.tagsInUseQuery.data.tagsInUse) || ([] as Tag[]);
        return _.sortBy(tags, x => x.name);
    }

    addTag = async (tag: Tag) => {
        const query = await this.client.mutate<AddTagMutation, AddTagMutationArgs>({
            mutation: addTagMutation,
            refetchQueries: [
                {
                    query: searchTagsQuery
                }
            ],
            variables: {
                tag: {
                    name: tag.name,
                    color: tag.color
                }
            },
            awaitRefetchQueries: true
        });

        return query.data!.addTag.tag;
    };

    modifyTag = async (tag: Tag) => {
        const query = await this.client.mutate<ModifyTagMutation, ModifyTagMutationArgs>({
            mutation: modifyTagMutation,
            variables: {
                tag: {
                    id: tag.id,
                    name: tag.name,
                    color: tag.color
                }
            },
            refetchQueries: [
                {
                    query: searchTagsQuery
                }
            ],
            awaitRefetchQueries: true
        });

        return query.data!.modifyTag.tag!;
    };

    filterTagsBySearchTerm = async (searchTerm: string | null): Promise<Tag[]> => {
        const { data } = await this.client.query<{ tags: Tag[] }, TagsQueryArgs>({
            query: searchTagsQuery,
            variables: { searchTerm }
        });
        return _.sortBy(data.tags, x => x.name);
    };

    exportTags = async (exportFormat: ExportFormat, tagsIds: string[], fileId?: string) => {
        return await this.client.mutate<ExportTagsMutation, ExportTagsMutationArgs>({
            mutation: exportTagsMutation,
            variables: { fileId, exportFormat, tagsIds }
        });
    };
    convertToTags = async ({ tagId, selectedInputs }: ConvertToTagsMutationArgs) => {
        return await this.client.mutate<ConvertToTagsMutation, ConvertToTagsMutationArgs>({
            mutation: convertToTagsMutation,
            variables: { tagId, selectedInputs }
        });
    };
}
