import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tag as TagType } from '../../../../graphql/types';
import SubmitButton from '../../../common/submit-button/SubmitButton';
import Tag from '../add-tags-popup/Tag';
import Circle from '../circle/Circle';
import TagForm from '../TagForm';
import './EditTagsPopup.scss';

type EditTagsPopupProps = {
    tag: TagType;
    removeTag: () => void;
    addTag: (tag: TagType) => Promise<void>;
    modifyTag: (tag: TagType) => Promise<void>;
    searchTags: (term: string) => void;
    recommendedTags: TagType[];
} & WithTranslation;

type EditTagsPopupState = {
    isEditing: boolean;
};

@inject('transcriptUiState')
@observer
class EditTagsPopup extends Component<EditTagsPopupProps, EditTagsPopupState> {
    state = {
        isEditing: false
    };

    render() {
        const { tag, t, modifyTag, searchTags, removeTag, recommendedTags, addTag } = this.props;
        const { isEditing } = this.state;

        return (
            <div className="tags-popup">
                {isEditing ? (
                    <>
                        {!_.isEmpty(recommendedTags) && (
                            <ul data-testid="recommendation-tag-list" className="recommendation-tag-list">
                                {recommendedTags.map(tag => (
                                    <Tag key={tag.id} tag={tag} addTag={addTag} />
                                ))}
                            </ul>
                        )}

                        <TagForm
                            onSubmit={modifyTag}
                            recommendedTags={recommendedTags}
                            searchTags={searchTags}
                            tag={tag}
                            label={t('edit tag')}
                        />
                    </>
                ) : (
                    <div className="wrapper">
                        <div className="tag-name">
                            <h3>{tag.name}</h3>
                            <Circle color={tag.color} />
                        </div>
                        <div className="buttons">
                            <SubmitButton secondary={true} onClick={() => this.setState({ isEditing: true })} autoWidth>
                                {t('edit tag')}
                            </SubmitButton>
                            <SubmitButton onClick={removeTag} autoWidth>
                                {t('delete tag')}
                            </SubmitButton>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(EditTagsPopup);
