const devMode = process.env.NODE_ENV === 'development';
const env = {
    GRAPHQL_API_URL: process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:8000/graphql',
    STAGE_B: process.env.REACT_APP_STAGE_B || false,
    ENV: process.env.REACT_APP_ENV_NAME,
    DEV_MODE: devMode,
    SENTRY_DSN: 'https://96533c2328a94ad4890ec1e588677b3a@sentry.io/1450470',
    SEEK_TIME_SECONDS: 5,
    ALLOWED_PLAYBACK_SPEEDS: [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2],
    USE_FULLSTORY: !devMode && process.env.REACT_APP_USE_FULLSTORY !== 'false',
    GIT_COMMIT: process.env.REACT_APP_GIT_COMMIT,
    ALLOWED_FILE_FORMATS: '.mp4, .mkv, .avi, .mov, .wmv, audio/*'
};

export default env;
