import { inject, IWrappedComponent } from 'mobx-react';
import { StoresType } from '../../bootstrap';
import * as React from 'react';
import { Subtract } from '../functional';

const withStores = <TStoreProps extends keyof StoresType>(...stores: TStoreProps[]) => <
    TProps extends Pick<StoresType, TStoreProps>
>(
    component: React.ComponentType<TProps>
) => {
    return (inject(...stores)(component) as any) as React.ComponentClass<
        Subtract<TProps, Pick<StoresType, TStoreProps>> & Partial<Pick<StoresType, TStoreProps>>
    > &
        IWrappedComponent<TProps>;
};

export default withStores;
