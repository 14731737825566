import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PriceMethod } from '../../../../graphql/types';
import ProfileInput from '../../../common/inputs/ProfileInput';
import './PriceMethods.scss';

type PriceMethodsProps = {
    priceMethods: PriceMethod[];
};

const PriceMethods = ({ priceMethods, t }: WithTranslation & PriceMethodsProps) => {
    return (
        <div className="price-methods">
            {priceMethods.map(method => (
                <ProfileInput
                    key={method.pricingUnit}
                    label={t(method.pricingUnit)}
                    name="perMinute"
                    value={method.unitPrice.amount.toString()}>
                    <span className="nis">₪</span>
                </ProfileInput>
            ))}
        </div>
    );
};

export default withTranslation()(PriceMethods);
