import _ from 'lodash';
import { action, autorun, computed, observable, runInAction, toJS } from 'mobx';
import { SelectedResultInput, Transcript } from '../graphql/types';
import SearchFieldsUiState from './SearchFieldsUiState';
import { ModalService } from '../services/ModalService';
import FileStore from '../stores/FileStore';
import ProjectStore from '../stores/ProjectStore';
import SearchStore from '../stores/SearchStore';
import SpeakerStore from '../stores/SpeakerStore';
import TranscriptModel from '../models/TranscriptModel';
import TagUiState from './TagUiState';
import { TranscriptSaver } from '../stores/TranscriptSaver';
import TranscriptStore from '../stores/TranscriptStore';
import TimeUtils from '../framework/utils/TimeUtils';

const SEARCHES_TO_KEEP = 10;

export type PickedItem = {
    id: string;
    label: string;
};

type ActiveSearchResult = {
    resultIndex: number;
    paragraphIndex: number;
};

export type LastSearchNode = {
    term: string;
};

export enum SearchView {
    FILES,
    PARAGRAPHS
}

export default class SearchUiState {
    @observable activeSearchResult: ActiveSearchResult = { paragraphIndex: 0, resultIndex: 0 };
    @observable selectedResults: SelectedResultInput[] = [];
    @observable activeTranscript: TranscriptModel | null = null;
    @observable isLoadingTranscript: boolean = false;
    @observable selectedFiles: string[] = [];
    private readonly transcriptSaver: TranscriptSaver;

    constructor(
        private projectStore: ProjectStore,
        private fileStore: FileStore,
        private speakerStore: SpeakerStore,
        private tagUiState: TagUiState,
        private searchStore: SearchStore,
        private modalService: ModalService,
        private transcriptStore: TranscriptStore,
        private searchFieldsUiState: SearchFieldsUiState
    ) {
        this.transcriptSaver = new TranscriptSaver(this.transcriptStore.saveTranscript, () =>
            this.modalService.openRefreshTranscriptModal(this.reloadSearch)
        );

        autorun(async () => {
            const activeSearchResultFile = this.activeSearchResultFile;
            if (!activeSearchResultFile) {
                runInAction(() => (this.activeTranscript = null));
                this.transcriptSaver.dispose();
                return;
            }

            const fileId = this.activeSearchResultFile!.file.id;
            const mediaFileDuration = this.activeSearchResultFile!.file.mediaFileDuration!;

            if (!this.activeTranscript || this.activeTranscript.id !== fileId) {
                runInAction(() => (this.isLoadingTranscript = true));
                const newTranscript = await this.transcriptStore.fetchTranscript(fileId);
                await this.reloadTranscript(newTranscript, mediaFileDuration);
                runInAction(() => (this.isLoadingTranscript = false));
            }

            const activeSearchResultParagraph = this.activeSearchResultParagraph;
            if (activeSearchResultParagraph) {
                this.activeTranscript!.setActiveParagraph(
                    this.activeTranscript!.getParagraph(
                        activeSearchResultParagraph.block.number,
                        activeSearchResultParagraph.paragraph.number
                    )
                );
            }
        });
    }

    @action.bound
    toggleSelectedFile(fileId: string) {
        const indexOfItem = this.selectedFiles.indexOf(fileId);
        if (indexOfItem !== -1) {
            this.selectedFiles = this.selectedFiles.filter((x, index) => index !== indexOfItem);
        } else {
            this.selectedFiles.push(fileId);
        }
    }

    @action.bound
    toggleSelectAllFiles() {
        const allSelected = this.searchStore.searchResults.length === this.selectedFiles.length;
        if (allSelected) {
            this.selectedFiles.length = 0;
        } else {
            this.selectedFiles = this.searchStore.searchResults.map(x => x.file.id);
        }
    }

    @action
    private async reloadTranscript(newTranscript: Transcript, mediaFileDuration: string) {
        this.transcriptSaver.dispose();
        const newTranscriptModel = new TranscriptModel(
            // Using non observable here means that startOffset and blockInterval won't get auto updated
            // should be okay for search results
            () => newTranscript,
            this.tagUiState.allTags,
            TimeUtils.turnDurationStringToSeconds(mediaFileDuration!)
        );

        // First initialize the TagUiState with the tags in the new transcript, so they'll be ready before re-rendering
        await this.tagUiState.init(newTranscriptModel);
        runInAction(() => {
            this.activeTranscript = newTranscriptModel;

            this.transcriptSaver.start(() => this.activeTranscript);
        });
    }

    @computed
    get activeSearchResultFile() {
        return !_.isEmpty(this.searchStore.searchResults)
            ? this.searchStore.searchResults[this.activeSearchResult.resultIndex]
            : null;
    }

    @computed
    get activeSearchResultParagraph() {
        return (
            this.activeSearchResultFile && this.activeSearchResultFile.results[this.activeSearchResult.paragraphIndex]
        );
    }

    @computed
    get totalResults() {
        return this.searchStore.totalResults;
    }

    @computed
    get activeSearchView() {
        if (
            this.searchStore.lastSearch &&
            (this.searchStore.lastSearch.searchTerm || !_.isEmpty(this.searchStore.lastSearch.speakersIds))
        ) {
            return SearchView.PARAGRAPHS;
        }
        return SearchView.FILES;
    }

    @action
    init = async () => {
        this.searchFieldsUiState.setTagsLoading(true);
        const [tags, _] = await Promise.all([this.tagUiState.init(null), this.projectStore.init()]);
        runInAction(() => this.searchFieldsUiState.setTags(tags));
        this.searchFieldsUiState.setTagsLoading(false);
    };

    @action
    setActiveSearchResult = (activeSearchResult: ActiveSearchResult) => {
        this.activeSearchResult = activeSearchResult;
    };

    @action
    toggleSelectedResults = (selectedResult: SelectedResultInput) => {
        const resultIndex = this.selectedResults.findIndex(result => result.id === selectedResult.id);
        if (resultIndex !== -1) {
            return this.selectedResults.splice(resultIndex, 1);
        }
        return this.selectedResults.push(selectedResult);
    };

    @action
    search = async () => {
        const searchTerm = this.searchFieldsUiState.searchTerm;
        this.activeSearchResult = { paragraphIndex: 0, resultIndex: 0 };
        this.selectedResults = [];
        this.selectedFiles = [];
        await this.searchStore.search(this.searchFieldsUiState.fields);
        if (searchTerm) {
            this.storeLastSearch({ term: searchTerm });
        }
    };

    loadMoreResults = async () => {
        return this.searchStore.searchMore(this.searchFieldsUiState.fields);
    };

    get lastSearches(): LastSearchNode[] {
        const storedSearches = (localStorage.lastSearches && JSON.parse(localStorage.lastSearches)) || [];
        return storedSearches.slice(0, SEARCHES_TO_KEEP);
    }

    @action storeLastSearch = (searchResult: LastSearchNode) => {
        const storedSearches = this.lastSearches;
        let newSearchList = _.uniqBy(storedSearches, x => x.term)
            // remove any old queries that are contained in the new query, to avoid storing mistaken searches
            .filter(x => !!x.term.trim() && !searchResult.term.includes(x.term));

        newSearchList = [searchResult].concat(newSearchList).slice(0, SEARCHES_TO_KEEP);
        localStorage.lastSearches = JSON.stringify(newSearchList);
    };

    openExportTranscriptionModal = () => {
        const fileIds =
            this.activeSearchView === SearchView.FILES ? this.selectedFiles : [this.activeSearchResultFile!.file.id];
        return this.modalService.exportTranscript(fileIds);
    };
    openExportSelectedParagraphModal = () => this.modalService.exportSelectedResults(this.selectedResults);

    openConvertTagModal = () =>
        this.modalService.convertToTags(
            this.selectedResults.map(x => ({ blockId: x.blockId, fileId: x.fileId, paragraphId: x.paragraphId })),
            this.reloadSearch
        );
    openExportTagsModal = (fileId: string) => this.modalService.exportTags(fileId);

    private reloadSearch = async () => {
        const currentActiveResult = toJS(this.activeSearchResult);
        await this.search();
        runInAction(() => {
            this.activeSearchResult = currentActiveResult;
        });
    };
}
