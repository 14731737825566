import classNames from 'classnames';
import React from 'react';
import TimeUtils from '../../../../framework/utils/TimeUtils';
import { File } from '../../../../graphql/types';
import LimitedText from '../../../common/tooltip/LimitedText';
import { Link } from 'react-router-dom';
import Checkbox from '../../../common/checkbox/Checkbox';

type FilesSearchRowProps = {
    isActive: boolean;
    file: File;
    onClick: () => void;
    index: number;
    isSelected?: boolean;
    onSelect: () => void;
};
const FilesSearchRow = ({ isActive, file, onClick, index, isSelected, onSelect }: FilesSearchRowProps) => {
    return (
        <tr onClick={onClick} className={classNames('file-row', { active: isActive })}>
            <td>{index}</td>
            <td
                className="text-center"
                onClick={e => {
                    e.stopPropagation();
                    onSelect();
                }}>
                <Checkbox checked={isSelected} value="" onChange={onSelect} />
            </td>
            <td className="project-name">
                <Link to={`/customer/projects/${file.project.id}`}>
                    <LimitedText content={file.project.name} maxLength={60} />
                </Link>
            </td>
            <td>
                <Link to={`/customer/transcript/${file.id}`}>
                    <LimitedText content={file.mediaFileName} maxLength={60} />
                </Link>
            </td>
            <td>{TimeUtils.formatDate(file.recordedAt, 'DD/MM/YY')}</td>
        </tr>
    );
};

export default FilesSearchRow;
