import { gql } from 'apollo-boost';

export const blockFragment = gql`
    fragment block on Block {
        number
        offset
        totalOffset
        paragraphs {
            number
            speakerId
            body
        }
    }
`;
