import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { connectMenu, ContextMenu } from 'react-contextmenu';
import AddTagsPopup from '../tags-popup/add-tags-popup/AddTagsPopup';
import EditTagsPopup from '../tags-popup/edit-tags-popup/EditTagsPopup';
import { Tag } from '../../../graphql/types';
import { EntityLocation } from '../../../framework/draft/types';
import withStores from '../../../framework/hoc/withStores';
import TagUiState from '../../../ui-states/TagUiState';

type EditorTagContextMenuProps = {
    tagUiState: TagUiState;
    trigger?: { tag?: Tag; tagEntity?: EntityLocation };
};

const MENU_TYPE = 'context-menu-tag-popup';

@connectMenu(MENU_TYPE)
@observer
class EditorTagContextMenu extends Component<EditorTagContextMenuProps> {
    render() {
        const {
            tagUiState: {
                openPopupTag,
                closePopupTag,
                isPopupTagOpen,
                addTag,
                searchTags,
                recommendedTags,
                modifyTag,
                removeTag
            },
            trigger
        } = this.props;
        return (
            <ContextMenu id={`context-menu-tag-popup`} onShow={openPopupTag} onHide={closePopupTag}>
                {!isPopupTagOpen ? (
                    <div />
                ) : trigger && trigger.tag ? (
                    <EditTagsPopup
                        tag={trigger.tag}
                        addTag={tag => {
                            return addTag(tag, trigger.tagEntity!);
                        }}
                        modifyTag={tag => {
                            return modifyTag(tag, trigger.tagEntity!);
                        }}
                        removeTag={() => {
                            removeTag(trigger.tagEntity!);
                        }}
                        searchTags={term => {
                            searchTags(term);
                        }}
                        recommendedTags={recommendedTags}
                    />
                ) : (
                    trigger &&
                    trigger.tagEntity && (
                        <AddTagsPopup
                            addTag={tag => {
                                return addTag(tag, trigger.tagEntity!);
                            }}
                            searchTags={term => {
                                return searchTags(term);
                            }}
                            recommendedTags={recommendedTags}
                        />
                    )
                )}
            </ContextMenu>
        );
    }
}

export default withStores('tagUiState')(EditorTagContextMenu);
