import { action, computed, observable } from 'mobx';
import FileStore from '../stores/FileStore';

export default class FilePageUiState {
    @observable searchTerm: string = '';
    constructor(private fileStore: FileStore) {}

    init() {
        return this.fileStore.init();
    }

    @action
    public setSearchTerm = (searchTerm: string) => (this.searchTerm = searchTerm);

    @computed
    public get auctionFiles() {
        if (this.searchTerm) {
            return this.fileStore.auctionFiles.filter(file =>
                file.mediaFileName.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
        return this.fileStore.auctionFiles;
    }

    @computed
    public get myFiles() {
        if (this.searchTerm) {
            return this.fileStore.myFiles.filter(file =>
                file.mediaFileName.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
        return this.fileStore.myFiles;
    }
}
