import { gql } from 'apollo-boost';

export const totalProfitFromFilesQuery = gql`
    query totalProfitFromFiles {
        totalProfitFromFiles {
            total {
                amount
                currency
            }
        }
    }
`;
