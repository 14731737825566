import { gql } from 'apollo-boost';
import { baseFileFragment } from '../../common/fragments/baseFileFragment';

export const filePageQuery = gql`
    query filePage {
        auctionFiles {
            ...baseFile
            project {
                id
                name
            }
        }

        myFiles {
            ...baseFile
            project {
                id
                name
            }
            price {
                priceUnitType
                quantity
                pricePerUnit {
                    amount
                    currency
                }
                total {
                    amount
                    currency
                }
            }
            wordCount
            pageCount
        }
    }
    ${baseFileFragment}
`;
