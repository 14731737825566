import { isEqual } from 'lodash';
import * as Yup from 'yup';
import i18n from '../../localization/i18n';

Yup.setLocale({
    mixed: {
        required: i18n.t('required field')
    },
    string: {
        email: i18n.t('not valid email address')
    }
});

Yup.addMethod(Yup.object, 'uniqueProperty', function(propertyName, message) {
    return this.test('unique', message, function(value) {
        if (!value || !value[propertyName]) {
            return true;
        }
        if (
            this.parent.filter((v: any) => v !== value).some((v: any) => isEqual(v[propertyName], value[propertyName]))
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
                message
            });
        }

        return true;
    });
});

Yup.addMethod(Yup.object, 'shortcutFormat', function(propertyName, message) {
    return this.test('only2', message, function() {
        // @ts-ignore
        const field = this.options.originalValue;
        if (field.shortcut.length === 1) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
                message
            });
        }
        return true;
    });
});

export const loginCredentialsSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required(),
    password: Yup.string().required()
});

export const loginOtpSchema = Yup.object().shape({
    otp: Yup.number()
        .typeError(i18n.t('enter only numbers'))
        .required()
});

export const configureSpeakerSchema = Yup.object().shape({
    speakers: Yup.array()
        .of(
            Yup.object()
                .shortcutFormat('shortcut', i18n.t('must have two keys'))
                .uniqueProperty('shortcut', i18n.t('duplicate field'))
                .shape({
                    name: Yup.string().required(),
                    shortcut: Yup.string()
                })
                .uniqueProperty('name', i18n.t('duplicate field'))
        )
        .required()
});
