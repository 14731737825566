import { gql } from 'apollo-boost';

export const modifyTranscriptOffsetMutation = gql`
    mutation modifyTranscriptOffset($startOffsetInSeconds: Int!, $transcriptId: ID!) {
        modifyTranscriptOffset(startOffsetInSeconds: $startOffsetInSeconds, transcriptId: $transcriptId) {
            success
            transcript {
                startOffset
            }
        }
    }
`;
