import { gql } from 'apollo-boost';
import { blockFragment } from '../../common/fragments/blockFragment';
import { slimFileFragment } from '../../common/fragments/fileFragment';

export const searchQuery = gql`
    query searchFiles($input: SearchFilesInput!) {
        searchFiles(input: $input) {
            cursor
            totalResults
            results {
                file {
                    ...file
                }
                results {
                    id
                    text
                    blockInterval
                    startOffset
                    speaker {
                        id
                        name
                        shortcut
                    }
                    block {
                        ...block
                    }
                    paragraph {
                        number
                        speakerId
                        body
                    }
                }
            }
        }
    }
    ${slimFileFragment}
    ${blockFragment}
`;
