import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import withStores from '../../framework/hoc/withStores';
import UserStore from '../../stores/UserStore';
import { observer } from 'mobx-react';

type RouteProps = {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path: string;
    exact?: boolean;
};

const PrivateRouteComponent: React.FC<RouteProps & { userStore: UserStore }> = ({ userStore, ...rest }) => {
    return <ProtectedRouteComponent predicate={() => userStore.isLoggedIn} {...rest} redirect="/login" />;
};

const PublicRouteComponent: React.FC<RouteProps & { userStore: UserStore }> = ({ userStore, ...rest }) => {
    return <ProtectedRouteComponent predicate={() => !userStore.isLoggedIn} {...rest} />;
};

const ProtectedRouteComponent: React.FC<RouteProps & { predicate: () => boolean; redirect?: string }> = ({
    component: Component,
    predicate,
    exact,
    redirect,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => (predicate() ? <Component {...props} /> : <Redirect to={redirect || '/'} />)}
        />
    );
};

export const PrivateRoute = withStores('userStore')(observer(PrivateRouteComponent));
export const ProtectedRoute = ProtectedRouteComponent;
export const PublicRoute = withStores('userStore')(observer(PublicRouteComponent));
