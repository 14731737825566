import React from 'react';
import SearchUiState from '../../../ui-states/SearchUiState';
import SearchResults from '../search-result-page/SearchResults';
import SearchFields from './search-fields/SearchFields';
import './SearchPage.scss';
import InlineSpinner from '../../common/loading-spinner/InlineSpinner';
import withStores from '../../../framework/hoc/withStores';
import SearchStore from '../../../stores/SearchStore';
import { withLoadable } from '../../../framework/hoc/withLoadable';
import ProjectStore from '../../../stores/ProjectStore';
import FlexPage from '../../common/page/FlexPage';
import { Observer } from 'mobx-react';

type SearchPageProps = {
    projectStore: ProjectStore;
    searchStore: SearchStore;
    searchUiState: SearchUiState;
};

const SearchPage: React.FC<SearchPageProps> = ({ searchStore, projectStore }) => {
    return (
        <Observer>
            {() => (
                <FlexPage className="search-page d-flex flex-column" footer={false}>
                    <SearchFields projects={projectStore.projects} />
                    {searchStore.isSearching ? (
                        <InlineSpinner className="flex-fill" />
                    ) : searchStore.hasSearched ? (
                        <SearchResults />
                    ) : (
                        <div className="position-relative text-center">
                            <div className="bg" />
                            <img
                                className="illustration"
                                alt=""
                                src={require('../../../assets/images/search-page-no-results-illustration.png')}
                            />
                        </div>
                    )}
                </FlexPage>
            )}
        </Observer>
    );
};

export default withStores('searchStore', 'searchUiState', 'projectStore')(
    withLoadable({
        loader: ({ searchUiState }: SearchPageProps) => {
            return searchUiState.init();
        },
        loading: null
    })(SearchPage)
);
