import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { formatMoney } from '../../../framework/utils/moneyUtils';
import TimeUtils from '../../../framework/utils/TimeUtils';
import { File } from '../../../graphql/types';
import FileStore from '../../../stores/FileStore';
import UserStore from '../../../stores/UserStore';
import HistoryUiState from '../../../ui-states/HistoryUiState';
import CommonTable, { Column } from '../../common/common-table/CommonTable';
import './HistoryPage.scss';
import SearchHistory from './search-history/SearchHistory';
import withStores from '../../../framework/hoc/withStores';
import { withLoadable } from '../../../framework/hoc/withLoadable';
import FlexPage from '../../common/page/FlexPage';

type InjectedProps = {
    fileStore: FileStore;
    userStore: UserStore;
    historyUiState: HistoryUiState;
};

type HistoryPageProps = InjectedProps & WithTranslation;

@observer
class HistoryPage extends Component<HistoryPageProps> {
    render() {
        const { fileStore, historyUiState } = this.props;
        const { t } = this.props;
        const { total, pickedMonth } = historyUiState;

        const columns = [
            {
                name: t('file name'),
                className: 'file-name-column text-right',
                render: (file: File) => file.mediaFileName
            },
            {
                name: t('closing project date'),
                render: (file: File) => TimeUtils.formatDate(file.recordedAt!, 'DD/MM/YYYY')
            },
            {
                name: t('total length'),
                render: (file: File) => file.mediaFileDuration
            },
            {
                name: t('price unit'),
                render: (file: File) => t(file.price.priceUnitType)
            },
            {
                name: t('quantity'),
                render: (file: File) => file.price.quantity
            },
            {
                name: t('price per unit'),
                render: (file: File) => formatMoney(file.price.pricePerUnit)
            },
            {
                name: t('total'),
                render: (file: File) => formatMoney(file.price.total)
            }
        ] as Column[];
        return (
            <FlexPage className="history-page">
                <SearchHistory
                    priceUnitTypes={fileStore.historyFiles!.map(file => file.price.priceUnitType)}
                    onSubmit={historyUiState.searchHistoryFiles}
                />
                <div className="table-title">
                    <h2>
                        <Trans i18nKey="filesCompletedTitle">{{ month: pickedMonth!.label }}</Trans>
                    </h2>
                    <span className="total">
                        <Trans i18nKey="totalProfit">{total && { total: formatMoney(total) }}</Trans>
                    </span>
                </div>
                <CommonTable columns={columns} rows={fileStore.historyFiles!} />
            </FlexPage>
        );
    }
}

export default withTranslation()(
    withStores('fileStore', 'userStore', 'historyUiState')(
        withLoadable({
            loader: ({ historyUiState, fileStore }: HistoryPageProps) => {
                return Promise.all([historyUiState.init(), fileStore.fetchHistoryFiles()]);
            }
        })(HistoryPage)
    )
);
