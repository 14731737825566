import _ from 'lodash';
import { inject, Observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import TimeUtils from '../../../framework/utils/TimeUtils';
import { FileStatus, Project } from '../../../graphql/types';
import ProjectStore from '../../../stores/ProjectStore';
import CommonTable from '../../common/common-table/CommonTable';
import Title from '../../common/title/Title';
import FlexPage from '../../common/page/FlexPage';
import { withLoadable } from '../../../framework/hoc/withLoadable';
import { ModalService } from '../../../services/ModalService';
import SubmitButton from '../../common/submit-button/SubmitButton';
import { Link } from 'react-router-dom';

type InjectedProps = {
    projectStore: ProjectStore;
    modalService: ModalService;
};
type ParamsProps = {
    id: string;
};

type ProjectsPageProps = RouteComponentProps<ParamsProps> & WithTranslation & InjectedProps;

const ProjectsPage = ({ projectStore, t, modalService }: ProjectsPageProps) => {
    const columns = [
        {
            name: t('created project date'),
            render: (project: Project) => TimeUtils.formatDate(project.created, 'DD/MM/YYYY'),
            className: 'text-right pr-4'
        },
        {
            name: t('project name'),
            render: (project: Project) => <Link to={`/customer/projects/${project.id}`}>{project.name}</Link>,
            className: 'text-right'
        },
        {
            name: t('category'),
            render: (project: Project) => project.category,
            className: 'text-right'
        },
        {
            name: t('number of uploaded files'),
            render: (project: Project) => <Link to={`/customer/projects/${project.id}`}>{project.numOfFiles}</Link>
        },
        {
            name: t('number of files being transcribed'),
            render: (project: Project) => (
                <Link to={`/customer/projects/${project.id}?status=${FileStatus.UnderWork}`}>
                    {project.numOfInProgressFiles}
                </Link>
            )
        },
        {
            name: t('number of completed files'),
            render: (project: Project) => (
                <Link to={`/customer/projects/${project.id}?status=${FileStatus.Delivered}`}>
                    {project.numOfCompletedFiles}
                </Link>
            )
        },
        {
            name: t('status'),
            render: (project: Project) => t(project.status)
        }
    ];
    return (
        <Observer>
            {() => {
                const { projects } = projectStore;
                return (
                    <FlexPage className="projects-page">
                        <Title title={t('projects')} />
                        <div className="d-flex justify-content-end">
                            <SubmitButton onClick={modalService.exportTags}>{t('export tags')}</SubmitButton>
                        </div>

                        {_.isEmpty(projects) ? (
                            <h3 className="text-center mt-5">{t('no projects was found')}</h3>
                        ) : (
                            <CommonTable rows={projects} columns={columns} paginate />
                        )}
                    </FlexPage>
                );
            }}
        </Observer>
    );
};

export default withTranslation()(
    withRouter(
        inject('projectStore', 'modalService')(
            withLoadable({ loader: ({ projectStore }: ProjectsPageProps) => projectStore.init() })(ProjectsPage)
        )
    )
);
