import { observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppUiState from '../ui-states/AppUiState';
import FullScreenLoadingSpinner from './common/loading-spinner/FullScreenLoadingSpinner';
import Login from './login/Login';
import ReactTooltip from 'react-tooltip';
import '../modules/common/tooltip/Tooltip.scss';
import withStores from '../framework/hoc/withStores';
import AppRoot from './AppRoot';
import { PrivateRoute } from './common/Routes';
import { withLoadable } from '../framework/hoc/withLoadable';
import { AuthService } from '../services/AuthService';
import { QueryParamProvider } from 'use-query-params';

type MainProps = {
    authService: AuthService;
    appUiState: AppUiState;
    routerStore: RouterStore;
};

@observer
class Main extends Component<MainProps> {
    componentDidMount() {
        this.props.authService.tryWarningAboutDisconnect();
    }

    render(): React.ReactNode {
        const { routerStore, appUiState } = this.props;

        if (appUiState.isAppLoading) {
            return <FullScreenLoadingSpinner />;
        }

        return (
            <>
                <Router history={routerStore.history}>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <Switch>
                            <Route path="/login" component={Login} />
                            <PrivateRoute path="/" component={AppRoot} />
                            <Redirect to="/" />
                        </Switch>
                    </QueryParamProvider>
                </Router>
                <ToastContainer pauseOnFocusLoss={false} toastClassName="orange-toast" />
                <ReactTooltip className="tooltip-fix" />
            </>
        );
    }
}

export default withStores('appUiState', 'routerStore', 'authService')(
    withLoadable({
        loader: ({ appUiState }: MainProps) => appUiState.init(),
        loading: FullScreenLoadingSpinner,
        delay: 500
    })(Main)
);
