import { useEffect } from 'react';

export default (callback: () => void, delay: number = 3000) => {
    let timeout = -1;
    useEffect(() => {
        timeout = setInterval(callback, delay) as any;
        return () => {
            clearInterval(timeout);
        };
    }, []);
};
