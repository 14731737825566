import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import InjectedComponent from '../../../framework/utils/InjectedComponent';
import NotificationStore from '../../../stores/NotificationStore';
import Footer from '../../common/footer/Footer';
import Title from '../../common/title/Title';
import NotificationItem from './notification-item/NotificationItem';
import './NotificationsPage.scss';
import FlexPage from '../../common/page/FlexPage';
import InlineSpinner from '../../common/loading-spinner/InlineSpinner';

type InjectedProps = {
    notificationStore: NotificationStore;
};

@inject('notificationStore')
@observer
class NotificationsPage extends InjectedComponent<InjectedProps, WithTranslation> {
    render() {
        const { notificationStore } = this.injectedProps;
        const { t } = this.props;
        const { notifications, loading, markNotificationRead } = notificationStore;
        return (
            <FlexPage className="notification-page" data-testid="notifications-page">
                <Title title={t('notification center')} />
                {loading ? (
                    <InlineSpinner />
                ) : !isEmpty(notifications) ? (
                    <ul>
                        {notifications.map(notification => (
                            <li key={notification.id}>
                                <NotificationItem
                                    notification={notification}
                                    markNotificationRead={() => markNotificationRead([notification.id])}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <h3 className="text-center mt-5">{t('no notifications')}</h3>
                )}
            </FlexPage>
        );
    }
}

export default withTranslation()(NotificationsPage);
