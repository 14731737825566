import { gql } from 'apollo-boost';

export const tagsInUseQuery = gql`
    query tagsInUse($fileId: ID) {
        tagsInUse(fileId: $fileId) {
            id
            name
            color
        }
    }
`;
