import moment, { Moment } from 'moment';
import 'moment/locale/he';

moment.locale('he');

class TimeUtils {
    formatDate = (date: Date | Moment, format: string) => {
        const momentDate = moment(date);
        if (!date || !momentDate.isValid()) {
            return '-';
        }
        return momentDate.format(format);
    };

    subtractHoursFromTime = (date: Date, hours: number) => moment(date).subtract(hours, 'hours');

    getMonthsFromDates = (date: Date) => {
        const dateEnd = moment(new Date());
        const dateStart = moment(date);
        const months = [];
        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            months.push(dateStart.format('MMMM YYYY'));
            dateStart.add(1, 'month');
        }
        return months;
    };

    wrapDate = (stringDate: string): Date => new Date(stringDate);

    turnDurationStringToSeconds = (date: string) => {
        const parsed = moment.duration(date);
        return parsed.asSeconds();
    };
    turnDurationStringToMicroSeconds = (date: string) => {
        const parsed = moment.duration(date);
        return parsed.asMilliseconds();
    };

    secondsToDurationString = (seconds: number, includeMilliseconds = false) => {
        let str = this.millisecondsToDurationString(seconds * 1000);
        if (!includeMilliseconds) {
            str = str.split('.')[0];
        }

        return str;
    };

    millisecondsToDurationString = (ms: number) => {
        const duration = moment.duration(ms);
        const pad = (x: number, padTo: number = 2) => x.toString().padStart(padTo, '0');
        return `${pad(duration.hours())}:${pad(duration.minutes())}:${pad(duration.seconds())}.${pad(
            duration.milliseconds(),
            3
        )}`;
    };

    get now() {
        return moment(new Date());
    }
    get zeroTime() {
        return moment(new Date(0, 0, 0, 0, 0, 0));
    }
}

export default new TimeUtils();
