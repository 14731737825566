import { Observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import TimeUtils from '../../../framework/utils/TimeUtils';
import VideoPlayerUiState from '../../../ui-states/VideoPlayerUiState';
import VideoControls from '../video-player/video-controls/VideoControls';
import './Player.scss';
import classNames from 'classnames';
import InlineSpinner from '../loading-spinner/InlineSpinner';
import withStores from '../../../framework/hoc/withStores';

type InjectedProps = {
    videoPlayerUiState: VideoPlayerUiState;
};
type PlayerProps = {
    mediaFileUrl?: string | null;
    mediaStreamFileSignature?: string | null;
    isAudioFile?: boolean;
} & InjectedProps;

const Player: React.FC<PlayerProps> = ({ videoPlayerUiState, mediaFileUrl, mediaStreamFileSignature, isAudioFile }) => {
    const [isPipEnabled, setIsPipEnabled] = useState(false);
    const playerRef = useRef<ReactPlayer>(null);
    useEffect(
        () => {
            videoPlayerUiState.clearVideo();
        },
        [mediaFileUrl]
    );

    return (
        <Observer>
            {() => {
                const {
                    isLoaded,
                    setPlaying,
                    setStopped,
                    isPlaying,
                    isMute,
                    setVideoTime,
                    setVideoPlayer,
                    videoTimeInSeconds,
                    totalTime,
                    onSeekChange,
                    playbackRate,
                    changePlaybackRate,
                    onRewindClick,
                    onForwardClick,
                    resetVideoTime,
                    toggleMute,
                    handleError
                } = videoPlayerUiState;
                const extraProps = {
                    onError: (error: Error, errorDetails: { [x: string]: string }) => {
                        handleError(error, errorDetails);
                    }
                };

                return (
                    <div className="player">
                        {/*  // onError typing is incorrect for hls player!
                        // @ts-ignore */}
                        <ReactPlayer
                            className={classNames({ 'd-none': !isLoaded || isPipEnabled })}
                            url={mediaFileUrl || undefined}
                            ref={playerRef}
                            controls
                            pip
                            width="100%"
                            height="100%"
                            onPlay={setPlaying}
                            playing={isPlaying}
                            muted={isMute}
                            playbackRate={playbackRate}
                            onProgress={state => setVideoTime(state.playedSeconds)}
                            onReady={() => setVideoPlayer(playerRef.current!)}
                            onEnablePIP={() => setIsPipEnabled(true)}
                            onDisablePIP={() => setIsPipEnabled(false)}
                            {...extraProps}
                            config={{
                                file: {
                                    hlsOptions: {
                                        xhrSetup(xhr: XMLHttpRequest, url: string) {
                                            if (url.includes('.m3u8')) {
                                                return;
                                            }
                                            xhr.open('GET', `${url}?${mediaStreamFileSignature || ''}`);
                                        },
                                        forceHLS: true
                                    }
                                }
                            }}
                        />
                        {!isLoaded && <InlineSpinner className="mt-4" size="small" />}
                        <div className="body-wrapper">
                            {isAudioFile && (
                                <input
                                    type="range"
                                    min={0}
                                    max={totalTime}
                                    value={videoTimeInSeconds}
                                    onChange={e => onSeekChange(parseFloat(e.target.value))}
                                />
                            )}
                            <VideoControls
                                disabled={!isLoaded}
                                playbackRate={playbackRate}
                                onPlaybackRateChanged={changePlaybackRate}
                                isPlaying={isPlaying}
                                isMuted={isMute}
                                timeElapsed={TimeUtils.secondsToDurationString(videoTimeInSeconds, false)}
                                onBackwardClick={onRewindClick}
                                onForwardClick={onForwardClick}
                                onPauseClick={setStopped}
                                onPlayClick={setPlaying}
                                onStopClick={resetVideoTime}
                                onMuteClick={toggleMute}
                            />
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default withStores('videoPlayerUiState')(Player);
