import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import './DatePicker.scss';
import { Hebrew } from './he';

flatpickr.localize(Hebrew);
type RecordDatePickerState = {
    date: Date[];
};
type RecordDatePickerProps = {
    onChange: (dates: Date[]) => void;
    label: string;
};

class DatePicker extends Component<RecordDatePickerProps, RecordDatePickerState> {
    state = {
        date: []
    };
    render() {
        const { date } = this.state;
        const { onChange, label } = this.props;
        return (
            <Flatpickr
                value={date}
                options={{ mode: 'range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' }}
                // @ts-ignore
                placeholder={label}
                onChange={date => {
                    this.setState({ date });
                    onChange(date);
                }}
            />
        );
    }
}

export default DatePicker;
