import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client/ApolloClient';
import { RouterStore } from 'mobx-react-router';
import { loginMutation } from '../graphql/common/mutations/login';
import { resendOtpMutation } from '../graphql/common/mutations/resendToken';
import { LoginMutation, LoginMutationArgs } from '../graphql/types';
import UserStore from '../stores/UserStore';
import AppUiState from '../ui-states/AppUiState';

export class AuthService {
    constructor(
        private appUiState: AppUiState,
        private routerStore: RouterStore,
        private userStore: UserStore,
        private client: ApolloClient<NormalizedCacheObject>
    ) {}

    public login = async ({ email, password }: LoginMutationArgs) => {
        const { data } = await this.client.mutate<LoginMutation, LoginMutationArgs>({
            mutation: loginMutation,
            variables: { email, password }
        });
        this.appUiState.setApiToken(data!.login.token);
        // OTP is disabled in the server, so automatically send dummy OTP
        await this.sendOtp('1234');
    };

    public sendOtp = async (otp: string) => {
        const token = await this.userStore.verifyOtp(otp);
        if (!(await this.appUiState.tryInitSession(token))) {
            return;
        }

        if (this.userStore.isLoggedIn) {
            if (this.userStore.isTranscriber) {
                await this.routerStore.history.push('/transcriber');
            }
            if (this.userStore.isCustomer) {
                await this.routerStore.history.push('/customer');
            }
        }
    };

    public resendOtp = async () => {
        // todo: error handling
        return await this.client.mutate({ mutation: resendOtpMutation });
    };

    public logout = (authError: boolean = false) => {
        this.appUiState.setAppLoadingState(true);
        this.appUiState.clearApiToken();
        window.location.href = `/login?${authError ? 'autherror' : ''}`; // refresh ftw
    };

    public tryWarningAboutDisconnect = () => {
        if (window.location.search.includes('autherror')) {
            this.routerStore.replace('/login');
            this.appUiState.showTranslatedToast('authorization error');
        }
    };
}
