import { action, computed, observable } from 'mobx';
import uuid from 'uuid';
import ParagraphModel from './ParagraphModel';
import TranscriptModel from './TranscriptModel';
import { Block, Tag } from '../graphql/types';
import BlockService from '../services/BlockService';
import TimeUtils from '../framework/utils/TimeUtils';

export default class BlockModel {
    @computed
    get number() {
        return this.transcript!.blocks.indexOf(this);
    }

    @computed
    get asJson() {
        return { paragraphs: this.paragraphs.map(x => x.asJson), blockOffset: this.offset };
    }

    @computed
    get text() {
        return this.paragraphs.map(x => x.editorState.getCurrentContent().getPlainText()).join('\n');
    }

    @computed
    public get previousBlock(): BlockModel | null {
        if (this.number === 0) {
            return null;
        }

        return this.transcript!.blocks[this.number - 1];
    }

    @computed
    public get nextBlock(): BlockModel | null {
        const allBlocks = this.transcript!.blocks;

        if (allBlocks.length - 1 === this.number) {
            return null;
        }

        return allBlocks[this.number + 1];
    }

    @computed
    get firstParagraph(): ParagraphModel {
        return this.paragraphs[0];
    }

    @computed
    get lastParagraph(): ParagraphModel {
        return this.paragraphs[this.paragraphs.length - 1];
    }

    @computed
    get videoPositionInSeconds() {
        return BlockService.calculateBlockVideoPositionInMS(0, this.transcript!.blockInterval, this.number) / 1000;
    }

    @computed
    get timestamp() {
        return TimeUtils.millisecondsToDurationString(this.timecode);
    }

    @computed
    get timecode() {
        return BlockService.calculateBlockTimeCode(
            this.transcript!.blocks,
            this.number,
            this.transcript!.blockInterval
        );
    }

    @computed
    get originalTimecode() {
        return this.timecode - this.offset;
    }

    static fromHtml(transcript: TranscriptModel, block: Block, tags: Tag[]) {
        const newBlock = new BlockModel(transcript, block.offset || 0);
        newBlock.paragraphs = block.paragraphs.map(paragraph => ParagraphModel.fromHtml(newBlock, paragraph, tags));
        return newBlock;
    }
    static empty(transcript: TranscriptModel) {
        const newBlock = new BlockModel(transcript);
        newBlock.insertNewParagraph(0);
        return newBlock;
    }
    @observable paragraphs: ParagraphModel[] = [];
    @observable offset: number;
    public uuid = uuid.v4();
    @observable transcript: TranscriptModel | null = null;

    private constructor(transcript: TranscriptModel, blockOffset = 0) {
        this.transcript = transcript;
        this.offset = blockOffset;
    }

    insertNewParagraph = (nextParagraphNumber: number): ParagraphModel => {
        const newParagraph = ParagraphModel.empty(this);

        if (nextParagraphNumber >= this.paragraphs.length) {
            this.paragraphs.push(newParagraph);
        } else {
            this.paragraphs.splice(nextParagraphNumber, 0, newParagraph);
        }
        return newParagraph;
    };

    @action
    modifyTimecode(newTimecodeInMillkSeconds: number) {
        this.offset = Math.max(newTimecodeInMillkSeconds - this.originalTimecode, 0);
    }

    @computed
    get isAtEndOfBlock() {
        return (
            this === this.transcript!.activeBlock &&
            this.lastParagraph === this.transcript!.activeParagraph &&
            this.lastParagraph.isEndOfParagraph
        );
    }
}
