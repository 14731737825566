import { isEmpty } from 'lodash';
import { inject, Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { ModalService } from '../../../services/ModalService';
import ProjectStore from '../../../stores/ProjectStore';
import Title from '../../common/title/Title';
import ProjectHeaderInfo from './project-header-info/ProjectHeaderInfo';
import './ProjectPage.scss';
import { LoadableInjectedProps, withLoadable } from '../../../framework/hoc/withLoadable';
import FlexPage from '../../common/page/FlexPage';
import MyFilesTable from '../../common/file-tables/MyFilesTable';
import { File, FileStatus } from '../../../graphql/types';
import { useQueryParam } from 'use-query-params';
import { StringParam } from 'use-query-params/lib/params';
import { buildURI } from '../../../framework/utils/csv';
import i18next from 'i18next';
import TimeUtils from '../../../framework/utils/TimeUtils';

type InjectedProps = {
    projectStore: ProjectStore;
    modalService: ModalService;
};
type ParamsProps = {
    id: string;
};

type ProjectPageProps = RouteComponentProps<ParamsProps> & WithTranslation & InjectedProps & LoadableInjectedProps;

const downloadAsCsv = (files: File[], t: i18next.TFunction) => {
    window.open(
        buildURI(
            [
                t('file name'),
                t('speakers'),
                t('status'),
                t('total length'),
                t('due date'),
                t('word count'),
                t('page count')
            ],
            files.map(file => [
                file.mediaFileName,
                file.speakers.map(speaker => speaker.name).join(', '),
                t(file.status).toString(),
                file.mediaFileDuration || '',
                TimeUtils.formatDate(file.dueDate, 'DD/MM/YYYY - HH:mm'),
                file.wordCount,
                file.pageCount
            ])
        )
    );
};

const ProjectPage: React.FC<ProjectPageProps> = ({ projectStore, modalService, t, match, reload }) => {
    useEffect(
        () => {
            let latestFetch: number | null = null;
            async function reloadTable() {
                try {
                    latestFetch = null;
                    await reload!(true);
                    latestFetch = (setTimeout(reloadTable, 3000) as any) as number;
                } catch (err) {}
            }

            reloadTable();

            return () => {
                if (latestFetch) {
                    clearTimeout(latestFetch);
                    latestFetch = null;
                }
            };
        },
        [match.params.id]
    );
    const [statusQuery, setStatusQuery] = useQueryParam('status', StringParam);

    return (
        <Observer>
            {() => {
                const project = projectStore.project!;
                let files = project.files;
                if (statusQuery) {
                    files = files.filter(x => x.status === statusQuery);
                }

                return (
                    <FlexPage className="project-page">
                        <Title title={t('project')} />
                        <ProjectHeaderInfo
                            project={project}
                            onExportToExcelClick={() => downloadAsCsv(files, t)}
                            onAddNewFilesClick={() => modalService.addFilesToProject(project)}
                        />
                        {!isEmpty(files) ? (
                            <MyFilesTable
                                myFiles={files}
                                isCustomer
                                rowClassSelector={file => {
                                    if (file.status === FileStatus.Delivered) {
                                        return 'under-work';
                                    }
                                    return '';
                                }}
                            />
                        ) : (
                            <>
                                <h3 className="text-center mt-5">{t('no files in project')}</h3>
                                <h3 className="text-center mt-2">{t('click the upload button to upload files')}</h3>
                            </>
                        )}
                    </FlexPage>
                );
            }}
        </Observer>
    );
};

export default withTranslation()(
    withRouter(
        inject('projectStore', 'modalService')(
            withLoadable({
                loader: ({
                    projectStore,
                    match: {
                        params: { id }
                    }
                }: ProjectPageProps) => {
                    return projectStore.fetchProjectById({ id });
                }
            })(ProjectPage)
        )
    )
);
