import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './ConfirmModal.scss';
import ModalActions from '../ModalActions';

type AcceptTranscriptionModalProps = {
    isOpen: boolean;
    titleText: string;
    bodyText: string;
    sendConfirmText: string;
    canCancel: boolean;
    sendCancelText?: string;
    onHide(): void;
    onSubmit(): boolean | void;
};

class ConfirmModal extends Component<AcceptTranscriptionModalProps> {
    static defaultProps = {
        canCancel: true,
        sendConfirmText: 'אישור',
        sendCancelText: 'ביטול'
    };

    render() {
        const {
            isOpen,
            onHide,
            onSubmit,
            titleText,
            sendConfirmText,
            canCancel,
            sendCancelText,
            bodyText
        } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={() => canCancel && onHide()} size="lg" centered={true}>
                <div className="submit-for-approval-modal">
                    <ModalHeader>{titleText}</ModalHeader>
                    <ModalBody>{bodyText}</ModalBody>
                    <ModalFooter>
                        <ModalActions
                            submitText={sendConfirmText}
                            onSubmitClick={async () => {
                                const result = await Promise.resolve(onSubmit());
                                if (result === false) {
                                    return;
                                }
                                onHide();
                            }}
                            onCancel={onHide}
                            cancelText={sendCancelText!}
                            isCancelDisabled={!canCancel}
                        />
                    </ModalFooter>
                </div>
            </Modal>
        );
    }
}

export default ConfirmModal;
