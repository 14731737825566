import { observer } from 'mobx-react';
import React, { Component } from 'react';
import BlockModel from '../../../models/BlockModel';
import TranscriptUiState from '../../../ui-states/TranscriptUiState';
import Checkbox from '../../common/checkbox/Checkbox';
import Entry from '../../common/text-editor/block/entry/Entry';
import './CustomerBlock.scss';
import withStores from '../../../framework/hoc/withStores';

type InjectedProps = {
    transcriptUiState: TranscriptUiState;
};

type CustomerBlockProps = {
    checked: boolean;
    onCheckBoxClick: (selectedBlockId: string) => void;
    block: BlockModel;
} & InjectedProps;

@observer
class CustomerBlock extends Component<CustomerBlockProps> {
    render() {
        const {
            checked,
            onCheckBoxClick,
            block,
            transcriptUiState: {
                file: { speakers },
                transcript,
                setActiveParagraph
            }
        } = this.props;
        return (
            <tr className="customer-block" data-testid={`block-${block.number}`}>
                <td className="checkbox">
                    <Checkbox
                        checked={checked}
                        value={block.number.toString()}
                        onChange={() => onCheckBoxClick(block.number.toString())}
                    />
                </td>
                <td className="ts" onClick={() => setActiveParagraph(block.firstParagraph)}>
                    {block.timestamp}
                </td>
                <Entry
                    data-testid="text-block"
                    block={block}
                    activeParagraph={transcript!.activeParagraph}
                    speakers={speakers}
                />
            </tr>
        );
    }
}

export default withStores('transcriptUiState')(CustomerBlock);
