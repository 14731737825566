import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import facebook from '../../../assets/images/facebook.png';
import linkedin from '../../../assets/images/linkedin.png';
import site from '../../../assets/images/site.png';
import './Footer.scss';

const Footer = ({ t }: WithTranslation) => {
    return (
        <div className="footer">
            <div className="social border">
                <div>
                    <a href="https://www.facebook.com/hever.translations/" rel="nofollow noopener" target="_blank">
                        <img src={facebook} alt={t('contact us on facebook')} />
                    </a>
                </div>
                <div>
                    <a
                        href="https://www.hevertranslations.co.il/subtitle-service/"
                        rel="nofollow noopener"
                        target="_blank">
                        <img src={site} alt={t('contact us on our site')} />
                    </a>
                </div>
                <div>
                    <a
                        href="https://www.linkedin.com/company/hever-localization/"
                        rel="nofollow noopener"
                        target="_blank">
                        <img src={linkedin} alt={t('contact us on linkedin')} />
                    </a>
                </div>
            </div>
            <div className="hever-details border">
                <span>{t('customer service: 03-5190793')}</span>
                <span className="px-2">|</span>
                <span>
                    <a href="mailto:textify@hevertranslations.com">textify@hevertranslations.com</a>
                </span>
            </div>
            <div className="copyright border">
                <span>{t('all rights reserved to Hever')}</span>
            </div>
        </div>
    );
};

export default withTranslation()(Footer);
