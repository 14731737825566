import { action, observable } from 'mobx';

export default class UserUiState {
    @observable hasUserResetPassword: boolean = false;

    @action
    public setHasUserResetPassword = (status: boolean) => {
        this.hasUserResetPassword = status;
    };
}
