import UserStore from '../stores/UserStore';
import { ApolloClient } from 'apollo-client';
import { LogWorkOnTranscriptMutation, LogWorkOnTranscriptMutationArgs } from '../graphql/types';
import { logWorkOnTranscriptMutation } from '../graphql/customer/mutations/logWorkOnTranscript';
import _ from 'lodash';

export default class WorkLoggingService {
    constructor(private client: ApolloClient<any>, private userStore: UserStore) {}

    logWorkOnTranscript(transcriptId: string) {
        if (!this.userStore.isTranscriber) {
            return;
        }

        this.doLogWorkOnTranscript(transcriptId);
    }

    private doLogWorkOnTranscript = _.debounce(
        async (transcriptId: string) => {
            return this.client
                .mutate<{ logWorkOnTranscript: LogWorkOnTranscriptMutation }, LogWorkOnTranscriptMutationArgs>({
                    mutation: logWorkOnTranscriptMutation,
                    variables: { transcriptId }
                })
                .catch(err => console.error(`Failed to log work on transcript: ${transcriptId}`, err));
        },
        1000,
        {
            maxWait: 15 * 1000
        }
    );
}
