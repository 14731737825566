import React, { ReactNode, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
    content: string;
    children?: ReactNode;
    rebindAllTooltipsOnMount?: boolean;
};

const Tooltip = ({ content, children, rebindAllTooltipsOnMount = true }: Props) => {
    // Was needed to fix certain tooltips not responding to onmousehover events, after being placed in draftjs transcript
    // optionally skipped for performance reasons when lots of tags are present
    if (rebindAllTooltipsOnMount) {
        useEffect(() => {
            ReactTooltip.rebuild();
        }, []);
    }

    return (
        <span data-tip={content} data-event-off="click mouseleave" data-event="mouseenter">
            {children}
        </span>
    );
};

export default Tooltip;
