import React, { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TimeUtils from '../../../framework/utils/TimeUtils';
import { File } from '../../../graphql/types';
import TranscriberFilesDropDown from '../../transcriber/header-info/TranscriberFilesDropDown';
import './HeaderInfo.scss';
import { RouteComponentProps, withRouter } from 'react-router';

type HeaderInfoProps = {
    file: File;
    myFiles?: File[];
    showTranscriberFilesDropDown?: boolean;
    submitButtons?: ReactNode;
};

const HeaderInfo = ({
    file,
    myFiles = [],
    t,
    showTranscriberFilesDropDown = false,
    submitButtons
}: HeaderInfoProps & WithTranslation & RouteComponentProps) => {
    return (
        <div className="header-info">
            <div className="right-side">
                {showTranscriberFilesDropDown && <TranscriberFilesDropDown file={file} files={myFiles} />}

                <div className="file-info">
                    <span className="item">
                        {t('category')}: <span className="value">{file.category}</span>
                    </span>
                    <span className="item">
                        {t('status')}: <span className="value">{t(file.status)}</span>
                    </span>
                    <span className="item">
                        {t('file length')}: <span className="value">{file.mediaFileDuration}</span>
                    </span>
                    <span className="item">
                        {t('due date')}:{' '}
                        <span className="value">{TimeUtils.formatDate(file.dueDate, 'DD/MM/YYYY - HH:mm')}</span>
                    </span>
                </div>
            </div>

            <div className="submit-buttons">{submitButtons}</div>
        </div>
    );
};

export default withTranslation()(withRouter(HeaderInfo));
