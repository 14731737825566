import { Money } from '../../graphql/types';

export const formatMoney = (money: Money): string => {
    const options = {
        style: 'currency',
        currency: money.currency,
        minimumFractionDigits: 2
    };
    if (money.amount % 100 === 0) options.minimumFractionDigits = 0;
    const formatter = new Intl.NumberFormat('he-IL', options);
    return formatter.format(money.amount);
};
