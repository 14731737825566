import { action, computed, observable, reaction } from 'mobx';
import env from '../env';
import ReactPlayer from 'react-player';
import { DateTime } from '../graphql/types';
import FileStore from '../stores/FileStore';
import UserStore from '../stores/UserStore';
import TranscriptUiState from './TranscriptUiState';
import { ModalService } from '../services/ModalService';
import SearchUiState from './SearchUiState';
import WorkLoggingService from '../services/WorkLoggingService';
import * as Sentry from '@sentry/browser';
import ReactTooltip from 'react-tooltip';

const { SEEK_TIME_SECONDS } = env;

export default class VideoPlayerUiState {
    @observable public player: ReactPlayer | null = null;
    @observable public videoTimeInSeconds: number = 0;
    @observable public isPairingCursorToVideoTime: boolean = true;
    @observable public createBlocksAutomatically: boolean = true;
    @observable public isPlaying: boolean = false;
    @observable public isMute: boolean = false;
    @observable public totalTime: number = 0;
    @observable public playbackRate: number = 1;

    constructor(
        private fileStore: FileStore,
        private transcriptUiState: TranscriptUiState,
        private searchUiState: SearchUiState,
        private userStore: UserStore,
        private modalService: ModalService,
        private workLoggingService: WorkLoggingService
    ) {
        reaction(
            () => this.matchingBlockIndex,
            blockIndex => {
                if (!this.videoTimeInSeconds) {
                    return;
                }

                if (!this.transcriptUiState.transcript) {
                    return;
                }

                this.transcriptUiState.syncTranscriptUptoBlockNumber(
                    blockIndex,
                    this.isPairingCursorToVideoTime,
                    this.createBlocksAutomatically
                );
            }
        );
        reaction(() => this.positionOfCurrentBlock, timeInSeconds => this.seekVideoTo(timeInSeconds));
        reaction(
            () => this.player,
            player => {
                if (player) {
                    this.totalTime = player.getDuration();
                } else {
                    this.totalTime = 0;
                }
            }
        );

        reaction(
            () => this.isLoaded,
            () => {
                // Work around tags tooltip not showing in draftjs until the following is called.
                // usually this is called in the Tooltip component, but when multiple tags appear it causes a performance
                // issue
                ReactTooltip.rebuild();
            }
        );
    }

    @computed
    get matchingBlockIndex() {
        return !this.videoTimeInSeconds || !this.transcript
            ? 0
            : Math.trunc(this.videoTimeInSeconds / this.transcript.blockInterval);
    }

    @computed
    get transcript() {
        return this.transcriptUiState.transcript || this.searchUiState.activeTranscript;
    }

    @computed
    get isLoaded() {
        return this.totalTime !== 0;
    }

    @computed
    get positionOfCurrentBlock() {
        return Math.min(!this.transcript ? 0 : this.transcript.activeBlock.videoPositionInSeconds, this.totalTime);
    }

    @action seekVideoTo = (seconds: number, allowedSeekThresholdSeconds = 1) => {
        if (!this.player) {
            return;
        }

        if (!this.isLoaded) {
            return;
        }

        if (Math.abs(this.videoTimeInSeconds - seconds) < allowedSeekThresholdSeconds) {
            return;
        }

        this.player.seekTo(Math.min(Math.max(seconds, 0), this.totalTime), 'seconds');
    };
    @action setVideoTime = (time: number) => {
        this.videoTimeInSeconds = time;
    };
    @action togglePairingCursorToVideoTimeCheckbox = () => {
        this.isPairingCursorToVideoTime = !this.isPairingCursorToVideoTime;
    };
    @action toggleCreateBlocksAutomatically = () => {
        this.createBlocksAutomatically = !this.createBlocksAutomatically;
    };
    @action setVideoPlayer = (player: ReactPlayer) => {
        this.player = player;
        const internalPlayer = this.player.getInternalPlayer() as HTMLVideoElement;
        internalPlayer.addEventListener('pause', () => {
            if (!this.player) {
                return;
            }

            const internalPlayer = this.player.getInternalPlayer() as HTMLVideoElement;
            // this event is also fired when the video is seeked, ignore that case
            if (internalPlayer.readyState === 4) {
                this.setStopped();
            }
        });
    };
    @action setPlaying = () => {
        console.debug('Video started');
        this.isPlaying = true;
        this.logWorkOnCurrentTranscript();
    };
    @action setStopped = () => {
        console.debug('Video paused');
        this.isPlaying = false;
        this.logWorkOnCurrentTranscript();
    };
    @action onForwardClick = () => {
        this.seekForward();
        this.logWorkOnCurrentTranscript();
    };
    @action onRewindClick = () => {
        this.seekVideoTo(this.videoTimeInSeconds - SEEK_TIME_SECONDS);
        this.logWorkOnCurrentTranscript();
    };
    @action toggleMute = () => {
        this.isMute = !this.isMute;
        this.logWorkOnCurrentTranscript();
    };
    @action onSeekChange = (seconds: number) => this.seekVideoTo(seconds);
    @action changeRecordingTime = (fileId: string, date: DateTime) => {
        return this.fileStore.changeRecordingTime({ date, transcriptionId: fileId });
    };
    @action resetVideoTime = () => {
        this.isPlaying = false;
        this.seekVideoTo(0);
        this.logWorkOnCurrentTranscript();
    };

    handleError = (error: Error, errorData: { [x: string]: string }) => {
        const videoFile = this.fileStore.currentFile;
        console.error('Playback error');
        Sentry.setExtras({
            details: errorData,
            videoFile: videoFile && videoFile.mediaFileName,
            currentTime: this.videoTimeInSeconds
        });
        Sentry.captureException(JSON.stringify(error));
    };

    configureParticipants = () => this.modalService.openParticipantsModal();

    @action clearVideo = () => {
        this.player = null;
        this.videoTimeInSeconds = 0;
        this.isPlaying = false;
        this.isMute = false;
        this.totalTime = 0;
    };

    @action
    changePlaybackRate = (playbackRate: number) => (this.playbackRate = playbackRate);

    @action
    private seekForward = () => this.seekVideoTo(this.videoTimeInSeconds + SEEK_TIME_SECONDS);

    private logWorkOnCurrentTranscript = () => {
        const { transcript } = this.transcriptUiState;
        if (transcript) {
            this.workLoggingService.logWorkOnTranscript(transcript.id);
        }
    };
}
