import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, PopoverBody } from 'reactstrap';
import env from '../../../../env';
import InjectedComponent from '../../../../framework/utils/InjectedComponent';
import { AuthService } from '../../../../services/AuthService';
import UserStore from '../../../../stores/UserStore';
import AppUiState from '../../../../ui-states/AppUiState';
import './ProfileIcon.scss';

type ProfileIconState = {
    isPopoverOpen: boolean;
};

type ProfileIconProps = {
    profileImg: string;
    fullName: string;
};

type InjectedProps = {
    authService: AuthService;
    userStore: UserStore;
};

@inject('authService', 'userStore')
@observer
class ProfileIcon extends InjectedComponent<
    InjectedProps,
    ProfileIconProps & WithTranslation & RouteComponentProps,
    ProfileIconState
> {
    state = {
        isPopoverOpen: false
    };

    public render() {
        const { isPopoverOpen } = this.state;
        const { userStore } = this.injectedProps;
        const {
            profileImg,
            fullName,
            t,
            match: { path }
        } = this.props;
        return (
            <div className="profile-icon">
                <div className="profile-image" id="profile-popover" onClick={this.onToggle}>
                    <img src={profileImg} alt={fullName} />
                </div>
                <Popover
                    placement="bottom"
                    target="profile-popover"
                    isOpen={isPopoverOpen}
                    toggle={this.onToggle}
                    className="profile-popover">
                    <PopoverBody>
                        {userStore.isCustomer ? (
                            <ul>
                                <li>
                                    <a className="cursor-pointer" onClick={this.onLogoutClick}>
                                        {t('logout')}
                                    </a>
                                </li>
                            </ul>
                        ) : (
                            <ul>
                                <li>
                                    <span>
                                        <Link to={`${path}/profile`} onClick={this.onToggle}>
                                            {t('employee profile')}
                                        </Link>
                                    </span>
                                </li>
                                {env.STAGE_B && (
                                    <li>
                                        <span>
                                            <Link to={`${path}/history`} onClick={this.onToggle}>
                                                {t('history')}
                                            </Link>
                                        </span>
                                    </li>
                                )}
                                <li>
                                    <a className="cursor-pointer" onClick={this.onLogoutClick}>
                                        {t('logout')}
                                    </a>
                                </li>
                            </ul>
                        )}
                    </PopoverBody>
                </Popover>
            </div>
        );
    }

    private onToggle = () => {
        this.setState(prevState => ({
            isPopoverOpen: !prevState.isPopoverOpen
        }));
    };
    private onLogoutClick = async () => {
        const { authService } = this.injectedProps;
        this.onToggle();
        await authService.logout();
    };
}

export default withTranslation()(withRouter(ProfileIcon));
