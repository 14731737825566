// @ts-ignore
import SimpleDecorator from 'draft-js-simpledecorator';
import React, { ReactNode } from 'react';

export const createInvalidCharDecorator = (matchCallback: (anyMatch: boolean) => void) =>
    new SimpleDecorator(
        (contentBlock: any, callback: any, contentState: any) => {
            const content = contentBlock.getText();
            const nonISO8859_8RE = /[^\s\u0020-\u007e\u00a0\u00a2-\u00a9\u00d7\u00ab-\u00b9\u00f7\u00bb-\u00be\u2017\u05d0-\u05ea\u200e\u200f]/g;
            let match;
            let anyMatch = false;
            while ((match = nonISO8859_8RE.exec(content))) {
                callback(match.index, match.index + 1, {});
                anyMatch = true;
            }
            matchCallback(anyMatch);
        },
        ({ children }: { children: ReactNode }) => <span style={{ color: 'red', fontWeight: 'bold' }}>{children}</span>
    );
