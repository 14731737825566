import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import ShortcutService from '../../../services/ShortcutService';
import FileStore from '../../../stores/FileStore';
import UserStore from '../../../stores/UserStore';
import TranscriptUiState from '../../../ui-states/TranscriptUiState';
import VideoPlayerUiState from '../../../ui-states/VideoPlayerUiState';
import HeaderInfo from '../../common/header-info/HeaderInfo';
import TextEditor from '../../common/text-editor/TextEditor';
import Title from '../../common/title/Title';
import VideoPlayer from '../../common/video-player/VideoPlayer';
import withStores from '../../../framework/hoc/withStores';
import { withLoadable } from '../../../framework/hoc/withLoadable';
import ExportBar from '../../customer/search-result-page/export-bar/ExportBar';
import AdminSubmitButtons from './header-info/AdminSubmitButtons';
import { ModalService } from '../../../services/ModalService';

interface InjectedProps extends RouteComponentProps {
    fileStore: FileStore;
    transcriptUiState: TranscriptUiState;
    userStore: UserStore;
    shortcutService: ShortcutService;
    videoPlayerUiState: VideoPlayerUiState;
    reload: () => void;
    modalService: ModalService;
}

type ParamsProps = {
    id: string;
};

type AdminTranscriptPageProps = InjectedProps & RouteComponentProps<ParamsProps> & WithTranslation;

@observer
class AdminTranscriptPage extends Component<AdminTranscriptPageProps> {
    async componentDidMount() {
        const { transcriptUiState, modalService } = this.props;
        document.addEventListener('keydown', this.handleKeyDown);

        if (transcriptUiState.file && isEmpty(transcriptUiState.file.speakers)) {
            modalService.openParticipantsModal();
        }
    }

    async componentWillUnmount() {
        const { videoPlayerUiState, transcriptUiState } = this.props;

        document.removeEventListener('keydown', this.handleKeyDown);
        transcriptUiState.reset();
        videoPlayerUiState.clearVideo();
    }

    handleKeyDown = (e: KeyboardEvent) => this.props.shortcutService.handleKeyPress(e);

    componentDidUpdate(prevProps: RouteComponentProps<ParamsProps>) {
        const { match } = this.props;
        const { id } = match.params;
        if (prevProps.match.params.id !== id) {
            this.props.reload();
        }
    }

    render() {
        const {
            transcriptUiState,
            transcriptUiState: { file: currentFile },
            fileStore: { myFiles }
        } = this.props;
        const { t } = this.props;

        return (
            <Container fluid={true}>
                <Title title={`${t('file name')}: ${currentFile!.mediaFileName}`} />
                <Row>
                    <Col xs="12">
                        <HeaderInfo
                            file={currentFile!}
                            myFiles={myFiles}
                            submitButtons={<AdminSubmitButtons file={currentFile!} />}
                        />
                        <ExportBar onExportTranscriptionClick={transcriptUiState.openExportTranscriptModal} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs="8">
                        <TextEditor />
                    </Col>
                    <Col xs="4">
                        <VideoPlayer file={currentFile!} showVideoTimePairing={true} allowConfigureSpeakers={false} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(
    withRouter(
        withStores(
            'fileStore',
            'transcriptUiState',
            'userStore',
            'shortcutService',
            'videoPlayerUiState',
            'modalService'
        )(
            withLoadable({
                loader: ({
                    transcriptUiState,
                    match: {
                        params: { id }
                    }
                }: AdminTranscriptPageProps) => {
                    return transcriptUiState.initialize(id);
                }
            })(AdminTranscriptPage)
        )
    )
);
