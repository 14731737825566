import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Transcriber, VideoShortcutType } from '../../../../graphql/types';
import '../Tab.scss';
import ShortcutForm, { Shortcut } from './ShortcutForm';

type EditorShortcutTabProps = {
    transcriber: Transcriber;
    onHide(): void;
    onSubmit(shortcuts: Shortcut[]): void;
    getActiveKeys(e: React.KeyboardEvent): Set<string>;
};

class VideoPlayerShortcutTab extends Component<EditorShortcutTabProps & WithTranslation> {
    render() {
        const { t, transcriber, onHide, onSubmit, getActiveKeys } = this.props;

        return (
            <div data-testid="video-player-tab" className="tab">
                <ShortcutForm
                    editorShortcuts={transcriber.videoShortcuts}
                    getActiveKeys={getActiveKeys}
                    onCancel={onHide}
                    onSubmit={async shortcuts => {
                        await onSubmit(shortcuts);
                        await onHide();
                    }}
                    shortcutTypes={Object.keys(VideoShortcutType).map(key => ({
                        value: VideoShortcutType[(key as unknown) as number],
                        label: t(VideoShortcutType[(key as unknown) as number])
                    }))}
                />
            </div>
        );
    }
}

export default withTranslation()(VideoPlayerShortcutTab);
