import LoadingSpinner, { SpinnerSize } from './LoadingSpinner';
import React from 'react';
import classnames from 'classnames';

const InlineSpinner: React.FC<{ size?: SpinnerSize; className?: string }> = ({ size, className }) => (
    <LoadingSpinner
        className={classnames('d-flex flex-fill align-items-center justify-content-center', className)}
        size={size}
    />
);
export default InlineSpinner;
