import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import search from '../../../../assets/images/search.png';
import FilePageUiState from '../../../../ui-states/FilePageUiState';
import ControlledInput from '../../../common/inputs/ControlledInput';
import './FilterFiles.scss';
import withStores from '../../../../framework/hoc/withStores';

type SearchTitleState = {
    searchQuery: string;
};
type InjectedProps = {
    filePageUiState: FilePageUiState;
};

type FilterFilesProps = WithTranslation & InjectedProps;

@observer
class FilterFiles extends Component<FilterFilesProps, SearchTitleState> {
    render() {
        const {
            t,
            filePageUiState: { searchTerm }
        } = this.props;
        return (
            <div className="title search-title">
                <h1>{t('main page')}</h1>
                <ControlledInput
                    icon={search}
                    name="searchQuery"
                    placeholder={t('search value')}
                    onChange={x => this.search(x.target.value)}
                    value={searchTerm}
                />
            </div>
        );
    }

    private search = (searchQuery: string) => {
        const { filePageUiState } = this.props;
        filePageUiState.setSearchTerm(searchQuery);
    };
}

export default withTranslation()(withStores('filePageUiState')(FilterFiles));
