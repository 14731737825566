import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ParagraphSearchResult } from '../../../../graphql/types';
import BlockService from '../../../../services/BlockService';
import Checkbox from '../../../common/checkbox/Checkbox';
import LimitedText from '../../../common/tooltip/LimitedText';
import classnames from 'classnames';

type SearchRowProps = {
    searchResult: ParagraphSearchResult;
    speaker?: string | null;
    body: string;
    timestamp: string;
    resultId: string;
    isActive: boolean;
    checked: boolean;
    onClick: () => void;
    onCheckBoxClick: () => void;
};

class ParagraphSearchRow extends Component<SearchRowProps & WithTranslation> {
    render() {
        const { isActive, onClick, onCheckBoxClick, checked, t, speaker, body, resultId, timestamp } = this.props;
        return (
            <tr className="paragraph-row ">
                <td>
                    <div className="d-flex justify-content-around align-items-center">
                        <div>
                            <Checkbox value={resultId} onChange={onCheckBoxClick} checked={checked} />
                        </div>
                        <div className="speakers">
                            <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">{t('speaker')}</span>
                                {speaker && <LimitedText content={speaker} maxLength={20} />}
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">{t('time code')}</span>
                                {timestamp}
                            </div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </div>
                    </div>
                </td>
                <td onClick={onClick} className={classnames({ active: isActive }, 'cursor-pointer')}>
                    <span dangerouslySetInnerHTML={{ __html: body }} />
                </td>
            </tr>
        );
    }
}

export default withTranslation()(ParagraphSearchRow);
