import {
    faBackward,
    faClock,
    faForward,
    faPause,
    faPlay,
    faRedo,
    faStop,
    faVolumeMute,
    faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import './VideoControls.scss';
import env from '../../../../env';
import _ from 'lodash';
import classnames from 'classnames';

const { ALLOWED_PLAYBACK_SPEEDS } = env;

type VideoControlsProps = {
    timeElapsed: string;
    disabled: boolean;
    isPlaying: boolean;
    isMuted: boolean;
    playbackRate: number;
    onPlaybackRateChanged: (x: number) => void;
    onBackwardClick(): void;
    onPauseClick(): void;
    onPlayClick(): void;
    onStopClick(): void;
    onForwardClick(): void;
    onMuteClick(): void;
};

const VideoControls = ({
    disabled,
    timeElapsed,
    onBackwardClick,
    onPauseClick,
    onPlayClick,
    onStopClick,
    onForwardClick,
    onMuteClick,
    isPlaying,
    isMuted,
    onPlaybackRateChanged,
    playbackRate
}: VideoControlsProps & WithTranslation) => {
    const [isPlaybackRateDropdownShown, setIsPlaybackRateDropdownShown] = useState(false);
    return (
        <div className={classnames('video-controls', { disabled })}>
            <div className="timestamp align-items-center d-flex">
                <span>{timeElapsed}</span>&nbsp;
                <FontAwesomeIcon icon={faClock} />
            </div>
            <ButtonGroup>
                <Button outline onClick={onBackwardClick} data-testid="backward-button">
                    <FontAwesomeIcon icon={faBackward} />
                </Button>
                {isPlaying ? (
                    <Button outline onClick={onPauseClick}>
                        <FontAwesomeIcon icon={faPause} />
                    </Button>
                ) : (
                    <Button outline onClick={onPlayClick} data-testid="play-button">
                        <FontAwesomeIcon icon={faPlay} />
                    </Button>
                )}
                <Button outline onClick={onStopClick} data-testid="stop-button">
                    <FontAwesomeIcon icon={faStop} />
                </Button>
                <Button outline onClick={onForwardClick} data-testid="forward-button">
                    <FontAwesomeIcon icon={faForward} />
                </Button>
                <Button outline onClick={onMuteClick}>
                    <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                </Button>
                <Dropdown
                    className="playback-rate-selector"
                    disabled={disabled}
                    isOpen={isPlaybackRateDropdownShown}
                    toggle={() => setIsPlaybackRateDropdownShown(!isPlaybackRateDropdownShown)}>
                    <DropdownToggle className="cursor-pointer" tag="a" caret disabled={disabled}>
                        <span className="pl-2">{playbackRate}x</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        {_.sortBy(ALLOWED_PLAYBACK_SPEEDS, x => -x).map((speed, index) => (
                            <DropdownItem key={index} onClick={() => onPlaybackRateChanged(speed)}>
                                {speed}x
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </ButtonGroup>
        </div>
    );
};

export default withTranslation()(VideoControls);
