import { Field, FieldProps } from 'formik';
import React from 'react';
import { FormFeedback, Input, Label } from 'reactstrap';

import './NormalInput.scss';
import { InputType } from 'reactstrap/lib/Input';

type FormikInputProps = {
    icon?: string;
    name: string;
    placeholder?: string;
    invalid?: boolean;
    type?: InputType;
    error?: string;
    className?: string;
    value?: any;
    label?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    [index: string]: any;
};

const FormikInput = React.forwardRef<HTMLInputElement, FormikInputProps>(
    ({ name, placeholder = '', type, invalid = false, icon, error, className, label, ...someOtherProps }, ref) => {
        return (
            <div className={`input-wrapper ${className ? className : ''}`}>
                <div className={`input ${name}`}>
                    {icon && <img src={icon} className="icon" alt="" />}
                    {label && <Label for={name}>{label}</Label>}
                    <Field
                        name={name}
                        render={({ field }: FieldProps) => (
                            <Input
                                innerRef={ref}
                                type={type}
                                data-testid={`test-${name}`}
                                placeholder={placeholder}
                                invalid={invalid}
                                {...field}
                                {...someOtherProps}
                            />
                        )}
                    />
                    <FormFeedback tooltip={true}>{error}</FormFeedback>
                </div>
            </div>
        );
    }
);

FormikInput.defaultProps = {
    type: 'text'
};

export default FormikInput;
