import React, { useEffect, useRef } from 'react';

export default <T extends HTMLOrSVGElement>() => {
    const ref = useRef(null) as React.RefObject<T>;
    useEffect(
        () => {
            setTimeout(() => {
                ref.current && ref.current.focus();
            }, 50);
        },
        [ref]
    );

    return ref;
};
