import { EditorProps, EditorState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import ParagraphModel from '../../../../../models/ParagraphModel';
import TagStore from '../../../../../stores/TagStore';
import { createTagDecorator } from '../../../../../framework/draft/tags/tagDecorator';
import { createInvalidCharDecorator } from '../../../../../framework/draft/exportableCharacters/invalidCharDecorator';
import withStores from '../../../../../framework/hoc/withStores';
import i18n from '../../../../../localization/i18n';
import './ParagraphEditor.scss';

type InjectedProps = {
    tagStore: TagStore;
};

type ParagraphEditorProps = {
    paragraph: ParagraphModel;
    readOnly?: boolean;
    onFocus: () => void;
    isCustomer: boolean;
} & InjectedProps &
    Partial<EditorProps>;

interface State {
    gotInvalidChars?: boolean;
}
const DISABLE_HOLD_TO_DISABLE = -1;

@observer
class ParagraphEditor extends Component<ParagraphEditorProps, State> {
    private editorRef: any = null;
    state: State = {};

    gotInvalidCharsCallback = (gotInvalidChars: boolean) => this.setState({ gotInvalidChars });

    render() {
        const {
            tagStore,
            readOnly,
            paragraph,
            handleKeyCommand,
            handleBeforeInput,
            keyBindingFn,
            onUpArrow,
            isCustomer,
            onDownArrow,
            onFocus
        } = this.props;
        const editor = (
            <Editor
                spellCheck
                readOnly={readOnly}
                placeholder={!paragraph.isActive ? i18n.t('empty block') : null}
                editorState={paragraph.editorState}
                decorators={[
                    createTagDecorator(tagStore.allTags),
                    createInvalidCharDecorator(this.gotInvalidCharsCallback)
                ]}
                onChange={(editorState: EditorState) => paragraph.setEditorState(editorState)}
                plugins={[...paragraph.plugins]}
                ref={(ref: any) => (this.editorRef = ref)}
                onUpArrow={onUpArrow}
                onDownArrow={onDownArrow}
                handleBeforeInput={handleBeforeInput}
                handleKeyCommand={handleKeyCommand}
                keyBindingFn={keyBindingFn}
                onFocus={onFocus}
            />
        );
        return (
            <div data-testid="draft-editor" onClick={() => this.focus()} className="paragraph-editor">
                {isCustomer ? (
                    <ContextMenuTrigger
                        id={`context-menu-tag-popup`}
                        holdToDisplay={DISABLE_HOLD_TO_DISABLE}
                        collect={() => {
                            return {
                                tagEntity: {
                                    blockKey: paragraph.firstDraftBlock.getKey(),
                                    entityKey: null
                                }
                            };
                        }}>
                        {editor}
                    </ContextMenuTrigger>
                ) : (
                    editor
                )}
                {this.state.gotInvalidChars && (
                    <span className="invalid-chars-alert">
                        הפסקה מכילה תוים שלא ניתן לייצא לפורמט AVID (מסומנים באדום)
                    </span>
                )}
            </div>
        );
    }

    public focus() {
        if (this.props.readOnly) {
            this.props.onFocus();
        } else {
            this.editorRef && this.editorRef.focus();
        }
    }
}

export default withStores('tagStore')(ParagraphEditor);
