import { RouterStore } from 'mobx-react-router';
import { truncateByLength } from '../framework/utils/StringUtils';
import TimeUtils from '../framework/utils/TimeUtils';
import { ConvertToTagInput, ExportFormat, File, Project, SelectedResultInput } from '../graphql/types';
import FileStore from '../stores/FileStore';
import SearchStore from '../stores/SearchStore';
import TagStore from '../stores/TagStore';
import TranscriptStore from '../stores/TranscriptStore';
import UserStore from '../stores/UserStore';
import ModalManagerUiState, { ModalType } from '../ui-states/modalManagerUiState';
import UserUiState from '../ui-states/UserUiState';
import i18n from '../localization/i18n';
import BlockModel from '../models/BlockModel';
import Toast from '../framework/utils/Toast';

export class ModalService {
    constructor(
        private modalManagerUiState: ModalManagerUiState,
        private fileStore: FileStore,
        private userStore: UserStore,
        private searchStore: SearchStore,
        private tagStore: TagStore,
        private routerStore: RouterStore,
        private userUiState: UserUiState,
        private transcriptStore: TranscriptStore
    ) {}

    openAcceptWorkingOnTranscriptModal = (fileId: string, mediaFileName: string, dueDate: Date) => {
        this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit: () => this.fileStore.acceptWorkingOnFile({ id: fileId }),
            titleText: `אישור הקובץ : ${truncateByLength(mediaFileName, 30)}`,
            sendConfirmText: 'אישור',
            bodyText: `                  שים לב - מועד ההגשה של הקובץ הוא ה-
        ${TimeUtils.formatDate(dueDate, 'DD/MM/YYYY')}
        `
        });
    };

    openParticipantsModal = () => {
        this.modalManagerUiState.openModal(ModalType.CONFIGURE_PARTICIPANT_MODAL, {});
    };

    openShortcutsModal = () => {
        this.modalManagerUiState.openModal(ModalType.TRANSCRIBER_SHORTCUTS_MODAL, {
            transcriber: this.userStore.user.transcriber
        });
    };

    openSubmitForApprovalModal = (fileId: string) => {
        this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit: async () => {
                await this.fileStore.sendFileForApproval({ id: fileId });
                this.routerStore.history.push('/');
            },
            titleText: 'האם אתה בטוח שאתה רוצה לשלוח את הקובץ לאישור?',
            sendConfirmText: 'מאשר שליחת קובץ לאישור',
            bodyText: 'לא יהיה ניתן יותר לערוך את הקובץ לאחר שליחתו לאישור'
        });
    };

    openApproveFileModal = (fileId: string) => {
        this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit: async () => {
                await this.fileStore.approveFile({ fileId });

                // assuming we are coming from django admin, lets reload the page
                // todo: wrap in service?
                window.opener.postMessage({ cmd: 'RELOAD' }, '*');

                // closing current window, might fail in some cases, so we do history push as a fallback
                window.close();
                this.routerStore.history.push('/');
            },
            titleText: 'האם אתה בטוח שאתה רוצה לאשר את הקובץ?',
            sendConfirmText: 'מאשר קובץ',
            bodyText: 'לא יהיה ניתן יותר לערוך את הקובץ לאחר אישורו'
        });
    };

    openModifyBlockOffsetModal = (block: BlockModel) => {
        this.modalManagerUiState.openModal(ModalType.MODIFY_BLOCK_OFFSET_MODAL, {
            onSubmit: async (newTimecode: number) => {
                await block.modifyTimecode(newTimecode);
            },
            titleText: 'אנא הזן את זמן תחילת המקטע',
            initialTimeCode: block.timecode,
            minimumTimeCode: block.originalTimecode
        });
    };

    openResetPasswordModal = () => {
        this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit: async () => {
                this.userUiState.setHasUserResetPassword(true);
                await this.userStore.resetPassword();
            },
            titleText: 'האם אתה בטוח שאתה רוצה לאפס את הסיסמא?',
            sendConfirmText: 'כן, אפס לי את הסיסמא',
            sendCancelText: 'לא, חזור אחורה'
        });
    };

    deleteTranscriptionModal = (fileId: string, mediaFileName: string) => {
        this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit: () => this.fileStore.deleteFile({ id: fileId }),
            titleText: `מחיקת הקובץ : ${truncateByLength(mediaFileName, 20)}`,
            sendConfirmText: 'מחק קובץ',
            bodyText: `האם אתה בטוח שברצונך למחוק את הקובץ: ${truncateByLength(mediaFileName, 15)}?`
        });
    };

    sendTranscriptionForRepairModal = (file: File) => {
        this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit: async () => {
                await this.fileStore.sendFileForRepair({ fileId: file.id });
                this.routerStore.history.push(`/customer/projects/${file.project.id}`);
            },
            titleText: 'שליחת הקובץ לתיקון',
            sendConfirmText: 'שלח קובץ',
            bodyText: `האם אתה בטוח שברצונך לשלוח קובץ : ${truncateByLength(file.mediaFileName, 15)}לתיקון?  `
        });
    };

    exportTranscript = (fileIds: string[]) => {
        this.modalManagerUiState.openModal(ModalType.EXPORT_TRANSCRIPTION_MODAL, {
            titleText: 'הורדת קובץ',
            onSubmit: async (exportFormat: ExportFormat) => {
                try {
                    let fileUrl;
                    if (fileIds.length > 1) {
                        fileUrl = await this.transcriptStore.exportTranscriptsFiles({ fileIds, exportFormat });
                    } else if (fileIds.length === 1) {
                        const outFileUrl = await this.transcriptStore.exportTranscriptFile({
                            fileId: fileIds[0],
                            exportFormat
                        });
                        fileUrl = outFileUrl;
                    }
                    this.downloadFile(fileUrl);
                    return fileUrl;
                } catch (err) {
                    Toast.error(i18n.t('Failed to export selected files.'));
                }
            }
        });
    };

    exportSelectedResults = (selectedResults: SelectedResultInput[]) => {
        this.modalManagerUiState.openModal(ModalType.EXPORT_TRANSCRIPTION_MODAL, {
            titleText: `הורדת קטעים נבחרים - סה״כ נבחרו:${selectedResults.length}`,
            validate: selectedResults.length !== 0,
            onSubmit: async (exportFormat: ExportFormat) => {
                const { data } = await this.searchStore.exportSelectedSearchResults({
                    searchResults: selectedResults,
                    exportFormat
                });
                const link = data!.exportSelectedSearchResults.outFileUrl;
                this.downloadFile(link);
                return link;
            }
        });
    };

    exportSelectedBlocks = (fileId: string, selectedBlocks: string[]) => {
        this.modalManagerUiState.openModal(ModalType.EXPORT_TRANSCRIPTION_MODAL, {
            titleText: `הורדת קטעים נבחרים - סה״כ נבחרו:${selectedBlocks.length}`,
            validate: selectedBlocks.length !== 0,
            onSubmit: async (exportFormat: ExportFormat) => {
                const { data } = await this.transcriptStore.exportSelectedBlocks({
                    selectedBlocks: selectedBlocks.map(x => ({ fileId, blockId: x })),
                    exportFormat
                });
                const link = data!.exportSelectedBlocks.outFileUrl;
                this.downloadFile(link);
                return link;
            }
        });
    };

    exportTags = (fileId?: string) => {
        this.modalManagerUiState.openModal(ModalType.EXPORT_TAGS_MODAL, {
            titleText: i18n.t('export tags'),
            fileId,
            allowedFormats: [
                { format: ExportFormat.Docx },
                {
                    format: ExportFormat.Avid,
                    disabledMessage: fileId ? null : i18n.t('format is disabled when not in file context')
                }
            ],
            onSubmit: async (exportFormat: ExportFormat, tagsIds: string[] = []) => {
                const { data } = await this.tagStore.exportTags(exportFormat, tagsIds, fileId);
                const link = data!.exportTags.outFileUrl;
                this.downloadFile(link);
                return link;
            }
        });
    };

    convertToTags = (selectedInputs: ConvertToTagInput[], onConvert: () => Promise<void>) => {
        this.modalManagerUiState.openModal(ModalType.CONVERT_TO_TAG_MODAL, {
            titleText: 'הפוך קטעים נבחרים לתגית',
            validate: selectedInputs.length !== 0,
            onSubmit: async (tagId: string) => {
                await this.tagStore.convertToTags({ tagId, selectedInputs });
                await onConvert();
            }
        });
    };

    addFilesToProject = (project: Project) => {
        this.modalManagerUiState.openModal(ModalType.ADD_FILES_TO_PROJECT_MODAL, {
            project
        });
    };

    openRefreshTranscriptModal = (onSubmit: () => void) => {
        return this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit,
            titleText: `גירסת תמלול לא מעודכנת!`,
            sendConfirmText: 'רענן',
            bodyText: `התמלול שמולך אינו בגירסא העדכנית ביותר, נא לרענן כדי לעדכן את השינויים.`,
            canCancel: false
        });
    };

    openGeneralConfirmModal = (onSubmit: () => void, bodyText: string) => {
        return this.modalManagerUiState.openModal(ModalType.CONFIRM_MODAL, {
            onSubmit,
            bodyText
        });
    };

    private downloadFile = (link: string) => {
        const aLink = document.createElement('a');
        aLink.href = link;
        aLink.setAttribute('target', '_blank');
        const e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        aLink.dispatchEvent(e);
        return true;
    };
}
