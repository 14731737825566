import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, PopoverBody } from 'reactstrap';
import grayLightbulb from '../../../../assets/images/grey-lightbulb.png';
import NotificationService from '../../../../services/NotificationService';
import NotificationStore from '../../../../stores/NotificationStore';
import './NotificationTray.scss';
import InlineSpinner from '../../loading-spinner/InlineSpinner';
import withStores from '../../../../framework/hoc/withStores';
import LimitedText from '../../tooltip/LimitedText';
import useInterval from '../../../../framework/hooks/useInterval';

type InjectedProps = {
    notificationStore: NotificationStore;
};

const NotificationTray = ({
    t,
    match: { path },
    notificationStore
}: InjectedProps & WithTranslation & RouteComponentProps) => {
    const [isPopoverOpen, togglePopover] = useState(false);
    useInterval(() => notificationStore.init(), 10 * 1000);

    return (
        <Observer>
            {() => {
                const { loading, unreadNotifications, notifications, markNotificationRead } = notificationStore;
                return (
                    <div className="notification-wrapper" id="notification-popover">
                        <div
                            className={`notification-icon ${unreadNotifications && 'has-notification-text'}`}
                            onClick={() => togglePopover(!isPopoverOpen)}
                            data-testid="notification-icon">
                            <span>{unreadNotifications}</span>
                            {unreadNotifications ? (
                                <span data-testid="has-notification-dot" className="has-notification-dot" />
                            ) : null}
                        </div>
                        <Popover
                            placement="bottom"
                            target="notification-popover"
                            isOpen={isPopoverOpen}
                            toggle={() => togglePopover(!isPopoverOpen)}
                            className="notification-popover">
                            <PopoverBody>
                                {notifications.length ? (
                                    <ul data-testid="notification-list">
                                        {notifications.slice(0, 5).map(notification => {
                                            return (
                                                <li
                                                    key={notification!.id}
                                                    onMouseEnter={() => {
                                                        if (notification.unread) {
                                                            return markNotificationRead([notification.id]);
                                                        }
                                                    }}
                                                    className={notification!.unread ? 'unread-bg' : ''}>
                                                    <Link
                                                        className="notification-info"
                                                        to={`${path}/${NotificationService.getLinkTo(notification)}`}
                                                        onClick={() => togglePopover(!isPopoverOpen)}>
                                                        <span className="notification-title">
                                                            <img
                                                                className={`lightbulb-icon ${
                                                                    notification!.unread ? 'unread' : ''
                                                                }`}
                                                                src={grayLightbulb}
                                                                alt=""
                                                            />
                                                            <LimitedText maxLength={50} content={notification!.title} />
                                                        </span>
                                                        <LimitedText
                                                            maxLength={60}
                                                            content={notification!.description}
                                                            component="p"
                                                        />
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : loading ? (
                                    <InlineSpinner size="small" className="p-4" />
                                ) : (
                                    <div className="no-notifications">{t('no notifications')}</div>
                                )}

                                <div className="all-notifications-link">
                                    <Link
                                        to={`${path}/all-notifications`}
                                        onClick={() => togglePopover(!isPopoverOpen)}>
                                        {t('all notifications')}
                                    </Link>
                                </div>
                            </PopoverBody>
                        </Popover>
                    </div>
                );
            }}
        </Observer>
    );
};

export default withStores('notificationStore')(withTranslation()(withRouter(NotificationTray)));
