import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { ModalService } from '../../../services/ModalService';
import UserStore from '../../../stores/UserStore';
import Title from '../../common/title/Title';
import Notifications from './notifications/Notifications';
import PersonalDetails from './personal-details/PersonalDetails';
import PriceMethods from './price-methods/PriceMethods';
import './ProfilePage.scss';
import FlexPage from '../../common/page/FlexPage';
import withStores from '../../../framework/hoc/withStores';
import UserUiState from '../../../ui-states/UserUiState';

type InjectedProps = {
    userStore: UserStore;
    modalService: ModalService;
    userUiState: UserUiState;
};

type ProfilePageProps = WithTranslation & InjectedProps;

@inject('userStore', 'modalService')
@observer
class ProfilePage extends Component<ProfilePageProps> {
    render() {
        const {
            t,
            userStore: { user },
            modalService,
            userUiState: { hasUserResetPassword }
        } = this.props;

        return (
            <FlexPage className="profile-page" data-testid="profile-page">
                <Title title={t('פרופיל אישי')} />
                <Row className="personal-info">
                    <Col xs="7">
                        <h2>{t('personal details')}</h2>
                        <div className="profile-box personal-details-wrapper">
                            <PersonalDetails
                                hasUserResetPassword={hasUserResetPassword}
                                fullName={user.fullName}
                                email={user.email}
                                phone={user.phone}
                                onResetButtonClick={() => modalService.openResetPasswordModal()}
                            />
                        </div>
                    </Col>
                    <Col xs="5">
                        <h2>{t('price method')}</h2>
                        <div className="profile-box price-methods-wrapper">
                            <PriceMethods priceMethods={user.transcriber!.priceMethods} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="5" className="custom-offset-7 notifications-wrapper">
                        <h2>{t('notifications')}</h2>
                        <div className="profile-box">
                            <Notifications
                                isEmailNotificationOn={user.shouldReceiveMail}
                                onToggle={this.onEmailNotificationToggle}
                            />
                        </div>
                    </Col>
                </Row>
            </FlexPage>
        );
    }
    private onEmailNotificationToggle = () => {
        const { userStore } = this.props;
        return userStore.changeEmailNotification();
    };
}

export default withTranslation()(withStores('userStore', 'userUiState')(ProfilePage));
