import { gql } from 'apollo-boost';
import { baseFileFragment } from './baseFileFragment';

export const fileFragment = gql`
    fragment file on File {
        ...baseFile
        project {
            id
            name
        }
        price {
            priceUnitType
            quantity
            pricePerUnit {
                amount
                currency
            }
            total {
                amount
                currency
            }
        }
    }
    ${baseFileFragment}
`;

export const fileWithSlimSpeakersFragment = gql`
    fragment file on File {
        id
        category
        status
        mediaFileName
        mediaFileUrl
        mediaStreamFileSignature
        mediaFileDuration
        isAudioFile
        dueDate
        encodingProgress
        project {
            id
            name
        }
        recordedAt
        speakers {
            id
            name
        }
        wordCount
        pageCount
    }
`;

export const slimFileFragment = gql`
    fragment file on File {
        id
        category
        speakers {
            id
            name
        }
        status
        mediaFileName
        mediaFileUrl
        mediaStreamFileSignature
        mediaFileDuration
        isAudioFile
        dueDate
        encodingProgress
        project {
            id
            name
        }
        recordedAt
    }
`;
