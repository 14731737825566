import { FieldProps } from 'formik';
import React from 'react';
import Select from 'react-select';
import { Option } from 'react-select/lib/filters';

type FormikSelectProps = {
    placeholder: string;
    option: Option;
    className?: string;
    noOptionsMessage: string;
};

const FormikSelect = ({
    options,
    field,
    form,
    placeholder,
    option,
    className,
    noOptionsMessage
}: FieldProps & FormikSelectProps & { options: any }) => {
    return (
        <Select
            className={className}
            options={options}
            isSearchable={false}
            name="shortcutType"
            value={option}
            noOptionsMessage={() => noOptionsMessage}
            // @ts-ignore
            onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
            onBlur={field.onBlur}
            placeholder={placeholder}
            inputProps={{ autoComplete: 'off', spellCheck: 'off' }}
        />
    );
};

export default FormikSelect;
