import axios from 'axios';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import blueDoc from '../../../../assets/images/blue-document.png';
import uploadCloud from '../../../../assets/images/upload-cloud.png';
import x from '../../../../assets/images/x.png';
import TimeUtils from '../../../../framework/utils/TimeUtils';
import { Project } from '../../../../graphql/types';
import FileUploadUiState, { ProjectUploadFiles, Status } from '../../../../ui-states/FileUploadUiState';
import SubmitButton from '../../submit-button/SubmitButton';
import './AddFilesToProjectModal.scss';
import withStores from '../../../../framework/hoc/withStores';
import InlineSpinner from '../../loading-spinner/InlineSpinner';
import LimitedText from '../../tooltip/LimitedText';
import env from '../../../../env';

type AddFilesToProjectModalState = {
    files: File[];
    dueDate: Date | null;
};

type props = {
    fileUploadUiState: FileUploadUiState;
};

type AddFilesToProjectModalProps = {
    isOpen: boolean;
    project: Project;
    onHide(): void;
} & WithTranslation &
    props;

@observer
class AddFilesToProjectModal extends Component<AddFilesToProjectModalProps, AddFilesToProjectModalState> {
    state = {
        files: [] as File[],
        dueDate: null
    };

    async componentDidMount() {
        const dueDate = await this.props.fileUploadUiState.getDueDate(this.props.project.id);
        this.setState({ dueDate });
    }

    render() {
        const { isOpen, onHide, t } = this.props;
        const { dueDate } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={onHide} size="lg" centered={true}>
                <div className="add-files-modal">
                    <ModalHeader>{t('add files')}</ModalHeader>
                    <ModalBody>
                        {!dueDate ? (
                            <InlineSpinner className="mb-4" />
                        ) : (
                            <>
                                {!_.isEmpty(this.state.files) && (
                                    <ul>
                                        <li>
                                            <div className="file-name" />
                                            <div className="due-date">{t('due date')}</div>
                                            <div />
                                        </li>
                                        {this.state.files.map(file => (
                                            <li key={file.name}>
                                                <div className="file-name">
                                                    <img src={blueDoc} alt="" />
                                                    <LimitedText content={file.name} maxLength={60} />
                                                </div>
                                                <div>
                                                    <input
                                                        disabled={true}
                                                        value={TimeUtils.formatDate(dueDate!, 'DD/MM/YY')}
                                                    />
                                                </div>
                                                <img
                                                    onClick={() => this.removeFromFiles(file)}
                                                    className="cursor-pointer"
                                                    src={x}
                                                    alt=""
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <form onSubmit={this.onSubmit}>
                                    <Dropzone
                                        accept={env.ALLOWED_FILE_FORMATS}
                                        onDrop={files =>
                                            this.setState(prevState => ({ files: [...prevState.files, ...files] }))
                                        }
                                        onFileDialogCancel={() => this.setState({ files: [] })}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="drop-zone-wrapper">
                                                <input {...getInputProps()} />
                                                <div className="drop-zone">
                                                    <img src={uploadCloud} alt="" />
                                                    <h4>{t('drag file here')}</h4>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <div className="submit-buttons">
                                        <SubmitButton disable={_.isEmpty(this.state.files)}>{t('upload')}</SubmitButton>
                                        <SubmitButton secondary={true} onClick={onHide}>
                                            {t('cancel')}
                                        </SubmitButton>
                                    </div>
                                    <div className="warning">
                                        {t('in case of an urgency works, please contact the project manager')}
                                    </div>
                                </form>
                            </>
                        )}
                    </ModalBody>
                </div>
            </Modal>
        );
    }

    private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        const { onHide } = this.props;
        const { fileUploadUiState } = this.props;
        e.preventDefault();
        await onHide();
        await fileUploadUiState.addToFiles(this.mapProjectFilesToProjectUploadFiles(this.state.files));
    };

    private removeFromFiles = (file: File) => {
        const files = [...this.state.files];
        const fileIndex = (files as File[]).findIndex(x => x.name === file.name);
        files.splice(fileIndex, 1);
        this.setState({ files });
    };

    private mapProjectFilesToProjectUploadFiles = (files: File[]): ProjectUploadFiles => {
        const { project } = this.props;
        return {
            projectId: project.id,
            projectName: project.name,
            files: files.map(file => ({
                name: file.name,
                uploadPercent: 0,
                file,
                status: Status.NOT_STARTED,
                cancelToken: axios.CancelToken.source()
            }))
        };
    };
}

export default withTranslation()(withStores('fileUploadUiState')(AddFilesToProjectModal));
