import { action, observable } from 'mobx';
import React from 'react';
import { EditorShortcut, Macro, VideoShortcut } from '../graphql/types';
import TranscriberStore from '../stores/TranscriberStore';

export enum ShortcutSection {
    PLAYER,
    EDITOR,
    MACROS
}

export default class ShortcutsModalUiState {
    @observable activeSection: ShortcutSection = ShortcutSection.PLAYER;
    constructor(private transcriberStore: TranscriberStore) {}

    @action setActiveSection = (section: ShortcutSection) => (this.activeSection = section);

    modifyMacros = (macros: Macro[]) => this.transcriberStore.modifyMacros({ macros });

    modifyEditorShortcuts = (shortcuts: EditorShortcut[]) => this.transcriberStore.modifyEditorShortcuts({ shortcuts });

    modifyVideoPlayerShortcuts = (shortcuts: VideoShortcut[]) =>
        this.transcriberStore.modifyVideoPlayerShortcuts({ shortcuts });

    handleKeys = (e: React.KeyboardEvent) => {
        const activeKeys = new Set();
        if (e.altKey) {
            activeKeys.add('AltLeft');
        }
        if (e.ctrlKey) {
            activeKeys.add('ControlLeft');
        }
        if (e.shiftKey) {
            activeKeys.add('ShiftLeft');
        }
        activeKeys.add(e.nativeEvent.code);
        if (e.key === 'Backspace') {
            activeKeys.clear();
        }
        return activeKeys;
    };
}
