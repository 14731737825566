import React, { Component } from 'react';
import srt from '../../../assets/images/srt.png';
import word from '../../../assets/images/word.png';
import avid from '../../../assets/images/avid.png';
import { ExportFormat } from '../../../graphql/types';
import Radio from '../radio/Radio';
import './ExportFormatFields.scss';

type ExportFormatFieldsProps = {
    selectedFileType: ExportFormat | null;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    allowedFormats?: Array<{ format: ExportFormat; disabledMessage?: string }>;
};

class ExportFormatFields extends Component<ExportFormatFieldsProps> {
    getAllowedFormats() {
        const { allowedFormats } = this.props;

        return [
            {
                format: ExportFormat.Docx,
                image: word
            },
            {
                format: ExportFormat.Avid,
                label: 'AVID - DS',
                image: avid
            },
            {
                format: ExportFormat.Srt,
                image: srt
            },
            {
                format: ExportFormat.Txt,
                image: srt
            }
        ]
            .filter(x => {
                return !allowedFormats || allowedFormats.find(y => y.format === x.format);
            })
            .map(x => {
                const exportFormatConfig = allowedFormats && allowedFormats.find(y => y.format === x.format);
                return {
                    ...x,
                    disabledMessage: (exportFormatConfig && exportFormatConfig.disabledMessage) || ''
                };
            });
    }

    render() {
        const { selectedFileType, onChange } = this.props;
        return (
            <div className="radio-group export-format-fields">
                {this.getAllowedFormats().map(x => (
                    <Radio
                        key={x.format}
                        isDisabled={!!x.disabledMessage}
                        onChange={onChange}
                        label={
                            <>
                                <img src={x.image} alt="" />
                                {x.label || x.format.toUpperCase()}
                                {x.disabledMessage && <span className="small mr-2">({x.disabledMessage})</span>}
                            </>
                        }
                        value={x.format}
                        checked={selectedFileType === x.format}
                    />
                ))}
            </div>
        );
    }
}

export default ExportFormatFields;
