import React from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { EntityLocation } from '../types';
import { observer } from 'mobx-react';
import withStores from '../../hoc/withStores';
import Tooltip from '../../../modules/common/tooltip/Tooltip';
import './TagSpan.scss';
import ReactTooltip from 'react-tooltip';
import TagUiState from '../../../ui-states/TagUiState';
import tinyColor from 'tinycolor2';
import { Tag } from '../../../graphql/types';

type TagSpanProps = {
    tagId: string;
    tagEntity: EntityLocation;
    tagUiState: TagUiState;
    readonly?: boolean;
};
const TagSpan: React.FC<TagSpanProps> = ({ tagId, tagEntity, tagUiState, readonly, ...props }) => {
    const { allTags, removeTag } = tagUiState;
    const tag = allTags.find((x: Tag) => x.id === tagId);

    if (!tag) {
        return <span>{props.children}</span>;
    }

    return (
        <ContextMenuTrigger
            renderTag="span"
            id={`context-menu-tag-popup`}
            collect={() => {
                return {
                    tag,
                    tagEntity
                };
            }}>
            {
                // rebindAllTooltipsOnMount canceled here for performance reason
                <Tooltip content={tag!.name} rebindAllTooltipsOnMount={false}>
                    <span
                        className="tag-hover"
                        style={{
                            backgroundColor: tag!.color,
                            color: tinyColor(tag!.color).getLuminance() < 0.5 ? 'white' : 'black'
                        }}
                        data-tip={tag!.name}>
                        {props.children}
                    </span>
                    {!readonly && (
                        <div
                            className="remove-tag-button"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                ReactTooltip.hide();
                                return tagEntity && removeTag(tagEntity);
                            }}>
                            <span className="remove-tag-x">X</span>
                        </div>
                    )}
                </Tooltip>
            }
        </ContextMenuTrigger>
    );
};

export default withStores('tagUiState')(observer(TagSpan));
