import { gql } from 'apollo-boost';
import { transcriptFragment } from '../fragments/transcriptFragment';

export const transcriptQuery = gql`
    query transcript($id: ID!) {
        transcript(id: $id) {
            ...transcript
        }
    }
    ${transcriptFragment}
`;
