import React, { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import './CommonTable.scss';
import { range } from 'lodash';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type Column = {
    name: string;
    className?: string;
    render: (item: any) => JSX.Element | string;
};
type CommonTableProps = {
    columns: Column[];
    rows: any[];
    paginate?: boolean;
    paginationSize?: number;
    rowClassSelector?: (item: any) => string;
    dataTestId?: string;
};
const CommonTable = ({
    columns,
    rows,
    rowClassSelector,
    dataTestId,
    paginationSize = 25,
    paginate = false
}: CommonTableProps) => {
    const [currentPage, setPage] = useState(1);
    const pagesAmount = Math.ceil(rows.length / paginationSize);
    const visibleRows = !paginate
        ? rows
        : rows.slice(currentPage * paginationSize - paginationSize, currentPage * paginationSize);

    return (
        <>
            <Table borderless={true} responsive={true} size="sm" className="common-table">
                <thead>
                    <tr>
                        {columns.map((column, i) => (
                            <th key={i} className={column.className}>
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {visibleRows.map((row, index) => {
                        return (
                            <tr
                                key={index}
                                className={rowClassSelector && rowClassSelector(row)}
                                data-testid={dataTestId ? dataTestId : `row-${row.id}`}>
                                {columns.map((column, i) => {
                                    return (
                                        <td className={column.className} key={i}>
                                            {column.render(row) ? column.render(row) : '-'}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {paginate && (
                <Pagination className="pagination-table">
                    <PaginationItem disabled={currentPage === 1} onClick={() => setPage(1)}>
                        <PaginationLink className="pagination-item">
                            <FontAwesomeIcon icon={faForward} />
                        </PaginationLink>
                    </PaginationItem>

                    {range(1, pagesAmount + 1).map(index => (
                        <PaginationItem disabled={currentPage === index} onClick={() => setPage(index)} key={index}>
                            <PaginationLink className="pagination-item">{index}</PaginationLink>
                        </PaginationItem>
                    ))}

                    <PaginationItem disabled={currentPage === pagesAmount} onClick={() => setPage(pagesAmount)}>
                        <PaginationLink className="pagination-item">
                            <FontAwesomeIcon icon={faBackward} />
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            )}
        </>
    );
};

export default CommonTable;
