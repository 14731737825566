import { gql } from 'apollo-boost';

export const exportJobStatusQuery = gql`
    query exportJobStatus($jobId: ID!) {
        exportJobStatus(id: $jobId) {
            id
            resultUrl
            status
        }
    }
`;
