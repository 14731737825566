import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import { truncateByLength } from '../../../../framework/utils/StringUtils';
import SearchUiState from '../../../../ui-states/SearchUiState';
import DatePicker from '../../../common/date-picker/DatePicker';
import MultiSelectDropDown from '../../../common/multi-select-dropdown/MultiSelectDropDown';
import SearchDropDown from '../../../common/search-dropdown/SearchDropDown';
import SubmitButton from '../../../common/submit-button/SubmitButton';
import SearchInput from './search-input/SearchInput';
import './SearchFields.scss';
import withStores from '../../../../framework/hoc/withStores';
import { Observer } from 'mobx-react';
import { Project } from '../../../../graphql/types';
import SearchFieldsUiState from '../../../../ui-states/SearchFieldsUiState';

type InjectedProps = {
    searchUiState: SearchUiState;
    searchFieldsUiState: SearchFieldsUiState;
    projects: Project[];
};
type SearchFieldsProps = WithTranslation & InjectedProps;

const SearchFields: React.FC<SearchFieldsProps> = ({ searchFieldsUiState, searchUiState, projects, t }) => {
    const [shouldShowLastSearches, setShouldShowLastSearches] = useState(false);

    return (
        <Observer>
            {() => {
                // destructured here so the observer will catch it
                const {
                    setProject,
                    setFile,
                    setPickedSpeakers,
                    setDates,
                    setPickedTags,
                    reset,
                    setSearchTerm,
                    searchTerm,
                    pickedProject,
                    pickedFiles,
                    pickedSpeakers,
                    speakers,
                    files,
                    isLoadingTags,
                    isLoadingSpeakers,
                    isLoadingFiles
                } = searchFieldsUiState;

                const { lastSearches, search } = searchUiState;
                return (
                    <div className="title search-fields">
                        <h1>{t('search')}</h1>
                        <div className="dropdowns">
                            <form onClick={e => e.preventDefault()}>
                                <SearchInput
                                    onClick={() => {
                                        setShouldShowLastSearches(true);
                                    }}
                                    onResultClick={searchResult => {
                                        setShouldShowLastSearches(false);
                                        setSearchTerm(searchResult);
                                    }}
                                    hideLastSearches={() => {
                                        setShouldShowLastSearches(false);
                                    }}
                                    onChange={setSearchTerm}
                                    searchTerm={searchTerm}
                                    lastSearches={lastSearches}
                                    shouldShowDropdown={shouldShowLastSearches}
                                />
                                <SearchDropDown
                                    label={t('project') as string}
                                    options={projects.map(project => ({
                                        label: project.name,
                                        id: project.id
                                    }))}
                                    onClick={pickedItem => setProject(pickedItem)}
                                    pickedItem={pickedProject && pickedProject.label}
                                    className="projects-dropdown"
                                />
                                <MultiSelectDropDown
                                    className="files-dropdown"
                                    label={t('files')}
                                    options={files.map(file => ({
                                        label: truncateByLength(file.mediaFileName, 40),
                                        id: file.id
                                    }))}
                                    onChange={value => setFile(value)}
                                    disabled={!pickedProject}
                                    values={pickedFiles}
                                    isLoading={isLoadingFiles}
                                />
                                <MultiSelectDropDown
                                    label={t('speaker')}
                                    options={speakers.map(speaker => ({
                                        label: truncateByLength(speaker.name, 40),
                                        id: speaker.id!
                                    }))}
                                    onChange={value => setPickedSpeakers(value)}
                                    disabled={!pickedProject}
                                    values={pickedSpeakers}
                                    isLoading={isLoadingSpeakers}
                                />
                                {pickedProject ? (
                                    <DatePicker
                                        onChange={(dates: Date[]) => setDates(dates[0], dates[1])}
                                        label={t('recording date')}
                                    />
                                ) : (
                                    <Input
                                        className="placeholder-disabled-input"
                                        disabled={true}
                                        placeholder={t('recording date')}
                                    />
                                )}
                                <MultiSelectDropDown
                                    label={t('tag')}
                                    options={searchFieldsUiState.tags.map(tag => ({
                                        label: truncateByLength(tag.name, 40),
                                        id: tag.id
                                    }))}
                                    onChange={value => setPickedTags(value)}
                                    values={searchFieldsUiState.pickedTags}
                                    className="tags-dropdown"
                                    isLoading={isLoadingTags}
                                />
                                <SubmitButton
                                    onClick={() => {
                                        setShouldShowLastSearches(false);
                                        search();
                                    }}>
                                    {t('search')}
                                </SubmitButton>
                                <div onClick={reset} className="eraser">
                                    {t('clear fields')}
                                </div>
                            </form>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default withTranslation()(withStores('searchUiState', 'searchFieldsUiState')(SearchFields));
