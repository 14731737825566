import { gql } from 'apollo-boost';
import { speakerFragment } from '../../common/fragments/speakerFragment';

export const refreshSpeakers = gql`
    query file($id: ID!) {
        file(id: $id) {
            id
            speakers {
                ...speaker
            }
        }
    }
    ${speakerFragment}
`;
