import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Switch from 'react-switch';
import './Notifications.scss';
import useSubmitLifeCycle from '../../../../framework/hooks/useSubmitLifeCycle';
import InlineSpinner from '../../../common/loading-spinner/InlineSpinner';

type NotificationsProps = {
    isEmailNotificationOn: boolean;
    onToggle(): void;
};

const Notifications = ({ isEmailNotificationOn, onToggle, t }: WithTranslation & NotificationsProps) => {
    const { isSubmitting, submitAction, shouldShowSpinner } = useSubmitLifeCycle(onToggle);
    return (
        <div className="notifications">
            <div className="notifications-box">
                {shouldShowSpinner && <InlineSpinner size="tiny" />}
                <span className="description">{t('notification will be sent to email')}</span>
                <label htmlFor="email-notification">
                    <span>{t('get notification via email')}</span>

                    <Switch
                        disabled={isSubmitting}
                        onChange={() => submitAction && submitAction()}
                        checked={isEmailNotificationOn}
                        id="email-notification"
                        uncheckedIcon={false}
                        checkedIcon={false}
                    />
                </label>
            </div>
        </div>
    );
};

export default withTranslation()(Notifications);
