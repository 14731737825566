import React, { useEffect } from 'react';
import { truncateByLength } from '../../../framework/utils/StringUtils';
import ReactTooltip from 'react-tooltip';

type Props = {
    maxLength: number;
    content: string;
    component?: React.ComponentType | string;
    className?: string;
};

const LimitedText = ({ maxLength, content, className, component: Component = 'span' }: Props) => {
    // useEffect(() => ReactTooltip.rebuild(), []);
    return (
        <Component data-tip={content.length > maxLength ? content : null} className={className}>
            {truncateByLength(content, maxLength)}
        </Component>
    );
};

export default LimitedText;
