import { useEffect, useRef, useState } from 'react';
import { runIfNotFinishedWithin } from '../promises';

export default (promiseCreator: (() => Promise<any>) | (() => void) | null) => {
    const isMounted = useRef(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPerformingAction, setIsPerformingAction] = useState(false);
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const submitAction = !promiseCreator
        ? undefined
        : async () => {
              try {
                  setIsSubmitting(true);
                  const promise = Promise.resolve(promiseCreator());
                  await runIfNotFinishedWithin(promise, 200, () => {
                      setIsPerformingAction(true);
                  });
              } finally {
                  if (isMounted.current) {
                      setIsSubmitting(false);
                      setIsPerformingAction(false);
                  }
              }
          };

    return { submitAction, isSubmitting, shouldShowSpinner: isPerformingAction };
};
