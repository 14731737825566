import { gql } from 'apollo-boost';
import { speakerFragment } from '../../common/fragments/speakerFragment';

export const projectSpeakersQuery = gql`
    query projectSpeakers($id: ID!) {
        projectSpeakers(id: $id) {
            ...speaker
        }
    }
    ${speakerFragment}
`;

export const projectSpeakerNamesQuery = gql`
    query projectSpeakerNames($id: ID!) {
        projectSpeakers(id: $id) {
            id
            name
        }
    }
`;
