import { DraftHandleValue, getDefaultKeyBinding } from 'draft-js';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import React from 'react';
import { isSetsEqual } from '../framework/utils/setUtils';
import { EditorShortcutType, Speaker } from '../graphql/types';
import ParagraphModel from '../models/ParagraphModel';
import { ModalService } from '../services/ModalService';
import FileStore from '../stores/FileStore';
import SpeakerStore from '../stores/SpeakerStore';
import TranscriptStore from '../stores/TranscriptStore';
import UserStore from '../stores/UserStore';
import { toast } from 'react-toastify';
import i18n from '../localization/i18n';
import TranscriptModel, { TranscriptSyncStatus } from '../models/TranscriptModel';
import { TranscriptSaver } from '../stores/TranscriptSaver';
import TagUiState from './TagUiState';
import WorkLoggingService from '../services/WorkLoggingService';
import TimeUtils from '../framework/utils/TimeUtils';
import BlockModel from '../models/BlockModel';
import TranscriptVideoSyncer from './TranscriptVideoSyncer';

const ExtendedEditorShortcutType = {
    ...EditorShortcutType,
    Delete: 'DELETE',
    SetSpeaker: 'SET_SPEAKER',
    MoveToNewBlock: 'MOVE_TO_NEW_BLOCK'
};

export default class TranscriptUiState {
    @observable selectedBlocks: string[] = [];
    @observable selectedFileId: string | null = null;
    @observable public transcript: TranscriptModel | null = null;
    private readonly transcriptSaver: TranscriptSaver;
    private readonly transcriptVideoSyncer: TranscriptVideoSyncer;

    private lastSetSpeakerCommand: Speaker | null = null;

    constructor(
        private fileStore: FileStore,
        private userStore: UserStore,
        private modalService: ModalService,
        private speakerStore: SpeakerStore,
        private tagUiState: TagUiState,
        private transcriptStore: TranscriptStore,
        private workLoggingService: WorkLoggingService
    ) {
        this.transcriptSaver = new TranscriptSaver(this.saveTranscript, () =>
            this.modalService.openRefreshTranscriptModal(this.reload)
        );

        this.transcriptVideoSyncer = new TranscriptVideoSyncer();

        // this.transcript is kept up to date using a reaction and not a computed, as we can't create a new observable inside a computed
        reaction(
            () => this.transcriptStore.transcript && this.transcriptStore.transcript.id,
            transcriptId => {
                if (!transcriptId) {
                    return;
                }

                return this.buildTranscriptViewModel();
            },
            { name: 'file changed' }
        );
    }

    @action
    private async buildTranscriptViewModel() {
        this.transcriptSaver.dispose();
        this.transcriptVideoSyncer.dispose();
        this.transcript = new TranscriptModel(
            () => this.transcriptStore.transcript!,
            this.tagUiState.allTags,
            TimeUtils.turnDurationStringToSeconds(this.file.mediaFileDuration!)
        );
        if (this.userStore.isCustomer) {
            await this.tagUiState.init(this.transcript);
        }
        this.transcriptSaver.start(() => this.transcript);
        this.transcriptVideoSyncer.start(this.transcript);
    }

    @computed
    get file() {
        return this.fileStore.currentFile!;
    }

    actions: { [index: string]: () => void } = {
        [ExtendedEditorShortcutType.InsertNewBlock]: () => {
            if (!this.transcript!.insertNewBlock()) {
                toast.info(i18n.t('max number of blocks reached'), { position: 'bottom-right' });
            }
        },
        [ExtendedEditorShortcutType.InsertNewParagraph]: () => this.transcript!.insertNewParagraph(),
        [ExtendedEditorShortcutType.Delete]: () => this.transcript!.removeParagraph(),
        [ExtendedEditorShortcutType.SetSpeaker]: () => this.setSpeaker(),
        [ExtendedEditorShortcutType.MoveToNewBlock]: () => this.tryMoveToNextParagraph()
    };

    @action
    async initialize(id: string) {
        this.selectedFileId = id;
        await this.fileStore.fetchFile(id);
        // not parallel anymore, lets not try load the transcript if file is not found
        await this.transcriptStore.init(id);

        // transcript will not automatically be created if the page is hot loaded
        if (!this.transcript) {
            return this.buildTranscriptViewModel();
        }
    }

    @action
    reset() {
        this.transcriptSaver.dispose();
        this.transcriptVideoSyncer.dispose();
        this.transcript = null;
    }

    @action setTimeCode = async (seconds: number) => {
        this.transcript!.syncStatus = TranscriptSyncStatus.IN_PROGRESS;
        await this.transcriptStore.setTranscriptInterval({
            interval: seconds,
            transcriptId: this.transcript!.id
        });
        runInAction(() => {
            this.transcript!.syncStatus = TranscriptSyncStatus.SYNCED;
        });
    };
    @action tryMoveToNextParagraph = () => this.transcript!.tryMoveToNextParagraph();
    @action tryMoveToNextBlock = () => this.transcript!.tryMoveToNextBlock();
    @action tryMoveToPreviousParagraph = () => this.transcript!.tryMoveToPreviousParagraph();
    @action replaceWithMacros = (): DraftHandleValue => {
        const { isCustomer, macros } = this.userStore;

        if (isCustomer) {
            return 'handled';
        }

        this.transcript!.replaceWithMacros(macros);

        return 'not-handled';
    };
    @action handleEditorShortcuts = (command: string): DraftHandleValue => {
        if (this.userStore.isCustomer) {
            return 'not-handled';
        }
        if (this.actions[command]) {
            this.actions[command]();
            return 'handled';
        }
        return 'not-handled';
    };
    @action setActiveParagraph = (activeParagraph: ParagraphModel) => {
        return this.transcript!.setActiveParagraph(activeParagraph);
    };

    @action toggleSelectedBlocks = (blockId: string) => {
        const resultIndex = this.selectedBlocks.findIndex(x => x === blockId);
        if (resultIndex !== -1) {
            return this.selectedBlocks.splice(resultIndex, 1);
        }
        return this.selectedBlocks.push(blockId);
    };
    @action moveCursorToBlock = (blockNumber: number) => {
        return this.transcript!.moveCursorToBlock(blockNumber);
    };

    @action syncTranscriptUptoBlockNumber(
        blockNumber: number,
        isPairingCursorToVideoTime: boolean,
        createBlocksAutomatically: boolean
    ) {
        this.transcriptVideoSyncer.syncTranscriptUptoBlockNumber(
            blockNumber,
            isPairingCursorToVideoTime,
            createBlocksAutomatically
        );
    }

    handleKeyBinding = (event: React.KeyboardEvent<{}>) => {
        if (this.userStore.isCustomer) {
            return 'customer-handle';
        }

        const activeKeys = new Set<string>();
        if (event.altKey) {
            activeKeys.add('AltLeft');
        }
        if (event.ctrlKey) {
            activeKeys.add('ControlLeft');
        }
        if (event.shiftKey) {
            activeKeys.add('ShiftLeft');
        }
        activeKeys.add(event.nativeEvent.code);

        const { editorShortcuts } = this.userStore;
        for (const shortcut of editorShortcuts) {
            if (isSetsEqual(new Set(shortcut.keys), activeKeys)) {
                return shortcut.shortcutType;
            }
        }

        const { speakers } = this.file;
        for (const speaker of speakers) {
            if (isSetsEqual(new Set(speaker.shortcut), activeKeys)) {
                this.lastSetSpeakerCommand = speaker;
                return ExtendedEditorShortcutType.SetSpeaker;
            }
        }
        if (event.key === 'Backspace') {
            if (this.transcript!.activeParagraph.isEmpty) {
                return ExtendedEditorShortcutType.Delete;
            }
        }

        if (activeKeys.has('Space') && this.transcriptVideoSyncer.shouldMoveToNewBlock) {
            return ExtendedEditorShortcutType.MoveToNewBlock;
        }

        return getDefaultKeyBinding(event);
    };

    getProjectSpeakers = () => {
        return this.speakerStore.getSpeakersByProjectId(this.file.project.id);
    };

    submitModifySpeakers = async (speakers: Speaker[]) => {
        const speakersInput = speakers.map(speaker => ({
            id: speaker.id ? speaker.id : null,
            name: speaker.name,
            shortcut: speaker.shortcut
        }));

        const speakersToBeRemoved = this.file.speakers.filter(x => !speakers.some(y => x.id === y.id));

        await this.speakerStore.modifySpeakers(
            { speakers: speakersInput, transcriptId: this.transcript!.id, fileId: this.file!.id }!
        );
        speakersToBeRemoved.forEach(speaker => {
            this.transcript!.removeSpeaker(speaker.id!);
        });
    };

    openSubmitForApprovalModal = () => {
        return this.modalService.openSubmitForApprovalModal(this.file!.id);
    };
    openApproveFileModal = () => {
        return this.modalService.openApproveFileModal(this.file!.id);
    };
    openShortcutsModal = () => {
        return this.modalService.openShortcutsModal();
    };
    openExportSelectedBlocksModal = () => {
        return this.modalService.exportSelectedBlocks(this.file!.id, this.selectedBlocks);
    };
    openExportTagsModal = () => {
        return this.modalService.exportTags(this.file!.id);
    };
    openExportTranscriptModal = () => {
        return this.modalService.exportTranscript([this.file!.id]);
    };
    openModifyBlockOffsetModal = (block: BlockModel) => {
        return this.modalService.openModifyBlockOffsetModal(block);
    };

    openConvertTagsModal = () => {
        return this.modalService.convertToTags(
            this.selectedBlocks.map(x => ({ blockId: x, fileId: this.file!.id })),
            this.reload
        );
    };

    sendTranscriptForRepair = async () => this.modalService.sendTranscriptionForRepairModal(this.file!);

    @action
    private setSpeaker() {
        if (this.lastSetSpeakerCommand) {
            this.transcript!.activeParagraph.setSpeaker(this.lastSetSpeakerCommand.id);
            this.lastSetSpeakerCommand = null;
        }
    }

    @action
    private saveTranscript = () => {
        const transcriptModel = this.transcript!;
        this.workLoggingService.logWorkOnTranscript(transcriptModel.id);
        return this.transcriptStore.saveTranscript(transcriptModel);
    };

    private reload = async () => {
        await this.transcriptStore.init(this.transcript!.id);
        return this.buildTranscriptViewModel();
    };
}
