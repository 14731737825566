import * as React from 'react';
import { Redirect, Switch } from 'react-router';
import TranscriberPage from './TranscriberPage';
import CustomerPage from './CustomerPage';
import ModalManager from './common/modal/ModalManager';
import { useTranslation } from 'react-i18next';
import withStores from '../framework/hoc/withStores';
import UserStore from '../stores/UserStore';
import { ProtectedRoute } from './common/Routes';
import { hot } from 'react-hot-loader';
import AdminPage from './AdminPage';
import env from '../env';
import classNames from 'classnames';
import { UserRole } from '../graphql/types';
import * as Sentry from '@sentry/browser';

const UnknownError = () => {
    const { t } = useTranslation();
    return (
        <div
            className={classNames('fatal-error d-flex flex-fill align-items-center justify-content-center', {
                dev: env.DEV_MODE
            })}>
            <h1>{t('unknown error has occurred')}</h1>
        </div>
    );
};

class AppRoot extends React.Component<{ userStore: UserStore }> {
    state = {
        showingError: false
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error('componentDidCatch', error, errorInfo);
        Sentry.withScope((scope: Sentry.Scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });

        this.setState({ showingError: true });
    }

    render(): React.ReactNode {
        const { userStore } = this.props;
        const { showingError } = this.state;

        return (
            <div className="dashboard-page d-flex flex-column flex-fill" data-testid="dashboard-page">
                <ModalManager />

                {showingError ? (
                    <UnknownError />
                ) : (
                    <Switch>
                        <ProtectedRoute
                            path="/transcriber"
                            component={TranscriberPage}
                            predicate={() => !userStore.isCustomer}
                        />
                        <ProtectedRoute
                            path="/customer"
                            component={CustomerPage}
                            predicate={() => userStore.isCustomer}
                        />
                        <ProtectedRoute path="/admin" component={AdminPage} predicate={() => true} />
                        <Redirect
                            to={
                                userStore.userRole === UserRole.Customer
                                    ? '/customer'
                                    : userStore.userRole === UserRole.Transcriber
                                    ? '/transcriber'
                                    : userStore.userRole === UserRole.Admin
                                    ? '/admin'
                                    : '/login'
                            }
                        />
                    </Switch>
                )}
            </div>
        );
    }
}

export default hot(module)(withStores('userStore')(AppRoot));
