import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { WithTranslation, withTranslation } from 'react-i18next';
import { truncateByLength } from '../../../../framework/utils/StringUtils';
import { Speaker } from '../../../../graphql/types';

type SpeakerDropDownState = {
    isDropDownOpen: boolean;
};
type SpeakerDropDownProps = {
    speakers: Speaker[];
    onClick: (speaker: Speaker) => void;
};

class SpeakerDropDown extends Component<SpeakerDropDownProps & WithTranslation, SpeakerDropDownState> {
    state = {
        isDropDownOpen: false
    };
    render() {
        const { isDropDownOpen } = this.state;
        const { speakers, onClick, t } = this.props;
        return (
            <Dropdown
                isOpen={isDropDownOpen}
                toggle={() =>
                    this.setState(prevState => ({
                        isDropDownOpen: !prevState.isDropDownOpen
                    }))
                }>
                <DropdownToggle className="cursor-pointer" tag="a" caret>
                    {t('choose speaker')}&nbsp;
                </DropdownToggle>
                <DropdownMenu>
                    {speakers.map((speaker, index) => (
                        <DropdownItem key={index} onClick={() => onClick(speaker)}>
                            {truncateByLength(speaker.name, 15)}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default withTranslation()(SpeakerDropDown);
