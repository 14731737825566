import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { EditorShortcutType, Transcriber } from '../../../../graphql/types';
import '../Tab.scss';
import ShortcutForm, { Shortcut } from './ShortcutForm';

type EditorShortcutTabProps = {
    transcriber: Transcriber;
    onHide(): void;
    onSubmit(shortcuts: Shortcut[]): void;
    getActiveKeys(e: React.KeyboardEvent): Set<string>;
};

class EditorShortcutTab extends Component<EditorShortcutTabProps & WithTranslation> {
    render() {
        const { transcriber, onHide, onSubmit, getActiveKeys, t } = this.props;

        return (
            <div data-testid="spelling-tab" className="tab">
                <ShortcutForm
                    editorShortcuts={transcriber.editorShortcuts}
                    getActiveKeys={getActiveKeys}
                    onCancel={onHide}
                    onSubmit={async shortcuts => {
                        await onSubmit(shortcuts);
                        await onHide();
                    }}
                    shortcutTypes={Object.keys(EditorShortcutType).map(key => ({
                        value: EditorShortcutType[(key as unknown) as number],
                        label: t(EditorShortcutType[(key as unknown) as number])
                    }))}
                />
            </div>
        );
    }
}

export default withTranslation()(EditorShortcutTab);
