import { gql } from 'apollo-boost';
import { userFragment } from '../fragments/userFragment';

export const currentUserQuery = gql`
    query currentUser {
        currentUser {
            ...user
        }
    }
    ${userFragment}
`;
