import { ApolloClient } from 'apollo-client';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import configureClient from './graphql/configureClient';
import { AuthService } from './services/AuthService';
import { ModalService } from './services/ModalService';
import ShortcutService from './services/ShortcutService';
import FileStore from './stores/FileStore';
import NotificationStore from './stores/NotificationStore';
import ProjectStore from './stores/ProjectStore';
import SearchStore from './stores/SearchStore';
import SpeakerStore from './stores/SpeakerStore';
import TagStore from './stores/TagStore';
import TranscriberStore from './stores/TranscriberStore';
import TranscriptStore from './stores/TranscriptStore';
import UserStore from './stores/UserStore';
import AppUiState from './ui-states/AppUiState';
import FilePageUiState from './ui-states/FilePageUiState';
import FileUploadUiState from './ui-states/FileUploadUiState';
import HistoryUiState from './ui-states/HistoryUiState';
import LoginUiState from './ui-states/LoginUiState';
import ModalManagerUiState from './ui-states/modalManagerUiState';
import SearchUiState from './ui-states/SearchUiState';
import ShortcutsModalUiState from './ui-states/ShortcutsModalUiState';
import TranscriptUiState from './ui-states/TranscriptUiState';
import UserUiState from './ui-states/UserUiState';
import VideoPlayerUiState from './ui-states/VideoPlayerUiState';
import TagUiState from './ui-states/TagUiState';
import { createBrowserHistory } from 'history';
import SearchFieldsUiState from './ui-states/SearchFieldsUiState';
import WorkLoggingService from './services/WorkLoggingService';

const bootstrap = (
    graphqlClient?: ApolloClient<any>,
    factories: { [index: string]: any } = {},
    history = createBrowserHistory()
) => {
    const { userStoreFactory } = factories;
    const client: ApolloClient<any> = graphqlClient
        ? graphqlClient
        : configureClient({
              onUnauthorizedHandler: () => {
                  // log out on error, only if logged in
                  if (appUiState.apiToken) {
                      authService.logout(true);
                  } else {
                      appUiState.showTranslatedToast('authorization error');
                  }
              },
              setErrorMessage: error => appUiState.showError(error)
          });

    const userStore = userStoreFactory ? userStoreFactory({ client }) : new UserStore(client);

    const transcriberStore = new TranscriberStore(client);

    const notificationStore = new NotificationStore(client);

    const routerStore = new RouterStore();
    syncHistoryWithStore(history, routerStore);

    const modalManagerUiState = new ModalManagerUiState();

    const appUiState = new AppUiState(userStore, routerStore);

    const tagStore = new TagStore(client);

    const fileStore = new FileStore(client);

    const transcriptStore = new TranscriptStore(client, tagStore, userStore);

    const projectStore = new ProjectStore(client);

    const searchStore = new SearchStore(client);

    const shortcutsModalUiState = new ShortcutsModalUiState(transcriberStore);

    const fileUploadUiState = new FileUploadUiState(fileStore, projectStore);

    const userUiState = new UserUiState();

    const workLoggingService = new WorkLoggingService(client, userStore);

    const modalService = new ModalService(
        modalManagerUiState,
        fileStore,
        userStore,
        searchStore,
        tagStore,
        routerStore,
        userUiState,
        transcriptStore
    );
    const speakerStore = new SpeakerStore(client);

    const tagUiState = new TagUiState(tagStore);

    const transcriptUiState = new TranscriptUiState(
        fileStore,
        userStore,
        modalService,
        speakerStore,
        tagUiState,
        transcriptStore,
        workLoggingService
    );

    const filePageUiState = new FilePageUiState(fileStore);

    const historyUiState = new HistoryUiState(fileStore, userStore);

    const searchFieldsUiState = new SearchFieldsUiState(fileStore, speakerStore, projectStore);

    const searchUiState = new SearchUiState(
        projectStore,
        fileStore,
        speakerStore,
        tagUiState,
        searchStore,
        modalService,
        transcriptStore,
        searchFieldsUiState
    );

    const videoPlayerUiState = new VideoPlayerUiState(
        fileStore,
        transcriptUiState,
        searchUiState,
        userStore,
        modalService,
        workLoggingService
    );
    const shortcutService = new ShortcutService(modalService, userStore, videoPlayerUiState);

    const authService = new AuthService(appUiState, routerStore, userStore, client);

    const loginUiState = new LoginUiState(authService);

    return {
        history,
        appUiState,
        userStore,
        client,
        authService,
        routerStore,
        tagStore,
        fileStore,
        transcriptStore,
        transcriptUiState,
        modalManagerUiState,
        modalService,
        historyUiState,
        searchStore,
        searchUiState,
        projectStore,
        notificationStore,
        userUiState,
        fileUploadUiState,
        loginUiState,
        filePageUiState,
        speakerStore,
        shortcutsModalUiState,
        shortcutService,
        transcriberStore,
        videoPlayerUiState,
        tagUiState,
        searchFieldsUiState
    };
};

export type StoresType = ReturnType<typeof bootstrap>;

export default bootstrap;
