import { gql } from 'apollo-boost';

export const slimProjectFragment = gql`
    fragment project on Project {
        id
        name
        category
        numOfFiles
        numOfInProgressFiles
        numOfCompletedFiles
        created
        dueDate
        status
    }
`;
