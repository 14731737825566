import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { isEmpty } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import FileStore from '../stores/FileStore';
import ProjectStore from '../stores/ProjectStore';

export enum Status {
    COMPLETED,
    IN_PROGRESS,
    NOT_STARTED
}

export type UploadFileStatus = {
    name: string;
    uploadPercent: number;
    file: File;
    status: Status;
    cancelToken: CancelTokenSource;
};

export type ProjectUploadFiles = {
    projectName: string;
    projectId: string;
    files: UploadFileStatus[];
};

export default class FileUploadUiState {
    @observable projectsFilesStatus: ProjectUploadFiles[] = [];
    @observable isUploading: boolean = false;
    @observable currentUploadedId: string | null = null;
    constructor(private fileStore: FileStore, private projectStore: ProjectStore) {}

    getDueDate = async (projectId: string) => {
        return await this.projectStore.getDueDate({ projectId });
    };

    @action addToFiles = async (projectFiles: ProjectUploadFiles) => {
        this.addToList(projectFiles);
        if (!this.isUploading) {
            await this.uploadAllFiles();
        }
    };

    @action clearFiles = () => {
        this.projectsFilesStatus.forEach(project =>
            project.files.forEach(file => {
                if (file.status !== Status.COMPLETED) {
                    this.fileStore.cancelUploadFiles(file.cancelToken);
                }
            })
        );
        this.isUploading = false;
        this.projectsFilesStatus = [];
    };

    @action
    cancelFile = (projectId: string, index: number) => {
        const project = this.projectsFilesStatus.find(project => project.projectId === projectId);
        const file = project!.files[index];
        if (file.status === Status.IN_PROGRESS) {
            this.fileStore.cancelUploadFiles(file.cancelToken);
        }
        project!.files.splice(index, 1);
        if (isEmpty(this.projectsFilesStatus.flatMap(filesStatus => filesStatus.files))) {
            runInAction(() => (this.isUploading = false));
        }
    };

    @action
    private uploadFile = async (fileStatus: UploadFileStatus, projectId: string) => {
        fileStatus.status = Status.IN_PROGRESS;
        const { url, uploadFields } = await this.fileStore.generateFileUploadUrl({
            fileName: fileStatus.file.name,
            fileType: fileStatus.file.type
        });
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': fileStatus.file.type
            },
            onUploadProgress: (progressEvent: any) => {
                runInAction(() => (fileStatus.uploadPercent = (progressEvent.loaded / progressEvent.total) * 100));
            },
            cancelToken: fileStatus.cancelToken.token
        };
        const parsedUploadFields = JSON.parse(uploadFields);
        const isSuccess = await this.fileStore.uploadToS3(url, fileStatus, parsedUploadFields, config);
        if (isSuccess) {
            await this.fileStore.addFile({
                mediaFileUrl: parsedUploadFields.key,
                projectId,
                mediaFileName: fileStatus.file.name
            });
        }
        runInAction(() => (fileStatus.status = Status.COMPLETED));
    };

    private addToList = (projectFiles: ProjectUploadFiles) => {
        const project = this.projectsFilesStatus.find(project => project.projectId === projectFiles.projectId);
        if (!!project) {
            return project.files.push(...projectFiles.files);
        }
        this.projectsFilesStatus = [...this.projectsFilesStatus, projectFiles];
    };

    private uploadAllFiles = async () => {
        runInAction(() => (this.isUploading = true));
        let nextProject;
        do {
            nextProject = this.projectsFilesStatus.find(x => !!x.files.find(x => x.status === Status.NOT_STARTED));
            if (nextProject) {
                const nextFile = nextProject.files.find(x => x.status === Status.NOT_STARTED);
                await this.uploadFile(nextFile!, nextProject.projectId);
            }
        } while (!!nextProject);
        runInAction(() => (this.isUploading = false));
    };
}
