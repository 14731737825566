import { observer } from 'mobx-react';
import React, { Component } from 'react';
import VideoPlayerUiState from '../../../ui-states/VideoPlayerUiState';
import Player from '../player/Player';
import RecordDate from './record-date/RecordDate';
import Speakers from './speakers/Speakers';
import './VideoPlayer.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import withStores from '../../../framework/hoc/withStores';
import { File } from '../../../graphql/types';

type VideoPlayerProps = {
    file: File;
    showVideoTimePairing: boolean;
    isCustomer?: boolean;
    allowConfigureSpeakers?: boolean;
} & InjectedProps &
    WithTranslation;

type VideoPlayerState = {
    date: Date;
};

type InjectedProps = {
    videoPlayerUiState: VideoPlayerUiState;
};

@observer
class VideoPlayer extends Component<VideoPlayerProps, VideoPlayerState> {
    player: any = null;
    state = {
        date: new Date()
    };
    componentDidMount(): void {
        const { file } = this.props;
        this.setState({ date: new Date(file.created) });
    }

    render() {
        const {
            file,
            showVideoTimePairing,
            isCustomer = false,
            t,
            videoPlayerUiState,
            allowConfigureSpeakers = true
        } = this.props;
        return (
            <div className="video-player">
                <Player
                    mediaFileUrl={file.mediaFileUrl}
                    mediaStreamFileSignature={file.mediaStreamFileSignature}
                    isAudioFile={file.isAudioFile}
                />
                <div className="body-wrapper">
                    <div className="video-time-pairing">
                        <div>
                            <label>
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    checked={videoPlayerUiState.isPairingCursorToVideoTime}
                                    onChange={videoPlayerUiState.togglePairingCursorToVideoTimeCheckbox}
                                />
                                {t('pair cursor with blocks')}
                            </label>
                        </div>
                        {showVideoTimePairing && (
                            <div>
                                <label>
                                    <input
                                        className="checkbox"
                                        type="checkbox"
                                        checked={videoPlayerUiState.createBlocksAutomatically}
                                        onChange={videoPlayerUiState.toggleCreateBlocksAutomatically}
                                    />
                                    {t('create blocks automatically')}
                                </label>
                            </div>
                        )}
                    </div>

                    <RecordDate
                        isCustomer={isCustomer}
                        onDatePicked={date => videoPlayerUiState.changeRecordingTime(file.id, date)}
                        recordedAt={file.recordedAt!}
                    />
                    <Speakers
                        isCustomer={isCustomer}
                        speakers={file.speakers}
                        allowConfigureSpeakers={allowConfigureSpeakers}
                        onConfigureParticipantsButtonClick={videoPlayerUiState.configureParticipants}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(withStores('videoPlayerUiState')(VideoPlayer));
