import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Project, ProjectStatus } from '../../../../graphql/types';
import SubmitButton from '../../../common/submit-button/SubmitButton';
import './ProjectHeaderInfo.scss';

type ProjectHeaderInfoProps = {
    project: Project;
    onAddNewFilesClick: () => void;
    onExportToExcelClick: () => void;
};

const ProjectHeaderInfo = ({
    project,
    t,
    onAddNewFilesClick,
    onExportToExcelClick
}: ProjectHeaderInfoProps & WithTranslation) => {
    return (
        <div className="project-header-info">
            <div className="category-info">
                <div className="info">
                    {t('project name')}: <span className="value">{project.name}</span>
                </div>
                <div className="info">
                    {t('category')}: <span className="value">{project.category}</span>
                </div>
                <div className="info">
                    {t('number of files')}: <span className="value">{project.numOfFiles}</span>
                </div>
                <div className="info">
                    {t('status')}: <span className="value">{t(`${project.status}`)}</span>
                </div>
            </div>
            <SubmitButton onClick={onExportToExcelClick} className="export-button">
                {t('export to excel')}
            </SubmitButton>
            <SubmitButton onClick={onAddNewFilesClick} disable={project.status === ProjectStatus.Closed}>
                {t('add files')}
            </SubmitButton>
        </div>
    );
};

export default withTranslation()(ProjectHeaderInfo);
