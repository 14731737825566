import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import configure from '../../../assets/images/configure.png';
import TranscriptUiState from '../../../ui-states/TranscriptUiState';
import EditorTagContextMenu from '../../customer/context-menu/EditorTagContextMenu';
import CustomerBlock from '../../customer/customer-transcript-page/CustomerBlock';
import Block from './block/Block';
import SyncStatusMessage from './sync-status-message/SyncStatusMessage';
import './TextEditor.scss';
import TimeCodeDropDown from './time-code-dropdown/TimeCodeDropDown';
import Toolbar from './toolbar/Toolbar';
import withStores from '../../../framework/hoc/withStores';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

type InjectedProps = {
    transcriptUiState: TranscriptUiState;
};

type TextEditorProps = {
    isCustomer?: boolean;
    canEditShortcuts?: boolean;
} & InjectedProps &
    WithTranslation;

@observer
class TextEditor extends Component<TextEditorProps> {
    static defaultProps = {
        isCustomer: false,
        canEditShortcuts: false
    };
    render() {
        const {
            transcriptUiState: { transcript, openShortcutsModal, setTimeCode, toggleSelectedBlocks, selectedBlocks },
            t,
            isCustomer,
            canEditShortcuts
        } = this.props;

        if (!transcript) {
            return (
                <div className="text-center pt-5">
                    <LoadingSpinner />
                </div>
            );
        }

        return (
            <div className={isCustomer ? 'customer-editor' : 'text-editor '}>
                <table>
                    <thead>
                        {isCustomer ? (
                            <>
                                <tr className="table-header">
                                    <th className="checkbox" />
                                    <th className="ts">{t('TS')}</th>
                                    <th className="speaker">{t('speaker')}</th>
                                    <th className="transcript">{t('transcript')}</th>
                                </tr>
                                <tr>
                                    <th className="checkbox" />
                                    <th className="tc">{transcript.blockInterval}</th>
                                    <th className="speaker bg-white" />
                                    <th className="bg-white w-100" />
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr className="table-header">
                                    <th className="ts">{t('TS')}</th>
                                    <th className="speaker">{t('speaker')}</th>
                                    <th className="transcript w-100">
                                        <div className="pr-3">{t('transcript')}</div>
                                        <SyncStatusMessage status={transcript.syncStatus} />
                                        {canEditShortcuts && (
                                            <div className="shortcuts-configuration" onClick={openShortcutsModal}>
                                                {t('shortcuts')} <img alt="" src={configure} />
                                            </div>
                                        )}
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <TimeCodeDropDown
                                            timeCode={transcript.blockInterval}
                                            setTimeCode={seconds => setTimeCode(seconds)}
                                        />
                                    </th>
                                    <th className="speaker" />
                                    <th>
                                        <Toolbar activeParagraph={transcript.activeParagraph} />
                                    </th>
                                </tr>
                            </>
                        )}
                    </thead>
                    <tbody>
                        {isCustomer
                            ? transcript.blocks.map(block => (
                                  <CustomerBlock
                                      block={block}
                                      key={block.number}
                                      checked={selectedBlocks.includes(block.number.toString())}
                                      onCheckBoxClick={selectedBlock => toggleSelectedBlocks(selectedBlock)}
                                  />
                              ))
                            : transcript.blocks.map(block => <Block block={block} key={block.uuid} />)}
                    </tbody>
                </table>

                {isCustomer && <EditorTagContextMenu />}
            </div>
        );
    }
}

export default withTranslation()(withStores('transcriptUiState')(TextEditor));
