import { toast, TypeOptions } from 'react-toastify';
import uuid from 'uuid';

class Toast {
    currentToasts: { [toastId: string]: string } = {};
    private show = (message: string, type: TypeOptions) => {
        const toastsAreVisible = Object.values(this.currentToasts).includes(message);
        if (toastsAreVisible) {
            return;
        }
        // dismiss other toasts
        const toastId = uuid.v4();

        toast(message, {
            type,
            toastId,
            position: toast.POSITION.BOTTOM_CENTER,
            pauseOnFocusLoss: false,
            onClose: () => this.onClose(toastId)
        });

        this.currentToasts[toastId] = message;
    };
    info = (message: string) => {
        this.show(message, 'info');
    };
    error = (message: string) => {
        this.show(message, 'error');
    };

    private onClose(id: string) {
        delete this.currentToasts[id];
    }
}
export default new Toast();
