import { Modifier, SelectionState } from 'draft-js';
import { action, computed, observable } from 'mobx';
import { Macro, Tag, TagLocationInput, Transcript } from '../graphql/types';
import BlockModel from './BlockModel';
import ParagraphModel, { CursorPosition } from './ParagraphModel';
import { EntityLocation } from '../framework/draft/types';
import { getEntityRange } from 'draftjs-utils';
import _ from 'lodash';

export enum TranscriptSyncStatus {
    SYNCED,
    IN_PROGRESS,
    NOT_SYNCED
}

export default class TranscriptModel {
    @observable blocks: BlockModel[];
    @observable id: string;
    @observable activeParagraph: ParagraphModel;
    @observable revision: number = 0;
    @observable syncStatus: TranscriptSyncStatus = TranscriptSyncStatus.SYNCED;

    constructor(
        private readonly transcriptGetter: () => Transcript,
        tags: Tag[],
        private readonly fileDuration: number
    ) {
        const staleTranscript = this.originalTranscript;
        this.blocks = staleTranscript.blocks.map(block => {
            return BlockModel.fromHtml(this, block, tags);
        });

        this.id = staleTranscript.id;
        this.revision = staleTranscript.revision;
        this.activeParagraph = this.firstBlock!.firstParagraph;
        this.moveTo(this.activeParagraph, CursorPosition.END);
    }

    @computed
    get allTags(): TagLocationInput[] {
        const tags = new Set<TagLocationInput>();
        this.runParagraphCallback(x => x.tags.forEach(y => tags.add(y)));
        return [...tags];
    }

    @computed
    get originalTranscript() {
        return this.transcriptGetter();
    }

    private get maxBlocks() {
        return Math.ceil(this.fileDuration / this.blockInterval);
    }

    @computed
    get isSaving() {
        return this.syncStatus === TranscriptSyncStatus.IN_PROGRESS;
    }

    @computed get blockInterval() {
        return this.originalTranscript && this.originalTranscript.blockInterval;
    }

    @computed get startOffset() {
        return this.originalTranscript && this.originalTranscript.startOffset;
    }

    @computed get asJson() {
        return {
            blocks: this.blocks.map(x => x.asJson)
        };
    }

    @computed get activeBlock(): BlockModel {
        return this.activeParagraph!.block!;
    }

    @computed
    private get isStartOfTranscript(): boolean {
        return !this.activeParagraph.previousParagraph && !this.activeBlock.previousBlock;
    }

    @computed
    private get isStartOfBlock(): boolean {
        return this.activeParagraph === this.activeBlock.firstParagraph;
    }

    @computed
    get firstBlock() {
        if (!this.blocks.length) {
            return null;
        }

        return this.blocks[0];
    }

    @computed get lastBlock() {
        if (!this.blocks.length) {
            return null;
        }

        return this.blocks[this.blocks.length - 1];
    }

    @action
    setActiveParagraph = (activeParagraph: ParagraphModel) => {
        return (this.activeParagraph = activeParagraph);
    };

    getParagraph = (blockNumber: number, paragraphNumber: number) => {
        if (blockNumber >= this.blocks.length) {
            throw new Error(`Block number ${blockNumber} is out of range`);
        }
        const block = this.blocks[blockNumber];

        if (paragraphNumber >= block.paragraphs.length) {
            throw new Error(`Paragraph number ${paragraphNumber} is out of range for block ${blockNumber}`);
        }

        return block.paragraphs[paragraphNumber];
    };

    tryMoveToNextParagraph = () => {
        if (!this.activeParagraph.isEndOfParagraph) {
            return;
        }

        if (this.activeParagraph.nextParagraph) {
            return this.moveTo(this.activeParagraph.nextParagraph);
        }

        this.tryMoveToNextBlock();
    };

    tryMoveToNextBlock = () => {
        if (!this.activeBlock.nextBlock) {
            return;
        }

        return this.moveTo(this.activeBlock.nextBlock.firstParagraph);
    };

    tryMoveToPreviousParagraph = () => {
        if (!this.activeParagraph.isStartOfParagraph) {
            return;
        }

        if (this.activeParagraph.previousParagraph) {
            return this.moveTo(this.activeParagraph.previousParagraph);
        }

        if (!this.activeBlock.previousBlock) {
            return;
        }

        return this.moveTo(this.activeBlock.previousBlock.lastParagraph);
    };

    @action
    insertNewBlock = (insertAt?: number, moveCursor: boolean = true) => {
        if (this.blocks.length >= this.maxBlocks) {
            return false;
        }

        const nextBlockNumber = insertAt || this.activeBlock.number + 1;
        const newBlock = BlockModel.empty(this);

        if (nextBlockNumber >= this.blocks.length) {
            this.blocks.push(newBlock);
        } else {
            this.blocks.splice(nextBlockNumber, 0, newBlock);
        }

        if (newBlock.previousBlock) {
            newBlock.firstParagraph.setSpeaker(newBlock.previousBlock.lastParagraph.speakerId);
        }

        if (moveCursor) {
            this.moveTo(newBlock.firstParagraph, CursorPosition.END);
        }

        return true;
    };

    @action
    insertNewParagraph = () => {
        const nextParagraphNumber = this.activeParagraph.number + 1;
        const newParagraph = this.activeBlock.insertNewParagraph(nextParagraphNumber);

        this.moveTo(newParagraph);
    };

    @action
    removeParagraph = () => {
        if (this.isStartOfTranscript) {
            return;
        }

        if (this.isStartOfBlock && this.activeBlock.paragraphs.length === 1) {
            return this.removeBlock(this.activeBlock);
        }

        const previousParagraph = this.activeParagraph.previousParagraph;

        if (!previousParagraph) {
            this.activeBlock.paragraphs.splice(this.activeParagraph.number, 1);
            return this.moveTo(this.activeBlock.previousBlock!.lastParagraph, CursorPosition.END);
        }

        this.activeBlock.paragraphs.splice(this.activeParagraph.number, 1);
        this.moveTo(previousParagraph, CursorPosition.END);
    };

    replaceWithMacros = (macros: Macro[]) => {
        return macros.some(macro => this.activeParagraph.tryReplaceWithText(macro));
    };
    addTag = (tag: Tag, tagEntityLocation: EntityLocation) => {
        const paragraph = this.findParagraphByDraftBlock(tagEntityLocation.blockKey);
        if (!paragraph) {
            return;
        }

        const editorState = paragraph.editorState;
        const contentState = editorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity('TAG', 'MUTABLE', {
            id: tag.id
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const contentStateWithTag = Modifier.applyEntity(contentStateWithEntity, editorState.getSelection(), entityKey);

        paragraph.pushUndoableNewContent(contentStateWithTag, 'apply-entity');
    };

    modifyTag = (tag: Tag, tagEntityLocation: EntityLocation) => {
        const paragraph = this.findParagraphByDraftBlock(tagEntityLocation.blockKey);
        if (!paragraph) {
            return;
        }

        const contentState = paragraph.editorState.getCurrentContent();
        const contentStateWithNewTag = contentState.mergeEntityData(tagEntityLocation.entityKey, {
            id: tag.id
        });
        paragraph.pushUndoableNewContent(contentStateWithNewTag, 'apply-entity');
    };

    removeTag = (tagEntityLocation: EntityLocation) => {
        const paragraph = this.findParagraphByDraftBlock(tagEntityLocation.blockKey);
        if (!paragraph) {
            return;
        }
        const editorState = paragraph.editorState;
        const range = getEntityRange(editorState, tagEntityLocation.entityKey);
        let entitySelection = SelectionState.createEmpty(tagEntityLocation.blockKey);
        entitySelection = entitySelection.merge({
            anchorOffset: range.start,
            focusOffset: range.end
        }) as SelectionState;

        const contentWithoutStyle = Modifier.applyEntity(editorState.getCurrentContent(), entitySelection, null);
        paragraph.pushUndoableNewContent(contentWithoutStyle, 'apply-entity');
    };

    moveCursorToBlock = (blockNumber: number) => {
        const block = this.findBlock(blockNumber);
        if (block && block !== this.activeBlock) {
            this.moveTo(block.lastParagraph, CursorPosition.END);
        }
    };

    tryCompleteBlocksUpTo = (blockNumber: number) => {
        const blocksToInsert = blockNumber - this.blocks.length + 1;
        if (blocksToInsert < 0) {
            const blocksRemoved = _.range(-blocksToInsert)
                .map(() => {
                    const lastBlock = this.lastBlock!;
                    const blockText = lastBlock.text.trim();
                    if (!blockText) {
                        this.removeBlock(lastBlock);
                        return true;
                    }
                    return false;
                })
                .filter(_.identity).length;

            return -blocksRemoved;
        }

        const blocksCreated = _.range(blocksToInsert)
            .map(() => {
                const insertAt = this.lastBlock!.number + 1;
                return this.insertNewBlock(insertAt, false);
            })
            .filter(_.identity).length;

        return blocksCreated;
    };
    private removeBlock = (block: BlockModel) => {
        if (!block.previousBlock) {
            return;
        }
        this.moveTo(block.previousBlock.lastParagraph, CursorPosition.END);

        this.blocks.splice(block.number, 1);
    };

    private moveTo = (paragraph: ParagraphModel, cursorPosition?: CursorPosition, onFinish?: () => void) => {
        this.setActiveParagraph(paragraph);
        setTimeout(() => {
            // This is needed otherwise the first character in the new paragraph is inserted weirdly
            // todo: find out what hidden bug is causing this

            const activeParagraph = this.activeParagraph;
            activeParagraph && activeParagraph.placeCursor(cursorPosition);
            onFinish && onFinish();
        }, 0);
    };

    private findBlock = (blockNumber: number) => {
        return this.blocks.find(block => block.number === blockNumber);
    };

    private runParagraphCallback(cb: (paragraph: ParagraphModel) => void | boolean) {
        this.blocks.forEach(block => {
            block.paragraphs.forEach(paragraph => {
                if (cb(paragraph) === false) {
                    return;
                }
            });
        });
    }

    @action
    removeSpeaker(id: string) {
        this.runParagraphCallback(paragraph => {
            if (paragraph.speakerId === id) {
                paragraph.speakerId = null;
            }
        });
    }

    private findParagraphByDraftBlock(blockKey: string): ParagraphModel | null {
        let foundParagraph = null;
        this.runParagraphCallback(paragraph => {
            if (paragraph.hasDraftBlock(blockKey)) {
                foundParagraph = paragraph;
                return false;
            }
        });

        return foundParagraph;
    }
}
