import { action, observable, runInAction } from 'mobx';
import { Money } from '../graphql/types';
import FileStore from '../stores/FileStore';
import { PickedItem } from './SearchUiState';
import TimeUtils from '../framework/utils/TimeUtils';
import UserStore from '../stores/UserStore';

export default class HistoryUiState {
    @observable userMonths: PickedItem[] = [];
    @observable userPriceUnitTypes: PickedItem[] = [];
    @observable pickedMonth: PickedItem | null = null;
    @observable pickedUnit: PickedItem | null = null;
    @observable total: Money | null = null;

    constructor(private fileStore: FileStore, private userStore: UserStore) {}

    @action
    public async init() {
        await this.getTotalProfitFromFiles();
        const userMonths = TimeUtils.getMonthsFromDates(this.userStore.user.dateJoined).map(month => ({
            label: month,
            id: month
        }));

        runInAction(() => {
            this.userMonths = userMonths;
            this.pickedMonth = userMonths[userMonths.length - 1];
        });
    }

    @action
    public getTotalProfitFromFiles = async () => {
        const totalParams = {
            month: this.pickedMonth && this.pickedMonth.id,
            priceUnit: this.pickedUnit && this.pickedUnit.id
        };
        await this.fileStore.searchHistoryFiles(totalParams);
        const total = await this.fileStore.getTotalProfitFromFiles();
        this.setTotal(total);
    };

    @action
    public setPickedMonth = (pickedMonth: PickedItem) => {
        this.pickedMonth = pickedMonth;
    };
    @action
    public setPickedUnit = (pickedUnit: PickedItem) => {
        this.pickedUnit = pickedUnit;
    };

    @action
    public setUserPriceUnitTypes = (userPriceUnitTypes: PickedItem[]) => {
        this.userPriceUnitTypes = userPriceUnitTypes;
    };

    public searchHistoryFiles = async () => {
        const totalParams = {
            month: this.pickedMonth && this.pickedMonth.id,
            priceUnit: this.pickedUnit && this.pickedUnit.id
        };

        await this.fileStore.searchHistoryFiles(totalParams);
    };

    @action
    private setTotal = (total: Money) => {
        this.total = total;
    };
}
