import { Notification, NotificationType } from '../graphql/types';

class NotificationService {
    getLinkTo = (notification: Notification) => {
        switch (notification.notificationType) {
            case NotificationType.FileAlmostDue:
            case NotificationType.FileNewUrgencyWage:
            case NotificationType.FileRepairsRequired:
            case NotificationType.FileTranscriberAssigned:
            case NotificationType.FileTranscriptionCompleted:
            case NotificationType.FileUrgencyUpdated:
                return `transcript/${notification.fileId}`;

            case NotificationType.ProjectCreated:
            case NotificationType.ProjectEmptyLongTime:
                return `projects/${notification.projectId}`;
        }
        return '/';
    };
}
export default new NotificationService();
