import { gql } from 'apollo-boost';

export const addTagMutation = gql`
    mutation addTag($tag: TagInput!) {
        addTag(tag: $tag) {
            tag {
                id
                name
                color
            }
        }
    }
`;
