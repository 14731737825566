import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import { ActionMeta, ValueType } from 'react-select/lib/types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ColorUtils from '../../../../framework/utils/ColorUtils';
import { Tag } from '../../../../graphql/types';
import TagStore from '../../../../stores/TagStore';
import { OptionType } from '../../multi-select-dropdown/MultiSelectDropDown';
import Select from '../../select/Select';
import './ConvertToTagsModal.scss';
import withStores from '../../../../framework/hoc/withStores';
import ModalActions from '../ModalActions';

type InjectedProps = {
    tagStore: TagStore;
};

type ConvertToTagsModalProps = {
    isOpen: boolean;
    isPastDelay: boolean;
    isLoading: boolean;
    titleText: string;
    validate?: boolean;
    onSubmit: (tagId: string) => Promise<void>;
    onHide(): void;
} & InjectedProps;

type ConvertToTagsModalState = {
    pickedTag: Tag;
    showPicker: boolean;
};

@observer
class ConvertToTagsModal extends Component<ConvertToTagsModalProps, ConvertToTagsModalState> {
    state = {
        pickedTag: this.getEmptyTag(),
        showPicker: false
    };

    render() {
        const {
            isOpen,
            onHide,
            onSubmit,
            titleText,
            validate,
            tagStore: { allTags }
        } = this.props;
        const { pickedTag, showPicker } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={onHide} size="lg" centered={true}>
                <div className="convert-to-tags-modal">
                    <ModalHeader>{titleText}</ModalHeader>
                    <ModalBody>
                        <div className="picker-wrapper">
                            {showPicker && (
                                <div className="picker">
                                    <TwitterPicker
                                        color={pickedTag.color ? pickedTag.color : ColorUtils.pickRandomColor()}
                                        width="100%"
                                        onChangeComplete={(color: ColorResult) =>
                                            this.setState(prevState => ({
                                                pickedTag: { ...prevState.pickedTag, color: color.hex },
                                                showPicker: false
                                            }))
                                        }
                                        triangle="hide"
                                    />
                                </div>
                            )}
                            <div
                                className="circle"
                                style={{ backgroundColor: pickedTag.color }}
                                onClick={() => this.setState(prevState => ({ showPicker: !prevState.showPicker }))}
                            />
                            <Select
                                className="select-tag-field"
                                options={allTags.map(tag => ({ value: tag.id, label: tag.name }))}
                                onChange={this.onSelectChange}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ModalActions
                            isDisabled={!validate || isEmpty(pickedTag.id)}
                            onCancel={onHide}
                            onSubmitClick={async () => {
                                await onSubmit(pickedTag.id);
                                onHide();
                            }}
                        />
                    </ModalFooter>
                </div>
            </Modal>
        );
    }
    private onSelectChange = (option: ValueType<OptionType>, actionMeta: ActionMeta) => {
        const {
            tagStore,
            tagStore: { allTags }
        } = this.props;

        if (actionMeta.action === 'clear') {
            this.setState({
                pickedTag: this.getEmptyTag()
            });
            return;
        }

        if (actionMeta.action === 'create-option') {
            this.setState(
                prevState => ({
                    pickedTag: {
                        ...prevState.pickedTag,
                        name: (option as OptionType).label
                    }
                }),
                async () => {
                    const tag = await tagStore.addTag(this.state.pickedTag);
                    this.setState(prevState => ({ pickedTag: { ...prevState.pickedTag, id: tag.id } }));
                }
            );
            return;
        }
        if (actionMeta.action === 'select-option') {
            const tag = allTags.find(tag => tag.id === (option as OptionType).value);
            this.setState({ pickedTag: tag as Tag });
        }
    };

    private getEmptyTag() {
        return {
            name: '',
            color: ColorUtils.pickRandomColor(),
            id: ''
        };
    }
}

export default withStores('tagStore')(ConvertToTagsModal);
