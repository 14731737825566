import React from 'react';
import { Button } from 'reactstrap';

import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import './SubmitButton.scss';
import classnames from 'classnames';
import useSubmitLifeCycle from '../../../framework/hooks/useSubmitLifeCycle';

type SubmitButton = {
    disable?: boolean;
    children: string;
    dataTestId?: string;
    secondary?: boolean;
    autoWidth?: boolean;
    onClick?: () => void;
    isLoading?: boolean;
    className?: string;
};

const SubmitButton = ({
    dataTestId = 'submit-button',
    children,
    disable = false,
    secondary,
    onClick,
    isLoading,
    autoWidth = false,
    className
}: SubmitButton) => {
    const { isSubmitting, shouldShowSpinner, submitAction } = useSubmitLifeCycle(onClick || null);

    return (
        <div className={classnames('submit-button', className, { 'auto-width': autoWidth })}>
            <Button
                type={secondary ? 'button' : 'submit'}
                outline={true}
                disabled={disable || isLoading || shouldShowSpinner || isSubmitting}
                data-testid={dataTestId}
                className={classnames({ secondary })}
                onClick={submitAction}>
                {children}
                {(isLoading || shouldShowSpinner) && <LoadingSpinner size="tiny" className="mr-2" />}
            </Button>
        </div>
    );
};

export default SubmitButton;
