import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import InjectedComponent from '../framework/utils/InjectedComponent';
import UserStore from '../stores/UserStore';
import Header from './common/header/Header';
import ModalManager from './common/modal/ModalManager';
import FilesPage from './transcriber/files-page/FilesPage';
import HistoryPage from './transcriber/history-page/HistoryPage';
import NotificationsPage from './transcriber/notification-page/NotificationsPage';
import ProfilePage from './transcriber/profile-page/ProfilePage';
import TranscriberTranscriptPage from './transcriber/transcriber-transcript-page/TranscriberTranscriptPage';
import withStores from '../framework/hoc/withStores';
import NotificationStore from '../stores/NotificationStore';
import { withLoadable } from '../framework/hoc/withLoadable';
import FullScreenLoadingSpinner from './common/loading-spinner/FullScreenLoadingSpinner';

type InjectedProps = {
    userStore: UserStore;
    notificationStore: NotificationStore;
};

@observer
class TranscriberPage extends Component<InjectedProps> {
    render() {
        return (
            <>
                <Header />
                <Switch>
                    <Route path={`/transcriber/transcript/:id`} component={TranscriberTranscriptPage} />
                    <Route path={`/transcriber/profile`} component={ProfilePage} />
                    <Route path={`/transcriber/all-notifications`} component={NotificationsPage} />
                    <Route path={`/transcriber/history`} component={HistoryPage} />
                    <Route path={`/transcriber`} component={FilesPage} />
                </Switch>
            </>
        );
    }
}

export default withStores('notificationStore')(
    withLoadable({
        loader: ({ notificationStore, userStore }: InjectedProps) => {
            return notificationStore.init();
        },
        loading: FullScreenLoadingSpinner
    })(TranscriberPage)
);
