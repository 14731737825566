import { gql } from 'apollo-boost';
import { speakerFragment } from '../../common/fragments/speakerFragment';

export const filesSpeakersQuery = gql`
    query filesSpeakers($ids: [ID!]!) {
        filesSpeakers(ids: $ids) {
            ...speaker
        }
    }
    ${speakerFragment}
`;

export const filesSpeakerNamesQuery = gql`
    query filesSpeakers($ids: [ID!]!) {
        filesSpeakers(ids: $ids) {
            id
            name
        }
    }
`;
