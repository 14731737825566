import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import SubmitButton from '../../common/submit-button/SubmitButton';
import withStores from '../../../framework/hoc/withStores';
import TranscriptUiState from '../../../ui-states/TranscriptUiState';

type CustomerSubmitButtonsProps = {
    transcriptUiState: TranscriptUiState;
};

const CustomerSubmitButtons = ({
    t,
    history,
    transcriptUiState
}: RouteComponentProps & WithTranslation & CustomerSubmitButtonsProps) => {
    return (
        <>
            <SubmitButton secondary={true} onClick={transcriptUiState.sendTranscriptForRepair}>
                {t('send for repair')}
            </SubmitButton>
            <SubmitButton onClick={() => history.push(`/customer/projects/${transcriptUiState.file.project.id}`)}>
                {t('close document')}
            </SubmitButton>
        </>
    );
};

export default withTranslation()(withRouter(withStores('transcriptUiState')(CustomerSubmitButtons)));
