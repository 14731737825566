import { ApolloLink, HttpLink } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import env from '../env';
import * as Sentry from '@sentry/browser';
import { API_TOKEN_KEY } from '../ui-states/AppUiState';

type CreateClient = {
    setErrorMessage: (error: string) => void;
    onUnauthorizedHandler(): void;
};

export default function createClient({ onUnauthorizedHandler, setErrorMessage }: CreateClient) {
    const httpLink = new HttpLink({ uri: () => env.GRAPHQL_API_URL });

    const reqLink = setContext(async operation => {
        const variablesString = JSON.stringify(operation.variables);
        console.info(
            `[Operation] ${operation.operationName}`,
            `${variablesString.slice(100)}${variablesString.length > 100 ? '...' : ''}`
        );
        const apiToken = localStorage.getItem(API_TOKEN_KEY);
        return {
            headers: {
                Authorization: apiToken ? `Bearer ${apiToken}` : ''
            }
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        let isUnauthorized = false;
        const errors = [] as string[];

        if (networkError) {
            console.error(`[Network error] Message: ${networkError}`);
            const aNetworkError = networkError as any;
            if (aNetworkError.statusCode === 401) {
                isUnauthorized = true;
            }

            errors.push('Network error occurred');
        }

        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
                console.warn(
                    `[GraphQL error] Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
                );

                if (message.includes('You do not have permission') || message.includes('אין לך הרשאה לבצע פעולה זו')) {
                    isUnauthorized = true;
                    return;
                }

                errors.splice(0, errors.length);
                errors.push(message);
            });
        }

        if (isUnauthorized) {
            onUnauthorizedHandler();
        } else if (errors.length > 0) {
            setErrorMessage(errors[0]);
        }
    });

    const afterwareLink = new ApolloLink((operation, forward) => {
        if (!forward) {
            return null;
        }
        return forward(operation).map(response => {
            const {
                response: { headers }
            } = operation.getContext();
            if (headers) {
                const requestId = headers.get('x-request-id');
                if (requestId) {
                    Sentry.configureScope(scope => scope.setExtra('requestId', requestId));
                }
            }

            return response;
        });
    });

    const link = reqLink
        .concat(errorLink)
        .concat(afterwareLink)
        .concat(httpLink);

    return new ApolloClient({
        link,
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'network-only'
            },
            query: {
                fetchPolicy: 'network-only'
            }
        }
    });
}
