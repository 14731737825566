import React, { Component, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TimeUtils from '../../../../framework/utils/TimeUtils';
import './ModifyTranscriptTimeCodeModal.scss';
import MaskedInput from 'react-text-mask';
import ModalActions from '../ModalActions';

type ModifyBlockTimeCodeModalProps = {
    isOpen: boolean;
    titleText: string;
    sendConfirmText: string;
    initialTimeCode: number;
    minimumTimeCode: number;
    onHide(): void;
    onSubmit(offsetInSeconds: number): Promise<void>;
};

type ModifyTranscriptOffsetModalState = {
    offset: number;
};

class ModifyBlockTimeCodeModal extends Component<
    ModifyBlockTimeCodeModalProps & WithTranslation,
    ModifyTranscriptOffsetModalState
> {
    state = {
        offset: this.props.initialTimeCode
    };

    private _timeInputRef: RefObject<MaskedInput> = React.createRef();

    componentDidMount(): void {
        const input = this._timeInputRef.current;
        if (input) {
            setTimeout(() => {
                input.inputElement.focus();
                // @ts-ignore
                input.inputElement.setSelectionRange(0, 0);
            }, 200);
        }
    }

    render() {
        const { isOpen, onHide, titleText, t, initialTimeCode } = this.props;
        const { offset } = this.state;
        return (
            <Modal isOpen={isOpen} size="lg" centered={true} autoFocus={false}>
                <div className="modify-offset">
                    <ModalHeader>{titleText}</ModalHeader>
                    <ModalBody>
                        <div className="input-wrapper">
                            <MaskedInput
                                // @ts-ignore
                                ref={this._timeInputRef}
                                min={initialTimeCode}
                                // render={(ref, props) => <Input innerRef={this._timeInputRef} {...props} />}
                                mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/, '.', /\d/, /\d/, /\d/]}
                                value={TimeUtils.millisecondsToDurationString(offset)}
                                placeholder="00:00:00"
                                pipe={this.onChange}
                            />
                        </div>
                        <div className="warning">
                            {t(
                                'pay attention! editing this field will change the rest of the time code in the transcript'
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ModalActions submitText={t('save')} onSubmitClick={this.onSubmit} onCancel={onHide} />
                    </ModalFooter>
                </div>
            </Modal>
        );
    }

    private onChange = (conformedValue: string) => {
        const newOffset = TimeUtils.turnDurationStringToMicroSeconds(conformedValue.replace(/_/g, '0')) || 0;

        const minimumTimeCode = this.props.minimumTimeCode;
        if (newOffset < minimumTimeCode) {
            this.setState({ offset: minimumTimeCode });

            return TimeUtils.millisecondsToDurationString(minimumTimeCode);
        }

        this.setState({ offset: newOffset });

        return conformedValue;
    };

    private onSubmit = async () => {
        const { onSubmit, onHide } = this.props;
        const { offset } = this.state;
        await onSubmit(offset);
        onHide();
    };
}

export default withTranslation()(ModifyBlockTimeCodeModal);
