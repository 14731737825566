import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TranscriptSyncStatus } from '../../../../models/TranscriptModel';
import LoadingSpinner from '../../loading-spinner/LoadingSpinner';
import './SyncStatusMessage.scss';
import classnames from 'classnames';

type SyncStatusMessageProps = {
    status: TranscriptSyncStatus;
};

const message = {
    [TranscriptSyncStatus.SYNCED]: '',
    [TranscriptSyncStatus.IN_PROGRESS]: 'saving changes...',
    [TranscriptSyncStatus.NOT_SYNCED]: 'error saving changes'
};

const SyncStatusMessage = ({ status, t }: SyncStatusMessageProps & WithTranslation) => (
    <div
        className={classnames('sync-status-message d-flex ', {
            success: status === TranscriptSyncStatus.IN_PROGRESS,
            error: status === TranscriptSyncStatus.NOT_SYNCED
        })}>
        {t(message[status])}
        {status === TranscriptSyncStatus.IN_PROGRESS && <LoadingSpinner size="tiny" className="mr-1" />}
    </div>
);

export default withTranslation()(SyncStatusMessage);
