import { gql } from 'apollo-boost';
import { blockFragment } from './blockFragment';

export const transcriptFragment = gql`
    fragment transcript on Transcript {
        id
        blockInterval
        startOffset
        blocks {
            ...block
        }
        revision
    }
    ${blockFragment}
`;
