import { gql } from 'apollo-boost';

export const generateFileUploadUrlMutation = gql`
    mutation generateFileUploadUrl($fileName: String!, $fileType: String!) {
        generateFileUploadUrl(fileName: $fileName, fileType: $fileType) {
            uploadUrl
            uploadFields
        }
    }
`;
