import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { modifyEditorShortcutsMutation } from '../graphql/transcriber/mutations/modifyEditorShortcuts';
import { modifyMacrosMutation } from '../graphql/transcriber/mutations/modifyMacros';
import { modifyVideoPlayerShortcutsMutation } from '../graphql/transcriber/mutations/modifyVideoPlayerShortcuts';
import {
    ModifyEditorShortcutsMutation,
    ModifyEditorShortcutsMutationArgs,
    ModifyMacrosMutation,
    ModifyMacrosMutationArgs,
    ModifyVideoPlayerShortcutsMutation,
    ModifyVideoPlayerShortcutsMutationArgs
} from '../graphql/types';

export default class TranscriberStore {
    constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

    modifyMacros = async ({ macros }: ModifyMacrosMutationArgs) => {
        await this.client.mutate<ModifyMacrosMutation, ModifyMacrosMutationArgs>({
            mutation: modifyMacrosMutation,
            variables: { macros },
            refetchQueries: ['currentUser']
        });
    };
    modifyEditorShortcuts = async ({ shortcuts }: ModifyEditorShortcutsMutationArgs) => {
        await this.client.mutate<ModifyEditorShortcutsMutation, ModifyEditorShortcutsMutationArgs>({
            mutation: modifyEditorShortcutsMutation,
            variables: { shortcuts },
            refetchQueries: ['currentUser']
        });
    };
    modifyVideoPlayerShortcuts = async ({ shortcuts }: ModifyVideoPlayerShortcutsMutationArgs) => {
        await this.client.mutate<ModifyVideoPlayerShortcutsMutation, ModifyVideoPlayerShortcutsMutationArgs>({
            mutation: modifyVideoPlayerShortcutsMutation,
            variables: { shortcuts },
            refetchQueries: ['currentUser']
        });
    };
}
